import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Config, FirestoreState } from 'src/app/store/FireStore/firestore.state';

@Component({
  selector: 'maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent {
  @Select(FirestoreState.GetWebsiteConfig) config$: Observable<Config>;

}
