import { Component, OnDestroy } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-toggle-int-dialing-failed-sheet',
  templateUrl: './toggle-int-dialing-failed-sheet.component.html',
  styleUrl: './toggle-int-dialing-failed-sheet.component.scss'
})
export class ToggleIntDialingFailedSheetComponent implements OnDestroy {

  constructor(private readonly store: Store){}

  public accept() {
    this.store.dispatch([new Navigate(['../services']), new CloseBottomSheet()]);
  }

  ngOnDestroy(): void {
    this.accept();
  }
}
