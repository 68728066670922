import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { StopAssignSimProcess } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { BottomSheetState } from 'src/app/store/BottomSheet/bottom-sheet.state';
import { isDesktop } from 'src/app/store/Core/functions/core-state-functions';

@Component({
  selector: 'app-bottom-sheet-content-container',
  templateUrl: './bottom-sheet-content-container.component.html',
  styleUrls: ['./bottom-sheet-content-container.component.scss']
})
export class BottomSheetContentContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Select(BottomSheetState.OpenSheetName) OpenSheet$!: Observable<string>

  ngDestroy$: Subject<null> = new Subject()
  container?: HTMLElement

  constructor(private bottomSheetRef: MatBottomSheetRef, private store: Store, private renderer: Renderer2, private actions$: Actions, private elementRef: ElementRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        this.bottomSheetRef?.afterOpened()
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(() => {
          this.setBottomSheetScrollConfig();
        })
        
        this.bottomSheetRef?.afterDismissed()
        .pipe(take(1))
        .subscribe(() => {
          this.enableBodyScroll()
          this.store.dispatch(new BottomSheetActions.CloseBottomSheet)
        })
        
    }, 100)

    this.actions$.pipe(
      takeUntil(this.ngDestroy$),
      ofActionSuccessful(BottomSheetActions.ChangeOpenSheet)).subscribe({
      next: () => {
        setTimeout(() => {
          this.setBottomSheetScrollConfig()
        }, 100)
      }
    });
  }

  setBottomSheetScrollConfig() {

    this.disableBodyScroll()

    const handle = this.elementRef.nativeElement.querySelector('.handle')!;

    const container = document.querySelector('.mat-bottom-sheet-container')! as HTMLElement;
    const sheetName = this.store.selectSnapshot(BottomSheetState.OpenSheetName);
    container.classList.add(`sheet-${sheetName}`);

    if(sheetName === 'ask-rain-widget') {
      container?.classList?.add('no-bottom-padding');
    }

    const sheetContent = this.elementRef.nativeElement.querySelector('.sheet-content-container')! as HTMLElement;

    const minHeight = sheetContent.clientHeight - 10;

    handle.addEventListener('touchmove', (event: TouchEvent) => {
      const movement = event?.touches?.[0]?.clientY;
      if (movement) {
        const newHeight = ((document.body.clientHeight - movement) + 10);
        container.style.height = `${String(newHeight)}px`;
        // container.style.maxWidth = '400px';
      } 

      if (!movement && container.clientHeight < minHeight) {
        this.bottomSheetRef?.dismiss();
      }

      handle.addEventListener('touchend', (event: TouchEvent) => this.dismissSheetCheck(container.clientHeight, minHeight, handle))
    })


    handle.addEventListener('drag', (event: TouchEvent) => {
      const movement = event?.touches?.[0]?.clientY;
      if (movement) {
        const newHeight = ((document.body.clientHeight - movement) + 10);
        container.style.height = `${String(newHeight)}px`;
        // container.style.maxWidth = '400px';
      } 

      if (!movement && container.clientHeight < minHeight) {
        this.bottomSheetRef?.dismiss();
      }

      handle.addEventListener('touchend', (event: TouchEvent) => this.dismissSheetCheck(container.clientHeight, minHeight, handle))
    })

  }

  dismissSheetCheck(containerHeight: number, minHeight: number, handle: HTMLElement) {
    if (containerHeight < minHeight) {
      this.bottomSheetRef?.dismiss();
      handle.removeEventListener('touchend', (event) => this.dismissSheetCheck(containerHeight, minHeight, handle))
    }
  }

  enableBodyScroll(){
    document.body.style.overscrollBehaviorY = "initial"
    document.body.style.overflowY = "initial"
    document.querySelector('html')!.style.overscrollBehaviorY = "initial"
    document.querySelector('html')!.style.overflowY = "initial"
  }

  disableBodyScroll(){
    document.body.style.overscrollBehaviorY = "none"
    document.body.style.overflowY = "hidden"
    document.querySelector('html')!.style.overscrollBehaviorY = "none"
    document.querySelector('html')!.style.overflowY = "hidden"
  }

  ngOnDestroy(): void {
    if (this.store.selectSnapshot(AssignSimState.GetStage)) this.store.dispatch(new StopAssignSimProcess());
    this.ngDestroy$.next(null),
    this.ngDestroy$.complete()
  }

}
