<app-bottom-sheet-content-template>
  <pre>{{topupResult$ | async}}</pre>

  @if (topupFreeSuccess) {
    <h1 Header >successfully <b>claimed!</b></h1>
  } @else {
    <h1 Header>heads <b>up!</b></h1>
  }

  @if (topupFreeSuccess) {
    <div Content class="result" >
      You have successfully claimed <b>1 GB</b> from <b>{{msisdn}}.</b>
    </div>
  } @else {
    <div Content class="result">
      @if (error) {
        <span>Unable to claim your <b>FREE gig</b> for <b>{{msisdn}}</b>. Please contact support on <b>081 610 1000</b></span>
      }
      @if (!error) {
        <span>
          Your <b>FREE gig</b> has already been claimed for <b>{{msisdn}}.</b>
        </span>
      }
    </div>
  }




</app-bottom-sheet-content-template>