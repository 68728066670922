import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'rain-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Select(CoreState.isLoaderOpen) isOpen$!: Observable<boolean>;

  @Input()
  paymentMethod: any;

  constructor(@Inject(DOCUMENT) private dom: Document) { }

  ngOnInit(): void {
    this.isOpen$.subscribe({
      next: (res) => res ? this.disableScroll() : this.enableScroll()
    });
  }

  private disableScroll() {
    const bodyEl = this.dom.querySelector('body');
  }

  private enableScroll() {
    const bodyEl = this.dom.querySelector('body');
    if (bodyEl) bodyEl.classList.remove('modal-open');
  }

}
