import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { SelectTopUpNumberComponent } from "../../shared/bottom-sheet-content-container/bottom-sheet-components/select-top-up-number/select-top-up-number.component";
import { RegisterComponent } from "../../shared/bottom-sheet-content-container/bottom-sheet-components/register/register.component";
import { PaymentResultNewComponent } from "../../shared/bottom-sheet-content-container/bottom-sheet-components/payment-result/payment-result-new.component";
import { SignInComponent } from "../../shared/bottom-sheet-content-container/bottom-sheet-components/sign-in/sign-in.component";
import { Renderer2 } from "@angular/core";

/**
 * @description Helper function to add the ability to drag a bottomSheet up and down
 * @param bottomSheetRef: MatBottomSheetRef<SelectTopUpNumberComponent|SignInComponent|RegisterComponent|PaymentResultNewComponent, any>
 * @param renderer: the tool used to re render the bottomsheet
 * @returns void
 */
export function makeBottomSheetDraggable(bottomSheetRef: MatBottomSheetRef<SelectTopUpNumberComponent|SignInComponent|RegisterComponent|PaymentResultNewComponent, any>,renderer:Renderer2){
    bottomSheetRef?.afterOpened()
    .pipe()
    .subscribe(() => {
      const container = document.querySelector<HTMLElement>('mat-bottom-sheet-container')!;
      renderer.listen(container, 'touchmove', (event:any) => {
        const movement = event?.touches?.[0]?.clientY;
        if (movement) {
          const newHeight = document.body.clientHeight - movement;
          container.style.height = `${String(newHeight)}px`;
        }
      })
    });

    bottomSheetRef?.afterDismissed()
    .pipe()
    .subscribe(() => {
        bottomSheetRef.dismiss()
    });
}