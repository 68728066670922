<!-- @if((ap$ | async); as ap) { -->
<!-- @if(ap !== undefined) { -->
  <main [class]="'is-' + (ap$ | async)">
    <section class="website-wrapper">
      @if(!(hideHeader$ | async)) {
        <app-header></app-header>
      }
  
      <div class="page-body-container" [class.no-header]="(hideHeader$ | async)">
        <router-outlet></router-outlet>
      </div>
      <app-landing-navbar></app-landing-navbar>
    </section>
    @if(showExpiredModal$ | async) {
      <app-raingo-modal></app-raingo-modal>
    }
  </main>
  @if ((open$ | async)) {
    <raingo-pwa-installer></raingo-pwa-installer>
  }
  <maintenance-mode></maintenance-mode>
  <raingo-full-page-loader></raingo-full-page-loader>
<!-- } -->
<!-- } -->

