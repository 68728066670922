import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  EMPTY,
  Observable,
  delay,
  expand,
  last,
  of,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { CoreState } from '../store/Core/core.state';
import {
  BalancesResponse,
  PurchaseHistoryItem,
  ServiceObject,
  TopUpOption,
  TopupCheckoutResponse,
  PaymentStatusResponse,
  PaymentPayAtStatusResponse,
  BalancesManagerResponse,
} from '../interfaces/interfaces';
import { AuthState } from '../store/Auth/auth.state';
import { AssignSimListItem } from '../interfaces/assign-sim.interfaces';

export enum PaymentStatuses {
  None = 0,
  Failed,
  Success,
  Pending,
  Timeout,
}

interface topupPayload {
  msisdn: string;
  productId: string;
  customer_name?: string;
  walletName?: string;
  unitsOverride?: number;
}

interface speedUpPayload {
  msisdn: string;
  product_id: string;
  service_id: string;
  customer_name?: string;
  walletName?: string;
  unitsOverride?: number;
}

interface topupFreePayload {
  msisdn: string;
  productId: string;
  walletname: string;
  customer_name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TopupService {
  constructor(private http: HttpClient, private store: Store) {}

  getProducts(): Observable<{ [key: string]: TopUpOption[] }> {
    const token = this.store.selectSnapshot(AuthState.getToken);

    const url = `${environment.AXIOM_RAINGO}/product/types`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      }),
    };

    return this.http.get<{ [key: string]: TopUpOption[] }>(url, httpOptions);
  }

  getServices(): Observable<{
    Fixed: ServiceObject[];
    Mobile: ServiceObject[];
    Voice: ServiceObject[];
    Assigned: ServiceObject[];
  }> {
    const token = this.store.selectSnapshot(AuthState.getToken);

    const url = `${environment.AXIOM_RAINGO}/service/findAll`;
    // const url = `/rain-go/service`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      }),
    };

    return this.http.get<{
      Fixed: ServiceObject[];
      Mobile: ServiceObject[];
      Voice: ServiceObject[];
      Assigned: ServiceObject[];
    }>(url, httpOptions);
  }

  getServicesByWhoami(): Observable<{
    Fixed: ServiceObject[];
    Mobile: ServiceObject[];
    Voice: ServiceObject[];
  }> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/service`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      }),
    };

    return this.http.get<{
      Fixed: ServiceObject[];
      Mobile: ServiceObject[];
      Voice: ServiceObject[];
    }>(url, httpOptions);
  }

  getServiceObjectByMsisdn(msisdn: string): Observable<ServiceObject> {
    const token = this.store.selectSnapshot(AuthState.getToken);

    const url = `${environment.AXIOM_RAINGO}/service/msisdn/${msisdn}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      }),
    };

    return this.http.get<ServiceObject>(url, httpOptions);
  }

  getServices2(): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.getToken) ?? '';

    if (!token) {
      return of(null);
    }

    const url = `${environment.SERVICE_PROXY_API}/service`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        Apikey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
      }),
    };

    return this.http.get<any>(url, httpOptions);
  }
  getPurchaseHistory(): Observable<PurchaseHistoryItem[]> {
    const token = this.store.selectSnapshot(AuthState.getToken);

    const url = `${environment.AXIOM_RAINGO}/payments/history?size=5`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<PurchaseHistoryItem[]>(url, httpOptions);
  }
  tlsIdentityCheck(): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.getToken);

    const url = `${environment.TLSEncryption}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<any>(url, httpOptions);
  }
  decodeTLSIdentityResponse(indentifier: any): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/auth/login`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    const body = {
      indentifiers: indentifier,
    };

    return this.http.post<PurchaseHistoryItem[]>(url, body, httpOptions);
  }

  getWallet(serviceId: string): Observable<BalancesResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const services = this.store.selectSnapshot(CoreState.getServices);
    let url = environment.balances;

    if (services) {
      url = environment.balances.replace('{service-id}', serviceId ?? '');
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        apiKey: 'p2jxyqSqVLSRZL54gusMDCoYA48XrZsJ',
      }),
    };

    return this.http.get<BalancesResponse>(url, httpOptions);
  }

  GetAllBalances(): Observable<BalancesManagerResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    let url = environment.balanceManager;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<BalancesManagerResponse>(url, httpOptions);
  }


  topup(
    data: {option: TopUpOption, msisdn: string, name?: string, bucketName?: string}
  ): Observable<TopupCheckoutResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/topup/checkout`;
    
    const topupNumber =
    data.msisdn?.[0] === '0' ? '27' + data.msisdn?.substring(1) : data.msisdn;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    let body: topupPayload = {
      msisdn: topupNumber,
      productId: data.option.id
    };

    if(data.option.id === 'Bb29-ALyVz8Dxu-ndiB') {
      body = {
        msisdn: topupNumber,
        productId: data.option.id,
        unitsOverride:1073741824
      };
    }

    if (data.bucketName) {
      body['walletName'] = data.bucketName;
    }
    if (data.name) {
      body['customer_name'] = data.name;
    }

    return this.http.post<TopupCheckoutResponse>(url, body, httpOptions);
  }

  speedUp(
    data: {product_id: string, msisdn: string, service_id: string, name?: string, bucketName?: string}
  ): Observable<TopupCheckoutResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/topup/checkout/speed-up`;
    
    const topupNumber =
    data.msisdn?.[0] === '0' ? '27' + data.msisdn?.substring(1) : data.msisdn;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    
    let body: speedUpPayload = {
      msisdn: topupNumber,
      product_id: data.product_id,
      service_id: data.service_id
    };


    if (data.bucketName) {
      body['walletName'] = data.bucketName;
    }
    if (data.name) {
      body['customer_name'] = data.name;
    }

    return this.http.post<TopupCheckoutResponse>(url, body, httpOptions);
  }

  topupFree(
    payload: TopUpOption,
    msisdn: string,
    walletName: string,
    name?: string,
    service?: any
  ): Observable<TopupCheckoutResponse> {

    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/topup`;

    const topupNumber =
      msisdn?.[0] === '0' ? '27' + msisdn?.substring(1) : msisdn;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
    };

    const body: topupFreePayload = {
      msisdn: topupNumber,
      walletname: walletName,
      productId: payload?.id,
    };

    if (name) {
      body['customer_name'] = name;
    }

    return this.http.post<any>(url, body, httpOptions); //get response
  }

  public getPaymentStatus(
    paymentId: string
  ): Observable<PaymentStatusResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const requestPath = `${environment?.PAYMENT_GATEWAY}/status/${paymentId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        apiKey: environment?.PAYMENT_GATEWAY_KEY,
      }),
    };

    return this.http.get<PaymentStatusResponse>(requestPath, httpOptions).pipe(
      expand((res: PaymentStatusResponse) => {
        if (res?.data?.message !== 'successfully created checkout') {
          return EMPTY;
        }
        return this.http
          .get<PaymentStatusResponse>(requestPath, httpOptions)
          .pipe(delay(3000));
      }),
      last()
    );
  }

  public getPayAtPaymentStatus(
    paymentId: string
  ): Observable<PaymentPayAtStatusResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const requestPath = `${environment?.PAYMENT_GATEWAY}/${paymentId}/status`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        apiKey: environment?.PAYMENT_GATEWAY_KEY,
      }),
    };

    return this.http
      .get<PaymentPayAtStatusResponse>(requestPath, httpOptions)
      .pipe(
        expand((res: PaymentPayAtStatusResponse) => {
          if (
            res?.message !== 'Checkout:Successful' &&
            res?.status !== 'pending'
          ) {
            return EMPTY;
          }
          return this.http
            .get<PaymentPayAtStatusResponse>(requestPath, httpOptions)
            .pipe(delay(3000));
        }),
        last()
      );
  }

  public getAssignSimsByPrimaryID(id: string): Observable<AssignSimListItem[]> {
    const url = `${environment.DIGITAL_IDENTITY}/assigned/primary/${id}`;
    const token = this.store.selectSnapshot(AuthState.getToken);

    const httpOptions = {
      headers: new HttpHeaders({
        accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        apiKey: environment?.PAYMENT_GATEWAY_KEY,
      }),
    };
    return this.http.get<AssignSimListItem[]>(url, httpOptions);
  }
}
