import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DigitalIdentityClientObject } from 'src/app/interfaces/interfaces';
import { AuthState } from 'src/app/store/Auth/auth.state';

@Component({
  selector: 'raingo-askrain-widget-sheet',
  templateUrl: './askrain-widget-sheet.component.html',
  styleUrl: './askrain-widget-sheet.component.scss'
})
export class AskrainWidgetSheetComponent {
  @Select(AuthState.isAuthed) isAuthed$: Observable<boolean>;
  @Select(AuthState.getAxiomUser) user$: Observable<DigitalIdentityClientObject>;
}
