<app-bottom-sheet-content-template>

  <div Content>
    <div class="info-lockup">
      <p>To continue with <b>assign SIM</b>, make sure:</p>
      <ul>
        <li>your rain mobile SIM is inserted</li>
        <li>your mobile data is on</li>
        <li>your wifi is off</li>
      </ul>
      <p>If you're on a dual SIM phone, you'll need to switch off your other SIM during this process.</p>
      <button class="button" (click)="retryWhoami()">
        @if (!(loading$ | async)) {
          <span>retry</span>
        } @else {
          <div class="loading-container">
            <app-loading-circle [loaderColor]="'white'" [size]="'20'"></app-loading-circle>
          </div>
        }
        <ng-template #loading>
          <div class="loading-container">
            <app-loading-circle [loaderColor]="'white'" [size]="'20'"></app-loading-circle>
          </div>
        </ng-template>
      </button>
    </div>

    <div class="disclosure">
      <p>rain mobile SIM not detected?<br>Call support on <a href="tel:+27816101000">081 610 1000</a>.</p>
    </div>
  </div>

</app-bottom-sheet-content-template>
