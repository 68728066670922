import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Utils } from '@Utils';
import { SSOSignIn, TurnOffSignInLoading } from '../store/Core/actions/core.actions';
import { environment } from 'src/environments/environment';
import { AuthState } from '../store/Auth/auth.state';
import { CoreState } from '../store/Core/core.state';
import { ShowLoader, SignOut } from '../store/Auth/actions/auth.actions';
import { Navigate } from '@ngxs/router-plugin';
import { CloseBottomSheet } from '../store/BottomSheet/actions/bottom-sheet-actions.actions';

export interface SSOPayload {
  tauToken: string;
  token: string;
  redirectUrl?: string;
  tokenSme?: string;
  mode?: string;
  referrrer: {
    origin: string,
    page: string
  };
  config: {
    hasPrepaid: boolean
  }
}

@Injectable({
  providedIn: 'root',
})
export class SSOService {
  private _cryptor = new Utils.Helpers.Encryptor();
  private _defaultTargetDomain = `${environment.RAIN_SSO_URL}/login`;
  
  constructor(private store: Store) {}

  /**
   * @description creates the url with the AES encrypted sso qParams to redirect to rain.co.za
   * @param {string} [redirectToSlug='my-rain']
   * @return {*}  {Promise<string>}
   * @memberof SSOService
   */
  public createUrl(redirectToSlug: string = 'manage/services', toAskRain: boolean = false, config: any = {}): Promise<string> {
    
    if(toAskRain) this._defaultTargetDomain = environment.ASK_RAIN_SSO_URL;
    else this._defaultTargetDomain = `${environment.RAIN_SSO_URL}/login`;

    const location = window.location;
    const tauToken = this.store.selectSnapshot(AuthState.getTauToken);
    const token = this.store.selectSnapshot(AuthState.getToken);
    const tokenSme = this.store.selectSnapshot(AuthState.getTokenSme);
    const hasPrepaid = this.store.selectSnapshot(CoreState.HasPayAsYouUseFlag);
    config = Object.assign(config, {hasPrepaid});
    const paramsMap: {tauToken: string | null, token: string | null, redirectUrl: string | null, config: any, tokenSme?: string | null, referrer: {origin: string, page: string} } = {
      tauToken,
      token,
      redirectUrl: `/${redirectToSlug}`,
      config,
      referrer: {
        origin: location.origin,
        page: location.pathname,
      }
    };
    
    if (tokenSme) paramsMap.tokenSme = tokenSme;
    
    return new Promise((resolve, reject) => {
      const encryptedData = this._cryptor.encrypt(JSON.stringify(paramsMap));
      const url = `${
        this._defaultTargetDomain
      }?sso=${encodeURIComponent(encryptedData)}`;

      if(url) resolve(url);
      reject('unable encode url');
    });

  }

  /**
   * @description a function to decrypt the sso payload
   * @param {string} fullUrl
   * @return {*}  {*}
   * @memberof SSOService
   */
  public decodeUrlParams(fullUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const encodedData = this._getParamsByName('sso', fullUrl) as string;
        const decryptedData = this._cryptor.decrypt(encodedData);
        if(decryptedData) resolve(decryptedData);
        else reject('unable to decode sso payload')
    });
  }

  public handleSSOSignIn(encyptedSSOData: string) {
    console.log('sso href:', encyptedSSOData);
    
    this.decodeUrlParams(encyptedSSOData).then(
      
      (data) => {
        const sso: SSOPayload = JSON.parse(data);
        console.log('sso Payload:', sso);
        
        const redirectUrl = sso.redirectUrl ? sso.redirectUrl : '/services';
        this.store.dispatch([new ShowLoader(),new SignOut(true), new TurnOffSignInLoading(),new CloseBottomSheet(), new Navigate([redirectUrl])])
        this.store.dispatch([new SSOSignIn(JSON.parse(data))])
      }
    )

  }

  /**
   * @description internal function to get the sso qParams when recieving the a redirect from rain.co.za
   * @private
   * @param {string} name
   * @param {string} url
   * @return {*}  {(string | null)}
   * @memberof SSOService
   */
  private _getParamsByName(name: string, url: string): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
