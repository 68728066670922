import { Utils } from "@Utils";
import { BottomSheetName } from "../../BottomSheet/intefaces/bottom-sheet-interfaces";

export class SetCanAssign {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Set can assign');
}

export class StartAssignSimProcess {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Start assign sim process');
}
export class AssignSim {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Initiate assign sim');
    constructor(public readonly payload: {loginId: string, msisdn: string}) {}
}

export class AssignSimSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Assign sim success');
    constructor(public readonly payload: any) {}
}

export class AssignSimFail {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Assign sim fail');
    constructor(public readonly payload: any) {}
}
export class StopAssignSimProcess {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Stop assign sim process');
}

export class NextStep {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Initiate next step');
    constructor(public readonly payload?: string) {}
}
export class RetryLoading {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] retry loading');
    constructor(public loading: boolean) {}
}