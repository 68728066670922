import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, tap, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/Auth/auth.state';
import { TicketsService } from './ticket.service';
import { IAddTicketRequest } from 'src/app/interfaces/ticket.interface';
import { ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { IFCCheckoutResponse, IServicePolicyResponse } from 'src/app/interfaces/services.interface';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    
    constructor(private _http: HttpClient, private store: Store, private readonly ticketSvc: TicketsService) {  }

    public saveName(name: string, serviceID: string): Observable<any> {
        const url = `${environment.SERVICE_PROXY_API}/fullcircle/service/${serviceID}`;
        const selectedSVC = this.store.selectSnapshot(DashboardState.GetSelectedService);
        let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
        let userToken = ((selectedSVC.account_type?.toLocaleLowerCase() !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);
        const user = this.store.selectSnapshot(AuthState.getAxiomUser)!;

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'ApiKey': 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
                'Authorization': userToken!,
            })
        };

        const payload = {
            product_configs: {
                configs: [
                    {
                        "name": "action",
                        "value": "migrate"
                    },
                    {
                        "name": "friendly_name",
                        "value": name
                    }
                ]
            }
        }
        return this._http.patch<any>(url, payload , httpOptions);
    }

    public toggleInternationalCalling(serviceID: string, isEnabled: boolean) {
      const url = `${environment.BASE_API_URL}/V3/fullcircle/service/${serviceID}`
      let toggle = isEnabled === true? "enable": "disable";
      const selectedSVC = this.store.selectSnapshot(DashboardState.GetSelectedService);

      let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
      let userToken = ((selectedSVC.account_type?.toLocaleLowerCase() !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);
      const body = {
        product_configs: {
          configs: [
            {
              name: "action",
              value: "international_access"
            },
            {
              name: "status",
              value: toggle
            }
          ]
        }
      }
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization:  userToken!,
          apikey: `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`,
          key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
        })
      };
  
      return this._http.patch(url, body, httpOptions)
    }

    public levelMigrate(request: { serviceId: string; product_id: string, msisdn: string }, isRainMobile: boolean): Observable<any> {
      let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
      let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
      let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getToken);
      const token = this.store.selectSnapshot(AuthState.getToken);
        let payload = {
          product_id: request.product_id,
          service_id: request.serviceId
        };
        
        let requestPath = `${environment.AXIOM_RAINGO}/service/vas-level-migrate`;
        if(!isRainMobile) {
          requestPath = `${environment.AXIOM_RAINGO}/service/level-migrate`;
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          }),
        };
        return this._http.post(requestPath, payload, httpOptions as any);
    }

    public FetchServicePolicySpeed(mode: string) : Observable<IServicePolicyResponse>{
      const url = `${environment.BASE_API_URL}/V1/rain-web/fc/service-policy`
      
      let smeToken = this.store.selectSnapshot(AuthState.getTokenSme)
      let userToken = ((mode === 'sme') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);
      
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: userToken!,
          ApiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH'
        })
      };
  
      return this._http.get<IServicePolicyResponse>(url, httpOptions)
    }

    ChangeServiceSpeed(addonId: string, serviceId: string){
      const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_addon`
      let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
      let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
      let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

      const payload = {
          product_id: addonId,
          service_id :serviceId
      }
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }),
      };
      
      return this._http.patch(requestPath, payload, httpOptions as any);
  }

  RevokeSpeedDownRequest(serviceId: string): Observable<any> {
    const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_addon-revoke`;
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        }),
    };

    return this._http.post(requestPath, {}, httpOptions as any)
  }

  public RevokeLevelMigration(payload: {product_id: string, service_id: string}): Observable<any> {
    let userId = this.store.selectSnapshot(AuthState.getAxiomUser)?.id
    const requestPath = `${environment.AXIOM_RAINGO}/service/revoke-migrate'`;

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken!,
        userId : userId!
      })
    };

    const configs = [];

    return this._http.post(requestPath, payload, httpOptions)
  }

  GetCheckoutDetails(order_id: string, amount: string): Observable<IFCCheckoutResponse> {
    const requestPath = `${environment.FullCIRCLE_API}/billing/checkout`;
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    
    const domain = window.location.host;
    const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `${userToken}`,
          apiKey: apiKey,
          Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
        }),
    };

    const payload = {
      amount: amount,
      order_id: order_id,
      type: "register_and_pay"
    }

    return this._http.post<IFCCheckoutResponse>(requestPath, payload, httpOptions);
  }


}