@if((speedUpConfig$ | async); as config) {
    <app-bottom-sheet-content-template>
    
        <h1 Header><strong>speed change successful</strong></h1>
        
        <div Content>
            <div class="disclaimer" *ngIf="(selectedSvc$ | async) as svc">
                <p>Nice one! Your rain<b>one</b> 5G home wifi speed will be {{formatSpeedUpName(config.selectedSpeed?.name)}} from your next bill.</p>
            </div>
            <div class="button-container">
                <button class="solid" (click)="dismiss()">got it</button>
            </div>
        </div>
    
    </app-bottom-sheet-content-template>
}


