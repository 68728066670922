import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrackingEvent } from 'src/app/interfaces/tracker.interface';
import { v4 as uuidv4 } from 'uuid';
import { PostEvent } from './actions/analytics.actions';

function loadDefault():AnalyticsStateModel {
    return {
        step: 0,
        sessionID: uuidv4(),
        logs: null
    }
}

interface AnalyticsStateModel {
    step: number,
    sessionID: string,
    logs: Array<TrackingEvent> | null
}

@State<AnalyticsStateModel>({
    name: 'analytics',
    defaults: loadDefault()
})

@Injectable()
export class AnalyticsState {

    constructor(private store: Store) { }

    @Action(PostEvent)
    PostEvent(ctx: StateContext<AnalyticsStateModel>, action: PostEvent) {
        const state = ctx.getState();
        const { event } = action;
        const allLogs = state.logs ? state.logs : [];
        allLogs.push(event);
        ctx.patchState({
            step: state.step++,
            logs: allLogs
        })

    }

}