import { Utils } from '@Utils';
import Levels from 'src/app/core/enums/levels.enum';
import { ILevel } from '../../interfaces/level.interface';

export class SetSelectedLevel {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Set selected level');
	constructor(public readonly payload: ILevel) {}
}

export class ClearLevelsConfig {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Clear levels config');
}

export class IncrementLines {
	static readonly type = Utils.Helpers.Type('[Dashboard: Lines select] Increment');
}
export class DecrementLines {
	static readonly type = Utils.Helpers.Type('[Dashboard: Lines select] Deccrement');
}

export class NextStep {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Next Step');
}

export class SetStep {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Set Step');
	constructor(public readonly step: number) {}
}

export class SetCurrentLevel {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Set current level');
	constructor(public readonly payload: number) {}
}

export class InitMigrations {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Init level migrations');
	// constructor(public readonly payload: {serviceId: string; product_id: string, msisdn: string}) {}
}

export class InitMigrationsSuccess {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Init level migrations success');
	constructor(public readonly payload: {res: any, config: any}) {}
}
export class InitMigrationsFail {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Init level migrations fail');
	constructor(public readonly payload: any) {}
}

export class ShowSuccessFailSheet {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Show success sheet');
}


export class ClearMigrations {
	static readonly type = Utils.Helpers.Type('[Dashboard: Level-Up] Clear level migrations');
}

