import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ProductActions } from '../actions';
import { TopUpOption } from 'src/app/interfaces/interfaces';
import { TopupService } from 'src/app/services/topup.service';
import { tap } from 'rxjs';
import { sortTopUpOptions } from 'src/app/utils/helpers/topup-helper';
import { AuthState } from '../../Auth/auth.state';

const dummyWifiPaymentOptions = {
  '30 day': [
    {
      id: 'Bg12-BHj5M3NmnPKDjg',
      base_type: null,
      href: null,
      referred_type: null,
      schema_location: null,
      type: 'PREPAID',
      amount: 100,
      quantity: 20,
      unit: 'GB',
      updated_at: '2024-07-16 13:20:35.420794',
      inserted_at: null,
      enabled: true,
    },
    {
      id: 'Bg12-BLSRwS1Sbp3h5w',
      base_type: null,
      href: null,
      referred_type: null,
      schema_location: null,
      type: 'PREPAID',
      amount: 150,
      quantity: 50,
      unit: 'GB',
      updated_at: '2024-07-16 13:20:35.420794',
      inserted_at: null,
      enabled: null,
    },
    {
      id: 'Bg12-BLQnD1EnAOutXg',
      base_type: null,
      href: null,
      referred_type: null,
      schema_location: null,
      type: 'PREPAID',
      amount: 200,
      quantity: 100,
      unit: 'GB',
      updated_at: '2024-07-16 13:20:35.420794',
      inserted_at: null,
      enabled: null,
    },
  ],
};

function loadDefault(): ProductStateModel {
  return {
    products: null,
    wifiProducts: null,
  };
}

interface ProductStateModel {
  products: { [key: string]: TopUpOption[] } | null;
  wifiProducts: { [key: string]: TopUpOption[] } | null;
}

@State<ProductStateModel>({
  name: 'product_state',
  defaults: loadDefault(),
})
@Injectable()
export class ProductState {
  @Selector()
  static GetProducts(state: ProductStateModel) {
    return state.products;
  }

  @Selector()
  static GetWifiProducts(state: ProductStateModel) {
    return state.wifiProducts;
  }

  constructor(
    private topupService: TopupService,
    private readonly store: Store
  ) {}

  @Action(ProductActions.FetchProducts)
  FetchProducts(
    ctx: StateContext<ProductStateModel>,
    action: ProductActions.FetchProducts
  ) {
    const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
    if (isAuthed) return;

    return this.topupService.getProducts().pipe(
      tap({
        next: (res: { [key: string]: TopUpOption[] }) => {
          if (Object.keys(res).length) {
            const { DATA, VOICE, SMS } = sortTopUpOptions(res);
            return ctx.patchState({
              products: { DATA, VOICE, SMS },
            });
          }

          return;
        },
        error: () => {
          console.log('error getting products');
        },
      })
    );
  }

  @Action(ProductActions.FetchProductsSuccess)
  FetchProductsSuccess(
    ctx: StateContext<ProductStateModel>,
    action: ProductActions.FetchProductsSuccess
  ) {
    const { res } = action.payload as any;
    if (Object.keys(res).length) {
      const { DATA, VOICE, SMS, PREPAID } = sortTopUpOptions(res as any);

      if(PREPAID && PREPAID.length > 0) {
        ctx.patchState({
          wifiProducts: {['30 Day']: [...PREPAID]}
        });
      }
      ctx.patchState({
        products: { DATA, VOICE, SMS },
      });
    }
  }
}
