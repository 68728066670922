@if (promo) {
  @if (promo.active) {
    <section
        (click)="setSelectedOption(promo.promoTopup, promoDetails.canBuy)" class="promo-lockup" [ngClass]="{
        'img-mode': promo.hasImage,
        'clickable': promo.canBuy
    }">
      @if (!promo.hasImage) {
        <div class="inner-container">
          <p>{{promo.promoTopup.quantity}} {{promo.promoTopup.unit}}</p>
          <p>R{{promo.promoTopup.amount}}</p>
        </div>
      } @else {
        <div class="inner-container">
          <img [src]="promo.imgUrl" alt="">
        </div>
      }
    </section>
  }
  @if (!promo.active && promo.placeholder.active) {
    <section class="promo-lockup img-mode clickable">
      @if ((eligbleFreeGigServices$ | async)?.length > 0 && pageSlug !== 'wifi') {
        <div class="inner-container" (click)="initiateFreeGiG()">
          <img src="assets/images/banners/free-gig-banner.webp" alt="free gig banner">
        </div>
      } @else {
        <div class="inner-container generic">
          <img [src]="promo.placeholder.image" alt="">
        </div>
      }
      <ng-template #generic>
        <div class="inner-container generic">
          <img [src]="promo.placeholder.image" alt="">
        </div>
      </ng-template>
    </section>
  }
}