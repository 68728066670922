<app-bottom-sheet-content-template>

    <h1 Header>oh no</h1>

    <div Content>
        <div class="info-lockup">
            <p>This service is not eligible to be topped up.</p>
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>

</app-bottom-sheet-content-template>