import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TopupService } from 'src/app/services/topup.service';
import { Navigate } from '@ngxs/router-plugin';
import { PaymentStatusResponse, PaymentPayAtStatusResponse } from 'src/app/interfaces/interfaces';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { PaymentActions } from './actions';
import { TopupState } from '../topup/topup.state';
import { PaymentCacheService } from 'src/app/services/payment-cache.service';
import { PAYMENT_METHOD, SELECTED_TOPUP, TOPUP_RESULT } from 'src/app/constants/constants';
import { HttpErrorResponse } from '@angular/common/http';

interface PaymentStateModel {
    loaded: boolean,
    paymentResult: string | null
    paymentMethod: any
}
@State<PaymentStateModel>({
    name: 'payment_state',
    defaults: {
        loaded: false,
        paymentResult: null,
        paymentMethod: null
    }
})
@Injectable()
export class PaymentState {

    @Selector()
    static loaded(state: PaymentStateModel) { return state?.loaded }
    @Selector()
    static paymentResult (state: PaymentStateModel) { return state?.paymentResult }
    @Selector()
    static GetMethod (state: PaymentStateModel) { return state?.paymentMethod }

    constructor(private topupService: TopupService, private datalayerService: DataLayerService, private store: Store, private paymentCacheService: PaymentCacheService) {}

    @Action(PaymentActions.SavePaymentMethod)
    SavePaymentMethod(ctx: StateContext<PaymentStateModel>, action: PaymentActions.SavePaymentMethod) {
        this.paymentCacheService.setPaymentCacheItem(PAYMENT_METHOD, action?.method?.paymentMethodName);
        return ctx.patchState({
            paymentMethod: action?.method?.paymentMethodName
        })
    }

    @Action(PaymentActions.GetPaymentResult)
    GetPaymentResult(ctx: StateContext<PaymentStateModel>, action: PaymentActions.GetPaymentResult) {
        const { method } = action;
        const paymentResult = this.store.selectSnapshot(TopupState.getTopUpResult) ?? this.paymentCacheService.getPaymentCacheItem(TOPUP_RESULT);
        const { checkout_id, checkout } = paymentResult ?? {};
        const { referenceKey } = checkout ?? {};
        const isPeachPayment = method === "peach";
        const topupStatusPayload = method === "peach" ? checkout_id : referenceKey;


        if (isPeachPayment) {
            return this.topupService.getPaymentStatus(topupStatusPayload ?? "")?.pipe(
                tap({
                    next: (res: PaymentStatusResponse) => {
                        ctx.patchState({
                            loaded: true,
                            paymentResult: res?.data?.message
                        })
    
                        if (res?.data?.message === "Transaction succeeded") {
                            ctx.dispatch(new Navigate(['/services']));
                        }
                        return
    
                    },
                    error: (err: HttpErrorResponse) =>  {
                        if(err.status === 404) {
                            setTimeout(() => {
                                return ctx.dispatch(new PaymentActions.GetPaymentResult(method));
                            }, 5000);
                        }
                    },
                    complete:() => {
                        const success = ctx.getState()?.paymentResult === "Transaction succeeded";
                        const selectedTopup = this.store.selectSnapshot(TopupState.getSelectedTopup)! ?? this.paymentCacheService.getPaymentCacheItem(SELECTED_TOPUP);
                        success ? 
                            this.datalayerService.purchase(selectedTopup) :
                            this.datalayerService.purchaseFail(selectedTopup);
                    },
                })
            )
        } else {
            return this.topupService.getPayAtPaymentStatus(topupStatusPayload ?? "")?.pipe(
                tap({
                    next: (res: PaymentPayAtStatusResponse) => {
                        ctx.patchState({
                            loaded: true,
                            paymentResult: res?.message
                        })
    
                        if (res?.message === "Transaction succeeded") {
                            ctx.dispatch(new Navigate(['/services']))
                        }
                        return
    
                    },
                    error: (err: HttpErrorResponse) =>  {
                        if(err.status === 404) {
                            setTimeout(() => {
                                return ctx.dispatch(new PaymentActions.GetPaymentResult(method));
                            }, 5000);
                        }
                    },
                    complete:() => {
                        const success = ctx.getState()?.paymentResult === "Transaction succeeded"
                        const selectedTopup = this.store.selectSnapshot(TopupState.getSelectedTopup)! ?? this.paymentCacheService.getPaymentCacheItem(SELECTED_TOPUP);
                        success ? 
                            this.datalayerService.purchase(selectedTopup) :
                            this.datalayerService.purchaseFail(selectedTopup);
                    },
                })
            )
        }
    }

    @Action(PaymentActions.ClearPaymentResult)
    ClearPaymentResult(ctx: StateContext<PaymentStateModel>, action: PaymentActions.ClearPaymentResult) {
        ctx.setState({
            loaded: false,
            paymentResult: null,
            paymentMethod: null
        })
    }

}