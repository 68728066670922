import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
    AngularFireStorage
  } from '@angular/fire/compat/storage';
  
import { FetchRaingoConfigResponse, FetchRaingoPromoResponse } from '../interfaces/interfaces';
import { HttpClient } from '@angular/common/http';
import { ILevel } from '../pages/Dashboard/interfaces/level.interface';

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {
    
    constructor(private firestore: AngularFirestore, private storage: AngularFireStorage, private _http: HttpClient) {  }

    getTopupPromo(): Observable<FetchRaingoPromoResponse> {
        const baseUrl = window?.location?.href?.toLocaleLowerCase()?.includes("www.raingo.co.za") ? 'website-config' : 'website-config-dev'; 
        return this.firestore
            .doc(`${baseUrl}/raingo-promo`)
            .snapshotChanges()
            .pipe(
                map(
                    (actions) => {
                    const data: any = actions.payload.data();
                    return data;
                    }
                )
            )
    }

    GetConfig(): Observable<FetchRaingoConfigResponse> {
        const baseUrl = window?.location?.href?.toLocaleLowerCase()?.includes("www.raingo.co.za") ? 'website-config' : 'website-config-dev'; 
        return this.firestore
            .doc(`${baseUrl}/raingo-config`)
            .snapshotChanges()
            .pipe(
                map(
                    (actions) => {
                    const data: any = actions.payload.data();
                    return data;
                    }
                )
            )
    }

    GetLevelsConfig(): Observable<{[type: string]: Array<ILevel>} > {
        const baseUrl = window?.location?.href?.toLocaleLowerCase()?.includes("www.raingo.co.za") ? 'website-config' : 'website-config-dev'; 
        const url = "https://firebasestorage.googleapis.com/v0/b/rain-mobile-a12e2.appspot.com/o/raingo%2Fconfigs%2Fraingo-levels-config.json?alt=media&token=ec7fc426-2949-4bed-9b41-40db31d4075f";

        return this._http.get<{[type: string]: Array<ILevel>} >(url);
    }
}