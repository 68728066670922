
<app-bottom-sheet-content-template>

    <h1 Header>what's assign SIM?</h1>
    
    <div Content>
        <div class="info-lockup">
            
            <div class="unique-selling-point">
                <img class="usp-image mobile-sim" src="assets/images/assign-sim/raingo-4g-mobile-sim.png" alt="rainone mobile sim">
                <p class="usp-text">with rain<b>one</b> you get <b>FREE</b> monthly gigs, minutes & sms.</p>
            </div>
        </div>
        <div class="info-lockup">
            <h3><b>assign SIM</b> lets you:</h3>
            <div class="unique-selling-point">
                <img class="usp-image" src="assets/images/assign-sim/manage-sim-icon.png" alt="manage sim">
                <p class="usp-text">take control of a SIM with your <b>own rain account</b></p>
            </div>
            <div class="unique-selling-point">
                <img class="usp-image" src="assets/images/assign-sim/shopping-cart.png" alt="shopping cart">
                <p class="usp-text"><b>buy more</b> data, minutes & sms on the go</p>
            </div>
            <div class="unique-selling-point">
                <img class="usp-image" src="assets/images/assign-sim/credit-card.png" alt="credit card">
                <p class="usp-text"><b>save your card</b> details for quicker check outs</p>
            </div>
        </div>
        <div class="button-container">
            <button class="solid" (click)="proceedWithAssignSim()">assign SIM now</button>
        </div>
        <div class="footer">
            <h3>If this SIM is part of your rain<b>one</b>, no need to assign SIM. Simply <b>close and sign in</b> on the home screen.</h3>
        </div>

    </div>

</app-bottom-sheet-content-template>