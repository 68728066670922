<app-bottom-sheet-content-template>
    <h1 Header>assign SIM<strong> successful</strong></h1>
    <div Content>
        <p class="primary-message">A notification has been sent to the rainone account holder.</p>

        <div class="actions-footer">
            <button class="submit" (click)="dismiss()">
                got it
            </button>
        </div>
    </div>

</app-bottom-sheet-content-template>