@if (products && tabTypes) {
    <section class="tabs-wrapper">
        <div class="tabs-header">
            <ul [style.grid-template-columns]="'repeat(' + tabTypes.length +',  1fr)'" [ngClass]="{
                'single-tab': tabTypes.length === 1,
                'two-tabs': tabTypes.length === 2,
                'three-tabs': tabTypes.length === 3
            }">
                @for (tType of tabTypes; track tType) {
                    <li (click)="selectTab(tType)" [class.selected]="selectedTab === tType">
                        <button>{{tType}}</button>
                        <span class="border"></span>
                    </li>
                }
            </ul>
        </div>
        <div class="tabs-lockups">
            <ul [ngClass]="{
                'single-tab': tabTypes.length === 1,
                'two-tabs': tabTypes.length === 2,
                'three-tabs': tabTypes.length === 3
            }" [class]="'product-type-'+formatTabName(selectedTab)">
                @for (tType of tabTypes; track tType) {
                <li class="tab" [class.selected]="selectedTab === tType" [class.free]="selectedTab === tType">
                    
                    @switch (tType.toLowerCase()) {
                        @case ('speed up') {
                            <h3 class="speed-up-copy">at <strong>R200</strong> per level per month</h3>
                            <div class="topup-card-container speedy">
                                @for (option of speedUps; track option) {
                                <div (click)="SetSelectedSpeedChange(option)" class="topup-option {{tType}}"
                                    [class.no-freeGig]="!(eligbleFreeGigServices$ |async)" [ngClass]="{
                                        'notselected': (!!(selectedTopUp$ | async) && option !== (selectedTopUp$ | async)?.selectedTopup),
                                        'is-disabled': isCurrentSpeedPolicy(option.id)
                                    }">
                                    <button class="topup-quantity">speed up - <strong>{{formatSpeedUpName(option.name)}}</strong></button>
                                    <span class="topup-amount-lockup"><p class="topup-amount">R{{option?.price }} <span>a month</span></p></span>
                                </div>
                                }
                            </div>
                        }
                        @case ('level up') {
                            <div class="topup-card-container level-up">
                                @for (option of productsByType[tType]; track option) {
                                    
                                    @if(selectedSvc$ | async; as selectedSvc) {
                                        @if(selectedSvc.product.config.displayName === 'rain mobile SIM') {
                                            <div (click)="goToLevelUpPage(); setSelectedOption(option)" class="topup-option level-up">
                                                <button class="topup-quantity">level up your rain<strong>mobile</strong> line</button>
                                            </div>
                                        } @else {
                                            <div (click)="goToLevelUpPage(); setSelectedOption(option)" class="topup-option level-up">
                                                <button class="topup-quantity">level up your rain<strong>one</strong> mobile lines</button>
                                            </div>
                                        }
                                    }
                                }
                            </div>
                        }
                        @case ('voice') {
                            <div class="topup-card-container" [class.is-buy-more-journey]="isBuyMoreJourney">
                                @for (option of productsByType[tType]; track option) {
                                    @if(option.amount === 100 && !isBuyMoreJourney && selectedSvc$ | async; as selectedSvc) {
                                        <div (click)="openPurchaseIntSheet(selectedSvc); setSelectedOption(option, true)" [class.is-disabled]="!(isAuthed$ | async) && selectedSvc.international_access === 'disabled'" class="topup-option international-wallet {{tType}}">
                                            <span>international wallet <strong>top up</strong></span>
                                            <p class="topup-amount">{{ "R"+ option?.amount }}</p>
                                        </div>
                                    }@else if(option.amount !== 100) {
                                        <div (click)="openSheet(option); setSelectedOption(option)" class="topup-option {{tType}}"
                                        [class.international-wallet]="">
                                        <button class="topup-quantity">{{option?.quantity}} {{option?.unit}}</button>
                                        <p class="topup-amount">{{ !option?.amount ? 'FREE' : "R"+ option?.amount }}</p>
                                    </div>
                                    }
                                }
                            </div>
                        }
                        @case ('data') {
                            <div class="topup-card-container">
                                @for (option of productsByType[tType]; track option) {
                                    @if(option.quantity === 1) {
                                        @if(isEligbleForPromo(selectedSvc$ | async) && option.amount == 10) {
                                        <div (click)="openSheet(option); setSelectedOption(option)" class="topup-option {{tType}}"
                                            [class.no-freeGig]="!(eligbleFreeGigServices$ |async)" [ngClass]="{
                                                'notselected': (!!(selectedTopUp$ | async) && option !== (selectedTopUp$ | async)?.selectedTopup),
                                                'free': !option?.amount,
                                                'ten-gig-promo' : true
                                            }">
                                            <button class="topup-quantity">{{option?.quantity}} {{option?.unit}}</button>
                                            <p class="topup-amount">{{ !option?.amount ? 'FREE' : "R"+ option?.amount }}</p>
                                        </div>
                                        } @else if(!isEligbleForPromo(selectedSvc$ | async) && option.amount == 50) {
                                            <div (click)="openSheet(option); setSelectedOption(option)" class="topup-option {{tType}}"
                                            [class.no-freeGig]="!(eligbleFreeGigServices$ |async)" [ngClass]="{
                                                'notselected': (!!(selectedTopUp$ | async) && option !== (selectedTopUp$ | async)?.selectedTopup),
                                                'free': !option?.amount,
                                            }">
                                            <button class="topup-quantity">{{option?.quantity}} {{option?.unit}}</button>
                                            <p class="topup-amount">{{ !option?.amount ? 'FREE' : "R"+ option?.amount }}</p>
                                        </div>
                                        }
                                    } @else {
                                        <div (click)="openSheet(option); setSelectedOption(option)" class="topup-option {{tType}}"
                                        [class.no-freeGig]="!(eligbleFreeGigServices$ |async)" [ngClass]="{
                                            'notselected': (!!(selectedTopUp$ | async) && option !== (selectedTopUp$ | async)?.selectedTopup),
                                            'free': !option?.amount
                                        }">
                                        <button class="topup-quantity">{{option?.quantity}} {{option?.unit}}</button>
                                        <p class="topup-amount">{{ !option?.amount ? 'FREE' : "R"+ option?.amount }}</p>
                                    </div>
                                    }
                                }
                            </div>
                        }
                        @default {
                            <div class="topup-card-container">
                                @for (option of productsByType[tType]; track option) {
                                <div (click)="openSheet(option); setSelectedOption(option)" class="topup-option {{tType}}"
                                    [class.no-freeGig]="!(eligbleFreeGigServices$ |async)" [ngClass]="{
                                        'notselected': (!!(selectedTopUp$ | async) && option !== (selectedTopUp$ | async)?.selectedTopup),
                                        'free': !option?.amount
                                    }">
                                    <button class="topup-quantity">{{option?.quantity}} {{option?.unit}}</button>
                                    <p class="topup-amount">{{ !option?.amount ? 'FREE' : "R"+ option?.amount }}</p>
                                </div>
                                }
                            </div>
                        }
                    }
                </li>
                }
            </ul>
        </div>
    </section>
}