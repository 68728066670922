<app-bottom-sheet-content-template>

  <h1 Header><strong>assign</strong> this SIM to me</h1>

  <div Content>
    <div class="info-lockup">
      <p>This request will be sent to the rain<strong>one</strong> account holder's phone. Once they've given the thumbs up, you'll be able to set up your own rain account.</p>
      <p>Just type in your name, so they know who you are, and hit send.</p>
    </div>
    <form class="form-container" [formGroup]="assignSimForm">
      <div class="input-container">
        <input id="contactName" type="text" [formControlName]="'name'" placeholder="enter your name">
        @if (assignSimForm.get('name')?.errors && assignSimForm.get('name')?.dirty && attemptedSubmit) {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a name
            </span>
          </p>
        }
      </div>

      <button class="submit" (click)="signIn()" [disabled]="assignSimForm.invalid" [ngClass]="{'disabled': assignSimForm.invalid}">
        send
      </button>
    </form>
  </div>
</app-bottom-sheet-content-template>