import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AxiomRegistrationResponse, DigitalIdentityClientObject, RegistrationPayload } from '../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/Auth/auth.state';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    
    constructor(private http: HttpClient, private store: Store) {  }

    registerUser(registrationPayload:RegistrationPayload): Observable<AxiomRegistrationResponse> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/create`

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<AxiomRegistrationResponse>(url, registrationPayload , httpOptions);
    }

    assignUser(registrationPayload:RegistrationPayload): Observable<AxiomRegistrationResponse> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/assign`
        const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                apiKey: apiKey,
            })
        };
        return this.http.post<AxiomRegistrationResponse>(url, registrationPayload , httpOptions);
    }

    getUserDetails(userId: string): Observable<DigitalIdentityClientObject> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/${userId}`
        const token = this.store.selectSnapshot(AuthState.getToken);

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                "Authorization": token!
            })
        };
        return this.http.get<DigitalIdentityClientObject>(url , httpOptions);
    }

    public getBillCycleDate(): Observable<{user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period: {start: number}}}}> {
        const url = `${environment.AXIOM_RAINGO}/user/bill-cycle`
        const token = this.store.selectSnapshot(AuthState.getToken);

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                "Authorization": token!
            })
        };
        return this.http.get<{user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period: {start: number}}}}>(url , httpOptions);
    }

}