import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { NextStep, StartAssignSimProcess } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-assign-sim-wizard',
  templateUrl: './assign-sim-wizard.component.html',
  styleUrls: ['./assign-sim-wizard.component.scss']
})
export class AssignSimWizardComponent implements OnInit {

  constructor(private store: Store) {}
  
  identified?: boolean | null;
  
  ngOnInit(): void {
    this.identified = !!this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);
  }

  public proceedWithAssignSim() {

    if (!this.identified || this.disableAssignSimIf5G()) {
      this.store.dispatch(new NextStep('4'));
      return;
    };
    
    this.store.dispatch(new StartAssignSimProcess());
    return;
  }

  public disableAssignSimIf5G() {
    const dCodedCreds = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);

    if(dCodedCreds) {
      const msisdn = dCodedCreds?.udg.msisdn as String;
      return Boolean(msisdn.length > 11);
    }

    return false;
  }

}
