import { Utils } from '@Utils';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DigitalIdentityClientObject, TLSDecryptedResponse } from 'src/app/interfaces/interfaces';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { InitiateAssignSimOTP } from 'src/app/store/Core/actions/core.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-assign-sim-enter-name',
  templateUrl: './assign-sim-enter-name.component.html',
  styleUrls: ['./assign-sim-enter-name.component.scss']
})
export class AssignSimEnterNameComponent implements OnInit {
  assignSimForm!: FormGroup;
  attemptedSubmit: boolean = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.setForm(); 
  }

  setForm() {
    this.assignSimForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
    });
  }

  signIn() {
    this.attemptedSubmit = false;
    const { name } = this.assignSimForm.getRawValue() ?? {};
    const cannotProceed = Boolean(!name || this.assignSimForm.invalid || this.assignSimForm.errors);
    
    if (cannotProceed) return this.attemptedSubmit = true;
    const payload = this.buildAssignSimPayload(name);
    
    return this.store.dispatch(new InitiateAssignSimOTP(payload));
  }

  private buildAssignSimPayload(nickname: string) {
    const decodedIdentifier = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as any;
    const decodedPrimaryUserCreds = Utils.Helpers.decodeJWT(decodedIdentifier.tokenCredential?.tokenCredential);
    const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
    
    if(isSignedIn) {
      const user = this.store.selectSnapshot(AuthState.getAxiomUser) as DigitalIdentityClientObject;
  
      const payload = {
        loginId: user.loginId,
        resourceIdentified: [
          {
            name: decodedIdentifier.msisdn
          }
        ],
        tokenCredentialDto: {
          login: decodedIdentifier.tokenCredential.login,
          mobileNumber: decodedPrimaryUserCreds.sub,
          token: decodedIdentifier.tokenCredential?.tokenCredential,
          nickname: nickname,
          partyId: user.individualIdentified?.id,
          "@type": decodedIdentifier['@type'],
          "@baseType":  decodedIdentifier['@baseType'],
          "@schemaLocation": decodedIdentifier['@schemaLocation'],
          "partyRoles":decodedIdentifier['partyRoles']
        }
      }
  
      return payload;
    } else {
      const payload = {
        loginId: decodedIdentifier.tokenCredential.login,
        resourceIdentified: [
          {
            name: decodedIdentifier.msisdn
          }
        ],
        tokenCredentialDto: {
          login: decodedIdentifier.tokenCredential.login,
          mobileNumber: decodedIdentifier.tokenCredential.mobileNumber,
          token: decodedIdentifier.tokenCredential?.tokenCredential,
          nickname: decodedIdentifier.tokenCredential?.nickname,
          "@type": decodedIdentifier['@type'],
          "@baseType":  decodedIdentifier['@baseType'],
          "@schemaLocation": decodedIdentifier['@schemaLocation'],
          "partyRoles":decodedIdentifier['partyRoles']
        }
      }
      return payload;
    }
  }

}
