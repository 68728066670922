@if(svcConfig$ | async; as svcConfig) {
    <section>
        <div class="toggle" [ngClass]="{
            'one-col-grid': checkTrueValues(svcConfig) < 2,
            'two-col-grid': checkTrueValues(svcConfig) === 2,
            'three-col-grid': checkTrueValues(svcConfig) > 2
        }">
            <div *ngIf="svcConfig.hasConsumerSVCs" class="option" [ngClass]="{'active': (mode$ | async) === 'consumer'}" (click)="setClientMode('consumer')">home</div>
            <div *ngIf="svcConfig.hasWorkSVCs" class="option" [ngClass]="{'active': (mode$ | async) === 'sme'}" (click)="setClientMode('sme')">work</div>
            <div *ngIf="svcConfig.hasMobileSVCs" class="option" [ngClass]="{'active': (mode$ | async) === 'mobile'}" (click)="setClientMode('mobile')">mobile</div>
        </div>
    </section>
}