/**
 * @description Helper function to check if email address is valid.
 * @param email address
 * @returns true or false
 */
export function isValidEmail(email:string|null):boolean {
    return email ? (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(email) : false;
}

export function jwtExpired(token: string | null) {
    if (!token) return true; 

    try {
        const decodedToken = JSON.parse(atob(token?.split(".")?.[1]));
        const expired = decodedToken?.exp * 1000 < Date.now(); 
        return expired
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }

}