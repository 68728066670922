import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ClientMode } from './actions/client-mode.actions';
import { ClientModeActions } from './actions';
import { FetchServices, GetAssignedSimList } from '../Core/actions/core.actions';
import { AuthState } from '../Auth/auth.state';

function loadDefault():ClientModeStateModel {
    return {
        mode: "consumer",
    }
}

interface ClientModeStateModel {
    mode: ClientMode
}

@State<ClientModeStateModel>({
    name: 'client_mode_state',
    defaults: loadDefault()
})

@Injectable()
export class ClientModeState {

    @Selector()
    static GetClientMode(state: ClientModeStateModel) { return state?.mode }

    constructor(private store: Store) { }

    @Action(ClientModeActions.SetClientMode)
    SetClientMode(ctx: StateContext<ClientModeStateModel>, action: ClientModeActions.SetClientMode) {
        const state = ctx.getState();
        ctx.patchState({
            mode: action?.mode
        })

        const user = this.store.selectSnapshot(AuthState.getAxiomUser);

        const relPartyId = user?.relatedParty?.find((rp) => rp?.role?.toLocaleLowerCase() === action?.mode)?.id;
        ctx.dispatch([new GetAssignedSimList(relPartyId as string)]);

        // ctx.dispatch(new FetchServices());
    }

    @Action(ClientModeActions.Reset)
    Reset(ctx: StateContext<ClientModeStateModel>) {
        ctx.setState(loadDefault())
    }

}