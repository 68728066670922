@if ((viewModel$ | async); as viewModel) {
  <section class="home-body">
    <ng-container>
      @if (viewModel?.showHomeWorkToggle) {
        <app-home-work-toggle></app-home-work-toggle>
      }
      <div class="home-header">
        <app-shop-header [homeViewModel]="viewModel"></app-shop-header>
      </div>
      <section>
        @if (!viewModel.homeLoader) {
          <div class="content">
            <!-- firebase -->
            <app-promo-tile [promo]="viewModel.promo"></app-promo-tile>
            <!-- firebase -->
            <div class="purchase-wrapper">
              <app-purchase-tabs [products]="viewModel.products"></app-purchase-tabs>
            </div>
          </div>
        } @else {
          <div class="loader-wrapper">
            <div class="loading-container">
              <div class="loading-box">
                <div class="loading-sphere"></div>
                <div class="loading-text">Getting your details</div>
              </div>
            </div>
          </div>
        }
      </section>
    </ng-container>
  </section>
}