import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const cachedStores = ['catelogue', 'auth_state', 'Topup_state', 'dashboard']

function clrLocalStorage() {
  if(!environment.production) return;
  Object.keys(localStorage).forEach(
    (key) => {
      if (cachedStores.includes(key)) return;
      return localStorage.removeItem(key);
    });
}

clrLocalStorage();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.warn(err));
