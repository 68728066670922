import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'app-assign-sim-verification-success',
  templateUrl: './assign-sim-verification-success.component.html',
  styleUrls: ['./assign-sim-verification-success.component.scss']
})
export class AssignSimVerificationSuccessComponent {

  constructor(private readonly store: Store) {

  }
  public dismiss() {
      this.store.dispatch(new CloseBottomSheet());
  }
}
