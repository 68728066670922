@if ((data$ | async); as data) {
  @if ((ap$ | async); as ap) {
    <div [class]="'installer-container is'+ data.mobileType + ' is-'+ap">
      @if (data.mobileType === 'browser') {
        <div class="header">
          <i (click)="close()">
            <img src="assets/icons/pwa/close.png">
          </i>
        </div>
      }
      <div class="main">
        <h3>Install app</h3>
        <ng-container>
          @if (data.mobileType === 'ios') {
            <div class="ios-prompt">
              <p>To install this web app on your device, open rainGo on your safari browser, then tap the Menu button and tap 'Add to Home screen' menu option.
              </p>
            </div>
          }
          @if (data.mobileType === 'android') {
            <div  class="ios-prompt">
              <p>Install rainGo onto your home screen, tap install.</p>
            </div>
          }
          @if (data.mobileType === 'browser') {
            <div>
              <div class="app-details">
                <img src="favicon.ico" alt="">
                <div class="app-name">
                  <h4>RainGO</h4>
                  <p class="web-url">https://www.raingo.co.za</p>
                </div>
              </div>
            </div>
          }
        </ng-container>
        <div class="actions-footer">
          <button (click)="rejectInstall()">
            Cancel
          </button>
          @if (data.mobileType !== 'ios') {
            <button class="install-btn" (click)="installPwa(data.mobileType)">
              Install
            </button>
          }
        </div>
      </div>
    </div>
  }
}