import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { RegistrationPayload, TLSDecryptedResponse } from 'src/app/interfaces/interfaces';
import { AssignUser, RegisterUser } from 'src/app/store/Core/actions/core.actions';
import { CoreState } from 'src/app/store/Core/core.state';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from '@angular/material/checkbox';
import { Utils } from 'src/app/utils';
import PlaceResult = google.maps.places.PlaceResult;
import { NgxGpAutocompleteDirective, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions}]
})
export class RegisterComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  @Select(CoreState.RegisterLoading) loading$?: Observable<boolean>
  @Select(CoreState.GetCreateAccountError) regError$: Observable<any>


  registerForm!: FormGroup;
  attemptedRegister: boolean = false;
  public genericMessage: {
    show: boolean,
    message: string | null
  } = {show: false, message: null};
  private ricaAddress: any;

  public options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['za'] },
    types: ['geocode']
  };

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.setForm() ;

    this.regError$
    .subscribe({
      next: (res) => {
        if(res && res.message) {
          const field = res.message?.error?.split(' ')[0] as string;
          const control = this.registerForm.get(field);
          
          if(control && res.message?.error.includes('exists')) {
            control.setErrors({exists: true});
          } else {
            this.genericMessage = {
              show: true,
              message: res.message?.error ?  res.message.error : res.message
            }
          }
        }
      }
    })
  }

  setForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$')])],
      id_number: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, Utils.FormValidators.noSpecialCharsValidator()])],
      rainNumber: ['', Validators.compose([Validators.required])],
      terms: [false, Validators.compose([Validators.requiredTrue])],
      whatsapp: [false],
      address: ['', Validators.required],
    })

    this.assignRainNumberToForm()
  }

  assignRainNumberToForm() {
    const decodeId = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse;
    
    if (decodeId && decodeId.udg?.msisdn) {
      return this.registerForm.get('rainNumber')?.patchValue(decodeId.udg?.msisdn)
    }
  }

  register(){
    this.attemptedRegister = false
    const { firstName, lastName, email, password, rainNumber, id_number, address } = this.registerForm.getRawValue() ?? {}

    const cannotProceed = !firstName || !lastName || !email || !password || !id_number || !rainNumber || !address || this.registerForm.invalid || this.registerForm.errors;

    if (cannotProceed) {
      this.attemptedRegister = true;
      return
    }

    const registrationPayload: RegistrationPayload = {
      user : {
        first_name: this.registerForm.get("firstName")?.value,
        name: this.registerForm.get("firstName")?.value,
        last_name: this.registerForm.get("lastName")?.value,
        id_number: this.registerForm.get("id_number")?.value,
        email: this.registerForm.get("email")?.value,
        password: this.registerForm.get("password")?.value,
        phone: this.registerForm.get("rainNumber")?.value,
        ricaAddress: this.ricaAddress
      }
    };
    
    this.store.dispatch(new RegisterUser(registrationPayload));
  }

  onChange(mouseEvent: any) {
    mouseEvent.source._elementRef.nativeElement.classList.remove('mat-checkbox-anim-unchecked-checked');
    mouseEvent.source._elementRef.nativeElement.classList.remove('mdc-checkbox--anim-unchecked-checked');
    mouseEvent.source._elementRef.nativeElement.classList.remove('mdc-checkbox--anim-checked-unchecked');
    mouseEvent.source._elementRef.nativeElement.classList.remove("mdc-checkbox--anim-checked-indeterminate");
    mouseEvent.source._elementRef.nativeElement.classList.remove("mdc-checkbox--anim-indeterminate-checked");
    mouseEvent.source._elementRef.nativeElement.classList.remove("mdc-checkbox--anim-indeterminate-unchecked");
    mouseEvent.source._elementRef.nativeElement.classList.remove("mdc-checkbox--anim-unchecked-indeterminate");
  }

  onAutocompleteSelected(result: any) {
    this.ricaAddress = this.buildRicaAddress(result);
  }

  private buildRicaAddress(place: any) {
    const addressComponents = place.address_components as google.maps.GeocoderAddressComponent[];
    const streetNumber = addressComponents.filter((ac) => ac.types[0] === 'street_number');
    const streetName = addressComponents.filter((ac) => ac.types[0] === 'route');
    const suburb = addressComponents.filter((ac) => ac.types.includes('sublocality'));
    const city = addressComponents.filter((ac) => ac.types[0] === 'locality');
    const province = addressComponents.filter((ac) => ac.types[0] === 'administrative_area_level_1');
    const country = addressComponents.filter((ac) => ac.types[0] === 'country');
    const postalCode = addressComponents.filter((ac) => ac.types[0] === 'postal_code');
    let buildingName = place.types?.includes("point_of_interest") ? place.name : '';
    
    return {
      street_number: streetNumber[0].long_name,
      street_name: streetName[0].long_name,
      suburb: suburb[0].long_name,
      city: city[0].long_name,
      province: province[0].long_name,
      postal_code: postalCode[0].long_name,
      country: country[0].long_name,
      building_name: buildingName,
    }    
  }
}