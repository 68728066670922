
@if ((viewModel$ | async); as viewModel) {
  <app-bottom-sheet-content-template [loading]="!viewModel.loaded">
    @if (viewModel.paymentResult === 'Transaction succeeded') {
      <h1 Header>
        payment <b>successful</b>
      </h1>
    } @else {
      <h1 Header>
        <b>oops</b>
      </h1>
    }
    @if (viewModel.paymentResult === 'Transaction succeeded') {
      <div Content>
        <div class="text-container" ngif>
          Congratulations! You have successfully purchased <b class="highlight">{{topup.quantity}} {{topup.unit}}</b> for <b class="highlight">{{msisdn}}</b>.
        </div>
        <div class="button-container">
          <button (click)="closeSheet()">close</button>
        </div>
      </div>
    } @else {
      <div Content>
        <div class="text-container">
          Unfortunately, your payment was unsuccessful. Please try again.
        </div>
        <div class="button-container">
          <button (click)="closeSheet()">close</button>
        </div>
      </div>
    }
  </app-bottom-sheet-content-template>
}