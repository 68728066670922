<app-bottom-sheet-content-template>
    <div Content>
        <p class="primary-message">This SIM is already assigned. Before it can be assigned to you, you’ll need to ask the rain<strong>one</strong> account holder to unassign it on their myrain page at rain.co.za</p>

        <div class="actions-footer">
            <button class="submit" (click)="close()">
                got it
            </button>
        </div>
    </div>
</app-bottom-sheet-content-template>