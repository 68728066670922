import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-assign-sim-request-sheet',
  templateUrl: './assign-sim-request-sheet.component.html',
  styleUrls: ['./assign-sim-request-sheet.component.scss']
})
export class AssignSimRequestSheetComponent {

  constructor(private store: Store) {}

  accept() {

  }

  deny() {

  }
}
