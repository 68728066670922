import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { OTPCreateResponse, OpenOtp } from '../interfaces';
import { AuthState } from '../store/Auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AssignSimService {

  constructor(private _http: HttpClient, private store: Store) {}

  AcceptOTP(payload: any): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/assign`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.post<any>(url, payload, httpOptions);
  }

  public InitiateOTP(payload: any) : Observable<OTPCreateResponse> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/assign`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.post<any>(url, payload, httpOptions);
  }

  public GetOpenOTPStats(): Observable<{listOpenOtps: Array<OpenOtp>}> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/getOpenOtps`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.get<{listOpenOtps: Array<OpenOtp>}>(url, httpOptions);
  }

  public RejectOTP(externalRef: string) {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/invalidate?externalRef=${externalRef}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.post<any>(url, null, httpOptions);
  }

  public FindOTPByMsisdn(msisdn: string): Observable<OpenOtp> {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/otp/${msisdn}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.get<OpenOtp>(url, httpOptions);
  }

  public AssignSim(payload: {loginId: string, msisdn: string}) {
    const token = this.store.selectSnapshot(AuthState.getToken);
    const url = `${environment.AXIOM_RAINGO}/sim/assign`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this._http.post<any>(url, payload, httpOptions);
  }
}
