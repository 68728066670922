@if(config$ | async; as config) {
    <app-bottom-sheet-content-template>
        
        <h1 Header>are you sure you want to<br>change to <strong>up to {{getSpeed(config.selectedSpeed?.name)}}?</strong></h1>
        
        <div Content>
            <div class="disclaimer">
                <p>Your change in wifi speed will take effect on your next billing date.</p>
            </div>
            <div class="button-container">
                <button class="outlined" (click)="close()">no</button>
                <button class="solid" (click)="continueSpeedDown()">yes</button>
            </div>
        </div>
    
    </app-bottom-sheet-content-template>
}