import { NgModule } from '@angular/core';
import { ErrorComponent } from './error.component';
import { CommonModule } from '@angular/common';
import { ErrorRoutingModule } from './error-routing.module';

const declarations = [
    ErrorComponent
]

@NgModule({
    declarations: [...declarations],
    imports: [
        CommonModule,
        ErrorRoutingModule
    ],
    exports: [...declarations]
})
export class ErrorModule { }