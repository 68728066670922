<app-bottom-sheet-content-template>
  <h1 Header><b>sign in</b> using your rain details</h1>

  <div Content>
    <form class="form-container" [formGroup]="signInForm">
      <div class="input-container">
        <input id="contactName" type="text" [formControlName]="'email'" placeholder="email" autocomplete="email">
        @if (signInForm.get('email')?.errors && signInForm.get('email')?.dirty && attemptedSignIn) {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid email
            </span>
          </p>
        }
      </div>
      <div class="input-container">
        <input id="companyName" [type]="getType()" [formControlName]="'password'" placeholder="password" autocomplete="current-password">
        @if (signInForm.get('password')?.errors && signInForm.get('password')?.dirty && attemptedSignIn) {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid password
            </span>
          </p>
        }
        <div class="show-hide-password-icon" (click)="toggleHideSHowPassword()" [class.show]="showPassword">
          @if(showPassword) {
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 48 32" style="enable-background:new 0 0 48 32;" xml:space="preserve">
            <style type="text/css">
              .st0{fill:none;stroke:#787878;stroke-width:1.5;}
              .st1{fill:#787878;}
            </style>
            <g id="Page-1">
              <g id="Group" transform="translate(0.000000, 1.000000)">
                <path id="Oval" class="st0" d="M23.9,27.3c6.7,0,12.8-4.1,18.5-12.3C37.2,6.8,31.1,2.7,23.9,2.7S10.5,6.8,5,15
                  C11,23.2,17.3,27.3,23.9,27.3z"/>
                <circle id="Oval-Copy" class="st0" cx="23.9" cy="15" r="6.6"/>
              </g>
            </g>
            <rect x="23.1" y="-3.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.1963 21.3867)" class="st1" width="1.3" height="38.9"/>
            </svg>
            
          } @else  {
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 48 32" style="enable-background:new 0 0 48 32;" xml:space="preserve">
            <style type="text/css">
              .st0{fill:none;stroke:#787878;stroke-width:1.5;}
            </style>
            <g id="Page-1">
              <g id="Group" transform="translate(0.000000, 1.000000)">
                <path id="Oval" class="st0" d="M23.9,27.3c6.7,0,12.8-4.1,18.5-12.3C37.2,6.8,31.1,2.7,23.9,2.7S10.5,6.8,5,15
                  C11,23.2,17.3,27.3,23.9,27.3z"/>
                <circle id="Oval-Copy" class="st0" cx="23.9" cy="15" r="6.6"/>
              </g>
            </g>
            </svg>
            
          }
        </div>
      </div>
      @if ((authError$ | async); as authError) {
        <div class="auth-error-container">
          @if (authError.hasError && authError.message.length > 1) {
            <p>{{authError.message}}</p>
          }
        </div>
      }
      <div class="button-container">
        <button class="sign-in" (click)="signIn()" [disabled]="signInForm.invalid || isSigningIn" [ngClass]="{'disabled': signInForm.invalid || isSigningIn}">
          @if (!isSigningIn) {
            <p>
              sign in
            </p>
          }
          @if (isSigningIn) {
            <p class="pendingAnimation"></p>
          }
        </button>
        <p (click)="forgotPassword()" class="forgot-password">forgot password</p>
      </div>
    </form>
  </div>

</app-bottom-sheet-content-template>