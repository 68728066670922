
<app-bottom-sheet-content-template>

  <h1 Header>
    please provide your <b>email address</b> <br> to reset your password
  </h1>

  <div Content>
    <form class="form-container" [formGroup]="passwordReset">
      <div class="input-container">
        <input id="contactName" type="text" [formControlName]="'email'" placeholder="email">
        @if (passwordReset.get('email')?.errors && passwordReset.get('email')?.dirty || (error$ | async); as error) {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid email
            </span>
          </p>
        }
      </div>
      <div class="button-container">
        <button class="password-reset-button" (click)="sendEmail()" [disabled]="passwordReset.invalid" [ngClass]="{'disabled': passwordReset.invalid}">
          <p>
            submit
          </p>
        </button>
        <p (click)="goToLogin()" class="back-to-login">back to login</p>
      </div>
    </form>
  </div>

</app-bottom-sheet-content-template>