import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function noSpecialCharsValidator(exception: string[] = []): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
      if (format.test(control.value)) {
        let matchesExempted = false;
        const matches: string[] = control.value.match(format);
        if (matches) matchesExempted = matches.every(value => exception.includes(value));
        return matchesExempted ? null : { specialChars: true };
      }
      return null;
    };
  }