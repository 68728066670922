<app-bottom-sheet-content-template>

    <h1 Header>service <strong>unavailable</strong></h1>
    
    <div Content>
        <div class="arrears-copy-container">
            <p>Your account is in arrears. In order to change your level, you’ll first need to go to manage and settle your outstanding bill.</p>
        </div>

        <div class="footer">
            <button (click)="navigateToMyRain()">rain.co.za</button>
        </div>
    </div>
</app-bottom-sheet-content-template>