@if((speedUpConfig$ | async); as config) {
    <app-bottom-sheet-content-template>
    
        <h1 Header>are you sure you want to<br><strong>speed up</strong> to {{formatSpeedUpName(config.selectedSpeed?.name)}}?</h1>
        
        <div Content>
            <div class="disclaimer">
                <p>
                    You&#39;ll be charged a pro-rata of <strong>{{getProRataPrice(config.selectedSpeed?.price, config) | currency : 'R'}}</strong> for the rest of the month. After that, <strong>{{calcDiff(config.currentSpeed, config.selectedSpeed) | currency: 'R'}}</strong> will be added to your current monthly bill. Making your total speed up bill <strong>{{config.selectedSpeed?.price | currency: 'R'}}</strong>.</p>
            </div>
            <div class="button-container">
                <button class="outlined" (click)="close()">cancel</button>
                <button class="solid" (click)="continuePurchase(config.selectedSpeed?.name)">confirm</button>
            </div>
        </div>
    
    </app-bottom-sheet-content-template>
}


