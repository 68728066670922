<app-bottom-sheet-content-template>

    <h1 Header>oh no, not a rain number</h1>

    <div Content>
        <div class="info-lockup">
            <p>The number you're trying to purchase for is not part of our network.</p>
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>

</app-bottom-sheet-content-template>