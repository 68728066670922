import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FireStoreActions } from './actions';
import { FetchRaingoConfigResponse, FetchRaingoPromoResponse, RaingoPromoObject } from 'src/app/interfaces/interfaces';
import { ILevel } from 'src/app/pages/Dashboard/interfaces/level.interface';

export interface Config {
    maintenanceModeOn: false
}

function loadDefault(): FirestoreStateModel {
    return {
        promoOption: null,
        wifiPromoOption: null,
        config: {
            maintenanceModeOn: false
        },
        levelsConfig: null
    }
}

interface FirestoreStateModel {
    promoOption: RaingoPromoObject | null,
    wifiPromoOption: RaingoPromoObject | null,
    config: Config,
    levelsConfig: {[type: string]: Array<ILevel>} | null
}

@State<FirestoreStateModel>({
    name: 'firestore_state',
    defaults: loadDefault()
})

@Injectable()
export class FirestoreState {

    @Selector()
    static getPromoDetails(state: FirestoreStateModel) { return state.promoOption }

    @Selector()
    static GetWebsiteConfig(state: FirestoreStateModel) { return state.config }

    @Selector()
    static GetLevelsConfig(state: FirestoreStateModel) { return state.levelsConfig }

    @Selector()
    static getWifiPromoDetails(state: FirestoreStateModel) { return state.wifiPromoOption }

    constructor(private firestoreService: FirestoreService) { }    

    @Action(FireStoreActions.FetchPromoDetails)
    FetchPromoDetails(ctx: StateContext<FirestoreStateModel>) {
        this.firestoreService.getTopupPromo().subscribe((res: FetchRaingoPromoResponse) => {
            const { promo } = res;
            
            return ctx.patchState({
                promoOption: promo,
                wifiPromoOption: res['wifi-promo']
            })
        })
    }

    @Action(FireStoreActions.FetchWebsiteConfig)
    FetchWebsiteConfig(ctx: StateContext<FirestoreStateModel>) {
        this.firestoreService.GetConfig().subscribe((res: FetchRaingoConfigResponse) => {
            return ctx.patchState({
                config: res as Config
            })
        })
    }

    @Action(FireStoreActions.FetchLevelsConfig)
    FetchLevelsConfig(ctx: StateContext<FirestoreStateModel>) {
        this.firestoreService.GetLevelsConfig().subscribe((res: {[type: string]: Array<ILevel>} ) => {
            if(res) {
                ctx.patchState({
                    levelsConfig: res
                })
            }
        })
    }

}