import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

export class Encryptor {
    private password: string = environment.encyrption;

    public encrypt(data: string) {
        return CryptoJS.AES.encrypt(data.trim(), this.password.trim()).toString();
    }

    public decrypt(data: string) {
        return CryptoJS.AES.decrypt(data.trim(), this.password.trim()).toString(CryptoJS.enc.Utf8);
    }
}