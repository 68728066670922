import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import * as AssignSimActions from './actions/assign-sim.actions';
import { AssignSimService } from 'src/app/services/assign-sim.service';
import { BottomSheetActions } from '../BottomSheet/actions';
import { CoreState } from '../Core/core.state';
import { FetchServices } from '../Core/actions/core.actions';
import { AuthState } from '../Auth/auth.state';
import { BottomSheetState } from '../BottomSheet/bottom-sheet.state';

/**
 *  Stage types;
 * 1: StartAssignSimProcess
 * 2: Register User
 * or
 * 3: SignIn User
 * 4: WhoAmI Unidentified
 * 5: PreSsignIn
 * 6: AlreadyAssigned
 */
export type Stage = '1' | '2' | '3' | '4' | '5' | '6';

interface AssignSimStateModel {
  stage: Stage | null;
  canAssign: boolean;
  loading: boolean;
}

@State<AssignSimStateModel>({
  name: 'assignSimState',
  defaults: {
    stage: null,
    canAssign: false,
    loading: false
  },
})
@Injectable()
export class AssignSimState {
  @Selector()
  static GetStage(state: AssignSimStateModel) {
    return state.stage;
  }

  @Selector()
  static isLoading(state: AssignSimStateModel) {
    return state.loading;
  }

  @Selector()
  static CanAssign(state: AssignSimStateModel) {
    return state.canAssign;
  }

  @Selector()
  static AssimStateData(state: AssignSimStateModel) {
    return state;
  }

  constructor(private _assingSimSvc: AssignSimService, private store: Store) {}

  @Action(AssignSimActions.SetCanAssign)
  SetCanAssign(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.SetCanAssign
  ) {
    ctx.patchState({
      canAssign: true,
    });
  }
  @Action(AssignSimActions.StopAssignSimProcess)
  StopAssignSimProcess(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.StopAssignSimProcess
  ) {
    ctx.patchState({
      stage: null
    });
  }

  @Action(AssignSimActions.StartAssignSimProcess)
  StartAssignSimProcess(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.StartAssignSimProcess
  ) {
    const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);    

    if(!isAuthed) {
      ctx.patchState({
        stage: '1',
      });
  
      ctx.dispatch(new BottomSheetActions.ChangeOpenSheet("assign_sim_register_or_sign_in"));
      return;
    } else {
      ctx.patchState({
        stage: '5',
      });

      ctx.dispatch(new BottomSheetActions.ChangeOpenSheet('ask_assign_sim'));
      return;
    }


  }

  @Action(AssignSimActions.NextStep)
  NextStep(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.NextStep
  ) {
    const state = ctx.getState();
    let nState;
    nState = action.payload as string;
    ctx.patchState({
      stage: nState as Stage,
    });

    switch (nState) {
      case '2':
        ctx.dispatch(new BottomSheetActions.ChangeOpenSheet("register"));
        return;
      case '3':
        ctx.dispatch(new BottomSheetActions.ChangeOpenSheet("sign_in"));
        return;
      case '4':
        ctx.dispatch(new BottomSheetActions.ChangeOpenSheet("assign_sim_unidentified")); 
        return;
      case '5':
        ctx.dispatch([new BottomSheetActions.ChangeOpenSheet("ask_assign_sim_success"), new FetchServices('NextStep')]); 
        ctx.patchState({
          stage: null, 
          loading: false
        });
        return;
      case '6':
        ctx.dispatch(new BottomSheetActions.ChangeOpenSheet("sim_already_assigned"));
        ctx.patchState({
          stage: null, 
          loading: false
        });
        return;
    }
  }

  @Action(AssignSimActions.AssignSim)
  AssignSim(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.AssignSim
  ) {
    const state = ctx.getState();
    const cStage = state.stage as string;
    
    this._assingSimSvc.AssignSim(action.payload)
      .subscribe({
        next: (res) => ctx.dispatch(new AssignSimActions.AssignSimSuccess(res)),
        error: (err) => ctx.dispatch(new AssignSimActions.AssignSimFail(err))
      });
  }

  @Action(AssignSimActions.AssignSimSuccess)
  AssignSimSuccess(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.AssignSimSuccess
  ) {
    ctx.dispatch([new AssignSimActions.NextStep('5'), new FetchServices('AssignSimSuccess')]);
  }

  @Action(AssignSimActions.RetryLoading)
  RetryLoading(
    ctx: StateContext<AssignSimStateModel>,
    action: AssignSimActions.RetryLoading
  ) {
    ctx.patchState({
      loading: action?.loading
    });
  }
}
