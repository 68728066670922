<app-bottom-sheet-content-template>
    
    <h1 Header>are you sure?</h1>
    
    <div Content>
        <div class="disclaimer">
            <p>Are you sure you want to reverse your level change?</p>
        </div>
        <div class="button-container">
            <button class="outlined" (click)="close()">no</button>
            <button class="solid" (click)="RevokeScheduledAction()">yes</button>
        </div>
    </div>

</app-bottom-sheet-content-template>


