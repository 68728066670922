import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { SetSelectedTopup, StartFreeGigPurchase } from './actions/topup.actions';
import { TopupActions } from './actions';
import { TopupService } from 'src/app/services/topup.service';
import { Navigate } from '@ngxs/router-plugin';
import { CoreActions } from '../Core/actions';
import { ServiceObject, TopUpOption, TopupCheckoutResponse } from 'src/app/interfaces/interfaces';
import { BottomSheetActions } from '../BottomSheet/actions';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { CoreState } from '../Core/core.state';
import { SELECTED_MSISDN, SELECTED_TOPUP, TOPUP_ERROR, TOPUP_FREE_SUCCESS, TOPUP_RESULT } from 'src/app/constants/constants';
import { PaymentCacheService } from 'src/app/services/payment-cache.service';
import { AuthState } from '../Auth/auth.state';
import { ClearServicePolicies, FetchServices, RemoveFreeGigSVCID } from '../Core/actions/core.actions';
import { ShowBottomSheet } from '../BottomSheet/actions/bottom-sheet-actions.actions';
import { HttpErrorResponse } from '@angular/common/http';

interface TopupStateModel {
    loading: boolean,
    selectedTopup: TopUpOption | null,
    topUpResult: TopupCheckoutResponse | null,
    topUpMsisdn: string | null,
    topUpFreeResult: any, // TODO: get type
    buyMoreMsisdn: string | null,
    error: boolean,
    errorMessage: {
        boldText: string,
        lightText: string 
    } | null
}
@State<TopupStateModel>({
    name: 'Topup_state',
    defaults: {
        loading: false,
        selectedTopup: null,
        topUpResult: null,
        topUpMsisdn: null,
        buyMoreMsisdn: null,
        topUpFreeResult: null,
        error:false,
        errorMessage: null
    }
})
@Injectable()
export class TopupState {

    constructor(private topupService: TopupService, private datalayerService: DataLayerService, private store: Store, private paymentCacheService: PaymentCacheService) {}

    @Selector()
    static getSelectedTopup(state: TopupStateModel) { return state.selectedTopup }
    @Selector()
    static getSetMsisdn(state: TopupStateModel) { return state.topUpMsisdn }
    @Selector()
    static loading(state: TopupStateModel) { return state.loading }
    @Selector()
    static getTopUpResult(state: TopupStateModel) { return state.topUpResult }
    @Selector()
    static getTopUpFreeResult(state: TopupStateModel) { return state.topUpFreeResult }
    @Selector()
    static getError(state: TopupStateModel) { return state.error }

    @Selector()
    static GetBuyMoreMsisdn(state: TopupStateModel) { return state.buyMoreMsisdn }
    @Selector()
    static getErrorMessage(state: TopupStateModel) { return state.errorMessage }


    @Action(SetSelectedTopup)
    setSelectedTopUp(ctx: StateContext<TopupStateModel>, action: TopupActions.SetSelectedTopup) {
        const { option } = action
        
        const currentOption = ctx?.getState()?.selectedTopup ?? null

        if (currentOption && option === currentOption) {
            ctx.patchState({
                selectedTopup: null
            })
            return
        }

        this.datalayerService.itemView(option)

        ctx.patchState({
            selectedTopup: option
        })

        this.paymentCacheService.setPaymentCacheItem(SELECTED_TOPUP, option);
    
    }

    @Action(TopupActions.TopUp)
    topUp(ctx: StateContext<TopupStateModel>, action: TopupActions.TopUp) {
        const payload = action.payload;

        ctx.patchState({
            topUpMsisdn: payload.msisdn,
            error: false,
            loading: true
        })
        
        return this.topupService.topup(payload as any)
            .pipe(tap({
                next: (res: TopupCheckoutResponse) => {
                    this.paymentCacheService.setPaymentCacheItem(SELECTED_MSISDN, payload.msisdn);
                    this.datalayerService.addToCart(payload.option)
                    ctx.dispatch(new TopupActions.TopUpSuccess(res))
                },
                error: (e: HttpErrorResponse) => {
                    
                    const numberInArrears = e?.error?.message?.toLowerCase()?.includes("service that is not in an active state")
                    const serviceAccountNotFound = e?.error?.message?.toLowerCase()?.includes("service account not found")
                    let errorMessage = {
                        boldText: "Please enter",
                        lightText: "a rain number"
                    };

                    if (numberInArrears) {
                        errorMessage = {
                            boldText: "Recipient account",
                            lightText: "in arrears"
                        }
                    };

                    if (serviceAccountNotFound) {
                        errorMessage = {
                            boldText: "Please enter",
                            lightText: "a rain number"
                        }
                    }
                    const token = this.store.selectSnapshot(AuthState.getToken)?.split('.')?.[1];
                    const tokenExpired = token ? new Date() > new Date(JSON.parse(atob(token))?.exp * 1000) : true;
                    
                    if (tokenExpired) {
                        errorMessage = {
                            boldText: "Token expired.",
                            lightText: "Please refresh your page or sign in.",
                        }
                    }

                    if(e.status === 400) {
                        if((e.error.message === 'Service account not found')) {

                           return  this.store.dispatch(new ShowBottomSheet('top-up-fail'));
                        }

                        if((e.error.message.includes('Cannot top up'))) {
                            
                            return this.store.dispatch(new ShowBottomSheet('non-eligible-top-up-error-sheet'));
                        }
                    }

                    ctx.patchState({
                        error: true,
                        errorMessage: errorMessage,
                        loading: false,
                    })
                    

                }

            }))
    }
    @Action(TopupActions.SpeedUpMigration)
    SpeedUpMigration(ctx: StateContext<TopupStateModel>, action: TopupActions.SpeedUpMigration) {
        const payload = action.payload;

        ctx.patchState({
            topUpMsisdn: payload.msisdn,
            error: false,
            loading: true
        })
        
        return this.topupService.speedUp(payload as any)
            .pipe(tap({
                next: (res: TopupCheckoutResponse) => {
                    this.paymentCacheService.setPaymentCacheItem(SELECTED_MSISDN, payload.msisdn);
                    this.datalayerService.addToCart(payload.option)
                    ctx.dispatch(new TopupActions.TopUpSuccess(res))
                },
                error: (e: HttpErrorResponse) => {
                    
                    const numberInArrears = e?.error?.message?.toLowerCase()?.includes("service that is not in an active state")
                    const serviceAccountNotFound = e?.error?.message?.toLowerCase()?.includes("service account not found")
                    let errorMessage = {
                        boldText: "Please enter",
                        lightText: "a rain number"
                    };

                    if (numberInArrears) {
                        errorMessage = {
                            boldText: "Recipient account",
                            lightText: "in arrears"
                        }
                    };

                    if (serviceAccountNotFound) {
                        errorMessage = {
                            boldText: "Please enter",
                            lightText: "a rain number"
                        }
                    }
                    const token = this.store.selectSnapshot(AuthState.getToken)?.split('.')?.[1];
                    const tokenExpired = token ? new Date() > new Date(JSON.parse(atob(token))?.exp * 1000) : true;
                    
                    if (tokenExpired) {
                        errorMessage = {
                            boldText: "Token expired.",
                            lightText: "Please refresh your page or sign in.",
                        }
                    }

                    if(e.status === 400 && (e.error.message === 'Service account not found' || e.error)) {

                        this.store.dispatch(new ShowBottomSheet('top-up-fail'));
                    }

                    ctx.patchState({
                        error: true,
                        errorMessage: errorMessage,
                        loading: false,
                    })
                    

                }

        }))
    }

    @Action(TopupActions.TopUpFree)
    TopUpFree(ctx: StateContext<TopupStateModel>, action: TopupActions.TopUpFree) {
        const { option, msisdn, walletName, name } = action ?? {};
        const service = action?.service as ServiceObject;

        ctx.patchState({
            topUpMsisdn: msisdn,
            error: false,
            loading: true
        })
        this.paymentCacheService.setPaymentCacheItem(SELECTED_MSISDN, msisdn);
        
        return this.topupService.topupFree(option, msisdn, walletName, name, service)
            .pipe(tap({
                next: (res: any) => { 
                    ctx.patchState({
                        topUpFreeResult: true
                    })
                    this.paymentCacheService.setPaymentCacheItem(TOPUP_FREE_SUCCESS, true);
                    ctx.dispatch([new BottomSheetActions.ChangeOpenSheet('topup-free-result'), new FetchServices(), new RemoveFreeGigSVCID(service.id)])
                },
                error: (e) => {
                    this.paymentCacheService.setPaymentCacheItem(TOPUP_FREE_SUCCESS, false);
                    ctx.patchState({
                        topUpFreeResult: false,
                        error: true
                    })
                    if (e.status !== 409) {
                        this.paymentCacheService.setPaymentCacheItem(TOPUP_ERROR, true);
                    } else {
                        this.paymentCacheService.setPaymentCacheItem(TOPUP_ERROR, false);
                    }
                    ctx.dispatch(new BottomSheetActions.ChangeOpenSheet('topup-free-result'))
                }

            }))
    }

    @Action(TopupActions.TopUpSuccess)
    topUpSuccess(ctx: StateContext<TopupStateModel>, action: TopupActions.TopUpSuccess) {
        const { result } = action;
        ctx.patchState({
            loading: false,
            topUpResult: result,
            error: false
        })

        this.paymentCacheService.setPaymentCacheItem(TOPUP_RESULT, result);
        setTimeout(() => {
            return ctx.dispatch([new CoreActions.GetWalletData('', false), new CoreActions.CheckedOut()])
        }, 500);
    }

    @Action(TopupActions.SetBuyMoreMsisdn)
    SetBuyMoreMsisdn(ctx: StateContext<TopupStateModel>, action: TopupActions.SetBuyMoreMsisdn) {
        const { msisdn } = action.payload;
        
        ctx.patchState({
            buyMoreMsisdn: msisdn
        });
    }
    @Action(TopupActions.ClearTopUpDetails)
    clearTopUpDetails(ctx: StateContext<TopupStateModel>, action: TopupActions.ClearTopUpDetails) {
        const state = ctx.getState();
        ctx.setState({
            selectedTopup: null,
            topUpResult: null,
            topUpMsisdn: null,
            topUpFreeResult: null,
            error:false,
            loading: false,
            errorMessage: null,
            buyMoreMsisdn: state.buyMoreMsisdn
        })
    }

}