import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { RevokeMigration } from 'src/app/store/Core/actions/service.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'raingo-reverse-level-migration-confirm-sheet',
  templateUrl: './reverse-level-migration-confirm-sheet.component.html',
  styleUrl: './reverse-level-migration-confirm-sheet.component.scss'
})
export class ReverseLevelMigrationConfirmSheetComponent {

  constructor(private readonly store: Store) {}

  public RevokeScheduledAction() {
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);    
    const actions = [];

    //selectedSvc is a sub service
    if(selectedSvc.parent_service_id) {
      const gSvc = this.store.selectSnapshot(CoreState.getServicebyID(selectedSvc.parent_service_id));
      
      actions.push(new RevokeMigration({product_id: gSvc!.serviceObject.product_id ,service_id: selectedSvc.parent_service_id}));
      
      Object.keys(gSvc!.subServices).forEach((key: string) => {
        actions.push(new RevokeMigration({product_id: gSvc!.subServices[key].product_id, service_id: key}));
      });
    } else  { 
      const gSvc = this.store.selectSnapshot(CoreState.getServicebyID(selectedSvc.id));
      
      //selectedSvc is a parent service
      if(gSvc?.subServices && Object.keys(gSvc?.subServices).length) {
        actions.push(new RevokeMigration({product_id: gSvc.serviceObject.product_id, service_id: gSvc.serviceObject.id}));

        Object.keys(gSvc!.subServices).forEach((key: string) => {
          actions.push(new RevokeMigration({product_id: gSvc!.subServices[key].product_id, service_id: key}));
        });

      } else {
        //selectedSvc is stand alone service
        actions.push(new RevokeMigration({product_id: selectedSvc.product_id, service_id: selectedSvc.id}));
      }

    }
    
    return this.store.dispatch(actions);
  }

  public close() {
    this.store.dispatch(new CloseBottomSheet());
  }
}
