import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MessageBarConfig } from 'src/app/interfaces';
import { DismissMessageBar } from 'src/app/store/Core/actions/core.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent {
  @Select(CoreState.getMessageBarConfig) messageBarConfig$: Observable<MessageBarConfig>;

  constructor(private readonly store: Store) {}

  public dismissMessageBar(mConfig: MessageBarConfig) {
    if(mConfig.code) {
      sessionStorage.setItem('dismissed_msg', mConfig.code);
    }
    this.store.dispatch(new DismissMessageBar());

  }
}
