import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { SpeedDownService } from 'src/app/store/Core/actions/service.actions';

@Component({
  selector: 'raingo-confirm-speed-down-sheet',
  templateUrl: './confirm-speed-down-sheet.component.html',
  styleUrl: './confirm-speed-down-sheet.component.scss'
})
export class ConfirmSpeedDownSheetComponent {
  @Select(DashboardState.GetSpeedUpConfig) config$: Observable< {
    canChangeSpeed: boolean,
    currentSpeed: string,
    selectedSpeed: any | null}>
  constructor(private readonly store: Store) {}

  public close() {
    this.store.dispatch(new CloseBottomSheet());
  }

  public continueSpeedDown() {
    const selectedSpeed = this.store.selectSnapshot(DashboardState.GetSpeedUpConfig)?.selectedSpeed!;

    
    this.store.dispatch(new SpeedDownService({speed: selectedSpeed.name}))
  }

  getSpeed(policy: string) {
    if(!policy) return;
    if (policy.includes('30')) {
      return '30 Mbps';
    } else if (policy.includes('60')) {
      return '60 Mpbs';
    } else if (policy.includes('Unlimited') || policy.includes('High')) {
      return '100+';
    }
    return false;
  }

}
