import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { RevokeSpeedDown } from 'src/app/store/Core/actions/service.actions';

@Component({
  selector: 'raingo-reverse-speed-down-confirm-sheet',
  templateUrl: './reverse-speed-down-confirm-sheet.component.html',
  styleUrl: './reverse-speed-down-confirm-sheet.component.scss'
})
export class ReverseSpeedDownConfirmSheetComponent {
  constructor(private readonly store: Store) {}

  public close() {
    return this.store.dispatch(new CloseBottomSheet());
  }

  public RevokeScheduledAction() {
    const selectedSvc  = this.store.selectSnapshot(DashboardState.GetSelectedService);

    return this.store.dispatch(new RevokeSpeedDown({serviceId: selectedSvc.id}));
  }
}
