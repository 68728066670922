<div class="header">
  <img (click)="goHome()" src="../../../assets/images/rain-go-logo.svg" alt="rainGo logo" width="75.79px" height="18px">
  <div class="col-right">

    @if (showTestPageMenuItem) {
      <div (click)="goToTestPage()" class="sign-in">Test Page</div>
    }
    @if ((viewModel$ | async)?.canSignIn) {
      <button (click)="handleAuth()" class="sign-in">sign out</button>
    }

  </div>
</div>