@if ((config$ | async)?.maintenanceModeOn) {
  <section>
    <div class="header">
      <img src="assets/gifs/maintenance-animation.gif" alt="maintenance mode gif">
    </div>
    <div class="copy-container">
      <div class="logo-container">
        <img src="assets/images/rain-go-logo.svg" alt="RainGo logo">
      </div>
      <h1>website update<br> in progress</h1>
      <p>Please bear with us while<br> we make a few updates</p>
    </div>
  </section>
}
