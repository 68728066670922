import { Component, OnDestroy } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { SignOut } from 'src/app/store/Auth/actions/auth.actions';
import { ChangeOpenSheet} from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-session-expired-sheet',
  templateUrl: './session-expired-sheet.component.html',
  styleUrl: './session-expired-sheet.component.scss'
})
export class SessionExpiredSheetComponent implements OnDestroy {

  constructor(private readonly store: Store) {}

  public accept() {
    this.store.dispatch([new SignOut(), new Navigate(['/services']), new ChangeOpenSheet('sign_in')]);
  }

  ngOnDestroy(): void {
    this.store.dispatch([new SignOut(), new ChangeOpenSheet('sign_in')]);
    
  }
}
