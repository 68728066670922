import { HttpErrorResponse } from '@angular/common/http';
import { Result } from '../interfaces/interfaces';
import { of, Observable } from 'rxjs';
import { isNil, isEmpty } from 'lodash';

/**
 * ServiceHelper @param
 */
export class ServiceHelper {
  public static handleError<T>(response: any): Observable<Result<T>> {
    if (response instanceof HttpErrorResponse) {
      const httperror = response as HttpErrorResponse;

      const content = httperror.error;

      let message = 'Unknown error';

      if (isNil(content) == false) {
        if (isNil(content.error) == false && isEmpty(content.error) == false) {
          message = content.error;
        } else {
          if (isNil(content.response) == false && isEmpty(content.response) == false) {
            message = content.response;
          } else {
            if (isNil(content.message) == false && isEmpty(content.message) == false) {
              message = content.message;
            }
          }
        }
      }

      if (httperror.status === 404) {
        return of(Result.notFound());
      }

      if (httperror.status === 409 || httperror.status === 406) {
        return of(Result.conflict<T>(message));
      }

      return of(Result.error(message));
    }

    return of(Result.error(response.message));
  }
}
