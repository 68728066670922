<app-bottom-sheet-content-template>

    <h1 Header>level change <strong>failed</strong></h1>

    <div Content>
        <div class="info-lockup">
            <p>Looks like something went wrong. Don’t worry a ticket has been logged and one of our agents will be in contact with you.</p>
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>

</app-bottom-sheet-content-template>