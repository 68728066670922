import { ServiceObject, TopUpOption } from "src/app/interfaces/interfaces";
import { Utils } from "src/app/utils";

export class SetSelectedTopup {
    static readonly type = Utils.Helpers.Type('[TopUp: Set] Set Selected Topup Option')
    constructor(public option: TopUpOption) {}
}

export class StartFreeGigPurchase {
    static readonly type = Utils.Helpers.Type('[TopUp: Start] Start free gig purchase')
    constructor(public option: TopUpOption) {}
}

export class SpeedUpMigration {
    static readonly type = Utils.Helpers.Type('[TopUp: Post] Speed Up')
    constructor(public readonly payload: {option: TopUpOption, msisdn: string,service_id: string, name?: string, bucketName?: string}) {}
}
export class TopUp {
    static readonly type = Utils.Helpers.Type('[TopUp: Post] Topup')
    constructor(public readonly payload: {option: TopUpOption, msisdn: string, name?: string, bucketName?: string}) {}
}
export class TopUpSuccess {
    static readonly type = Utils.Helpers.Type('[TopUp: Post] Topup Sucess')
    constructor(public result: any) {}
}
export class ClearTopUpDetails {
    static readonly type = Utils.Helpers.Type('[TopUp: Reset] Topup state reset')
}

export class TopUpFree {
    static readonly type = Utils.Helpers.Type('[TopUp: Post] Topup Free')
    constructor(public option: any, public msisdn: string, public walletName: string , public name?: string, public service?: ServiceObject) {}
}
export class TopUpFreeSuccess {
    static readonly type = Utils.Helpers.Type('[TopUp: Post] Topup Free Sucess')
    constructor(public result: any) {}
}
export class ClearTopUpFreeDetails {
    static readonly type = Utils.Helpers.Type('[TopUp: Reset] Topup Free state reset')

}
export class SetBuyMoreMsisdn {
    static readonly type = Utils.Helpers.Type('[TopUp: Buy-More] Set buy more msidn');

    constructor(public readonly payload: {msisdn: string}) {}
}