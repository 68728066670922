<app-bottom-sheet-content-template>

  <h1 Header>who are you <b>buying</b> for?</h1>

  <div Content class="topup-options-container">

      <div class="add-number">

          <div class="add-option-input">
            <form [formGroup]="anotherNumberForm">
              <div class="number-input">
                <input #numberInput type="tel" placeholder="enter number" maxlength="11" onkeyup="this.value=this.value.replace(/[^\d]/,'')" [formControlName]="'number'" (focus)="isFocused = true" (blur)="isFocused = false">
              </div>
            </form>

            <div class="actions">
              <button (click)="topUp()" [ngClass]="{'disabled': isDisabled} ">
                @if (!(loading$ | async)) {
                  <p>
                    buy
                  </p>
                }
                @if ((loading$ | async)) {
                  <p class="pendingAnimation"></p>
                }
              </button>
            </div>
          </div>
      </div>

    @if (((error$ | async) || hasError) && attemptedToSubmit) {
      <div class="form-error">
        <p> <img src="assets/images/error-icon.png" alt="error"> <b>{{(errorMessage$ | async)?.boldText}}</b> {{(errorMessage$ | async)?.lightText}}</p>
      </div>
    }

  </div>
</app-bottom-sheet-content-template>