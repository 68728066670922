
<app-bottom-sheet-content-template>

    <h1 Header>
        password <b>reset sent</b>
    </h1>
    
    <div Content>
        <div class="actions">
            <button (click)="backToLogin()">back to login</button>
        </div>
    </div>

</app-bottom-sheet-content-template>