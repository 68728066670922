import * as moment from "moment";

/**
 * 
 * @param billCycleDate 
 * @param amount 
 * @returns 
 */
export function CalcProRata(billCycleDate: string, amount: string) {
    const selectedPaymentDate = moment(billCycleDate).date();
    const today = moment().startOf('d');
    const todayMonth = moment().month();
    const todayDay = moment().date();
    const todayYear = moment().year();
    let delDays = 0;
    let nextBillCycle;
    let lastBillCycle;

    if (Number(selectedPaymentDate > todayDay)) {
        nextBillCycle = moment({ date: Number(selectedPaymentDate), month: moment().month(), year: todayYear })
        lastBillCycle = moment({ date: Number(selectedPaymentDate), month: determineLastMonth(todayMonth), year: determineIfLastYear(todayMonth, todayYear) })
    } else {
        nextBillCycle = moment({ date: Number(selectedPaymentDate), month: determineNextMonth(todayMonth), year: determineIfNextYear(todayMonth, todayYear) })
        lastBillCycle = moment({ date: Number(selectedPaymentDate), month: todayMonth, year: todayYear })
    }

    const daysInCycle = nextBillCycle.diff(lastBillCycle, 'days');
    const proRataDays = nextBillCycle.diff(today, 'days') - delDays - 1; // note we subtract 1 as we don't count today
    const proRataRate = Number(amount) / daysInCycle;
    let proRataAmount = proRataRate * proRataDays;

    if (proRataAmount < 1) return 1
    return proRataAmount
}


/**
 * 
 * internal helper functions
 */
function determineNextMonth(todayMonth: number) {
    return todayMonth < 11 ? todayMonth + 1 : 0;
}

function determineIfNextYear(todayMonth: number, todayYear: number) {
    return todayMonth == 11 ? todayYear + 1 : todayYear;
}

function determineLastMonth(todayMonth: number) {
    return todayMonth > 0 ? todayMonth - 1 : 11;
}

function determineIfLastYear(todayMonth: number, todayYear: number) {
    return todayMonth === 0 ? todayYear - 1 : todayYear;
}