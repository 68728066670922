import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Selector, Store } from '@ngxs/store';
import { isEmpty, isNil } from 'lodash';
import { EMPTY, Observable, Subject, catchError, delay, map, of, retryWhen, switchMap, take, takeUntil, throwError } from 'rxjs';
import { concat, expand, last, takeLast } from 'rxjs/operators';
import { SELECTED_MSISDN, SELECTED_TOPUP } from 'src/app/constants/constants';
import { IPaymentStatusDetail, PurchaseHistoryItem, PurchaseStatus, TopUpOption } from 'src/app/interfaces/interfaces';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { PaymentCacheService } from 'src/app/services/payment-cache.service';
import { PaymentStatuses, TopupService } from 'src/app/services/topup.service';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { BottomSheetState } from 'src/app/store/BottomSheet/bottom-sheet.state';
import { CoreState } from 'src/app/store/Core/core.state';
import { PaymentActions } from 'src/app/store/Payment/actions';
import { PaymentState } from 'src/app/store/Payment/payment.state';
import { TopUp } from 'src/app/store/topup/actions/topup.actions';
import { TopupState } from 'src/app/store/topup/topup.state';

const RETRY_COUNT = 4;
const RETRY_DELAY = 15000;
const ERROR_MESSAGE_PATTERN = 'svr';
const RETRY_FAILURE_MESSAGE = `Sorry, there was no result after ${RETRY_COUNT} retries`;
const NOT_COMPLETE_MESSAGE = 'Not complete yet';
const ERROR_PAYMENT_TIMEOUT = 'Payment status Timeout';

interface PaymentResultViewModel {
  loaded: boolean,
  paymentResult: PurchaseStatus
}

class PaymentResultSelectors {

  @Selector([PaymentState.loaded, PaymentState.paymentResult])
  static viewModel(loaded:boolean, paymentResult: PurchaseStatus): PaymentResultViewModel {
    return {
      loaded,
      paymentResult
    }
  }
}

@Component({
  selector: 'app-payment-result-new',
  templateUrl: './payment-result-new.component.html',
  styleUrls: ['./payment-result-new.component.scss']
})
export class PaymentResultNewComponent implements OnInit, OnDestroy {

  @Select(PaymentResultSelectors.viewModel) viewModel$?: Observable<PaymentResultViewModel>

  msisdn:string = "";

  successful: boolean = true;

  loading: boolean = true

  ngDestroy$: Subject<null> = new Subject<null>

  paymentStatus?: PurchaseStatus;

  PaymentStatuses = PaymentStatuses

  topup?: TopUpOption;

  constructor(private store: Store, private paymentCacheService: PaymentCacheService) {}

  ngOnInit(): void {
    // this.populate()
    
    this.topup = this.store.selectSnapshot(TopupState.getSelectedTopup) ?? this.paymentCacheService.getPaymentCacheItem(SELECTED_TOPUP);
    this.msisdn = this.store.selectSnapshot(TopupState.getSetMsisdn) ?? this.paymentCacheService.getPaymentCacheItem(SELECTED_MSISDN);
    const paymentMethod = this.store.selectSnapshot(BottomSheetState.GetSheetData);

    this.store.dispatch(new PaymentActions.GetPaymentResult(paymentMethod));

    // this.getPaymentStatus(paymentId!)
  }


  public closeSheet() {
    this.store.dispatch(new BottomSheetActions.CloseBottomSheet);
  }

  ngOnDestroy(): void {
    this.paymentCacheService.clearPaymentCache();
    this.store.dispatch(new PaymentActions.ClearPaymentResult)
  }

}
