import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/store/Core/core.state';
import { ClosePWAInstaller } from 'src/app/store/pwa/actions/pwa.actions';
import { PWAState } from 'src/app/store/pwa/state/pwa.store';

@Component({
  selector: 'raingo-pwa-installer',
  templateUrl: './pwa-installer.component.html',
  styleUrls: ['./pwa-installer.component.scss']
})
export class PwaInstallerComponent {
    @Select(PWAState.GetInstallerData) data$: Observable<{ mobileType: 'ios' | 'android' | 'browser', promptEvent: any }>;
    @Select(CoreState.getActiveBreakpoint) ap$: Observable<string>;

    constructor(private store: Store) {}

    public installPwa(type: string): void {
      if(type === 'browser') return this.installDesktop();

      const data = this.store.selectSnapshot(PWAState.GetInstallerData);
      data.promptEvent.prompt();
      this.close();
    }

    public close() {
      this.store.dispatch(new ClosePWAInstaller());
    }

    public rejectInstall() {
      const now = new Date();
      // add one week exactly
      now.setDate(now.getDate() + 7);
      document.cookie = `discardAppInstall=true;expires=${now.toUTCString()};path=/`;
      this.store.dispatch(new ClosePWAInstaller());
    }

    public installDesktop() {
      const data = this.store.selectSnapshot(PWAState.GetInstallerData);

      data.promptEvent.prompt();  // Wait for the user to respond to the prompt
      data.promptEvent.userChoice
        .then((choice: any) => {
            if (choice.outcome === 'accepted') {
                console.log('User accepted');
            } else {
                console.log('User dismissed');
            }

            this.close();
        })
    }
}
