import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { OpenOtp } from 'src/app/interfaces';
import { TLSDecryptedResponse } from 'src/app/interfaces/interfaces';
import { SetSelectedService, SetSelectedServiceById } from 'src/app/pages/Dashboard/store/actions/dashboard.actions';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { SSOService } from 'src/app/services/sso.service';
import { AuthActions } from 'src/app/store/Auth/actions';
import { AuthIfNotAuthed, ShowLoader, SignIn, SignOut } from 'src/app/store/Auth/actions/auth.actions';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { AuthWithTLS, RequestToDecodeIdentifierSuccess, SSOSignIn, TurnOffFirstTimeLanding } from 'src/app/store/Core/actions/core.actions';
import { CoreState } from 'src/app/store/Core/core.state';
import { FetchProductsSuccess } from 'src/app/store/Product/actions/product.actions';
import { environment } from 'src/environments/environment';

interface NavConfig {
  home: boolean,
  ask: boolean,
  manageAccount: boolean,
  manageSim: boolean,
  authed: boolean,
  signIn: boolean
}

@Component({
  selector: 'app-landing-navbar',
  templateUrl: './landing-navbar.component.html',
  styleUrls: ['./landing-navbar.component.scss']
})
export class LandingNavbarComponent implements OnInit, OnDestroy {
  @Select(CoreState.IsFirstTimeLanding) isFirstTimeLanding$: Observable<boolean>;
  @Select(CoreState.getDecodedTLSCredentials) decodeIdentifier$: Observable<any>;
  @Select(CoreState.GetOpenOTPs) openOtps$: Observable<OpenOtp[]>;
  @Select(CoreState.isSimAssigned) isSimAssigned$: Observable<boolean>;

  private ngDestroy$: Subject<any> = new Subject();

  public navConfig$: Observable<NavConfig> = this.setNavConfig();
  public selectedNavIcon: string | null;
  whoamiMsisdn: any;

  constructor(
    private store: Store,
    private actions: Actions,
    private readonly aRoute: ActivatedRoute,
    private readonly sso: SSOService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const href = window.location.pathname;

    switch (href) {
      case '/home':
        this.selectedNavIcon = 'mobile';
        break;
 
        case '/services':
        this.selectedNavIcon = 'home';
        break;
    
      default:
        this.selectedNavIcon = null;
        break;
    }
    this.aRoute.queryParams
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((res) => {
      const { sheet, sso} = res;

      if(sheet) {
        // this.store.dispatch(new ShowBottomSheet(sheet));
      }

      if(sso) {
        // this.store.dispatch(new SignOut());
        this.store.dispatch(new ShowLoader());
        this.sso.handleSSOSignIn(window.location.href);
      }
    });

    this.navConfig$ = this.setNavConfig();

    this.actions.pipe(
      ofActionCompleted(AuthIfNotAuthed, SignIn, SSOSignIn, AuthWithTLS, RequestToDecodeIdentifierSuccess, FetchProductsSuccess, SetSelectedService, SetSelectedServiceById)
    ).subscribe({
      next: (res) => {
        const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
        if ( isSignedIn ) this.store.dispatch(new TurnOffFirstTimeLanding());

        if( res?.action instanceof SignIn || res?.action instanceof SSOSignIn ) {
          const isFirstTimeLanding = this.store.selectSnapshot(CoreState.IsFirstTimeLanding);
          if ( isFirstTimeLanding ) this.store.dispatch(new TurnOffFirstTimeLanding());
        }

        if (window.location?.href?.includes('/home')) {
          this.selectedNavIcon = 'mobile';
        }
        else if (window.location?.href?.includes('/services')) {
          this.selectedNavIcon = 'home';
        }
        this.navConfig$ = this.setNavConfig();
      }
    });

    this.router.events
      .subscribe({
        next: (res) => {
          if( res instanceof NavigationEnd) {
            if(res.url.includes('assign')) this.selectedNavIcon = 'assign';
            else if(res.url === '/home') this.selectedNavIcon = 'mobile';
            else if(res.url.includes('/services')) this.selectedNavIcon = 'home';
            else {
              this.selectedNavIcon = null;
            }
          }
        }
    });
  }

  redirectToHomePage() {
    window.location.href = environment.MANAGE_PAGE;
  }

  openAssignSimBottomSheet(disable?: boolean) {
    const isSimAssigned = this.store.selectSnapshot(CoreState.isSimAssigned);
    const isPrimaryUser = this.store.selectSnapshot(CoreState.isPrimaryUser);
    const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
    const formattingServices = this.store.selectSnapshot(CoreState.isFormattingServices);
    const tlsLoaded = this.store.selectSnapshot(CoreState.tlsloaded);
    const decodedCreds = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse;
    if (!tlsLoaded) return;

    if(decodedCreds) {
      if(isAuthed && isPrimaryUser) {
        this.store.dispatch(new BottomSheetActions.ShowBottomSheet("assign_sim_primary_message"));
        return
      };
      const assignedServices = this.store.selectSnapshot(CoreState.GetAssignedServices);
      const alreadyAssigned = assignedServices?.find((as) => as.msisdn === decodedCreds?.udg.msisdn);
      if(alreadyAssigned) {
        return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("sim_already_assigned"));
      }
    }

    if(disable || isSimAssigned || formattingServices) {
      if(isSimAssigned) return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("sim_already_assigned"));
      else return;
    }
    return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("assign_sim_wizard"));
  }

  openCreateAccountSheet() {
    this.store.dispatch(new BottomSheetActions.ShowBottomSheet("register"));
  }

  handleAuth() {
    const token = this.store.selectSnapshot(AuthState.getToken) ?? null;
    
    const user: string = token ? JSON.parse(atob(token.split('.')[1]))?.sub : null;
    if (!token || user?.includes('browser')) return this.openSheet();
    else return this.signOut();
  }
  
  openSheet() {
    return this.store.dispatch(new BottomSheetActions.ShowBottomSheet('sign_in'));
  }

  signOut() {
    return this.store.dispatch([new AuthActions.SignOut]);
  }

  goToWifiPage() {
    this.selectedNavIcon = 'home';
    return this.store.dispatch([new Navigate(['/services'])]);
  }

  goToShop() {
    this.selectedNavIcon = 'mobile';
    this.store.dispatch(new Navigate(['/services']));
  }

  private setNavConfig() {
    const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
    if(isSignedIn) {
      
      return of({
        home: true,
        ask: true,
        manageAccount: !this.checkIfOnSVCPage(),
        manageSim: this.checkIfOnSVCPage(),
        authed: true,
        signIn: false
      });
    } else {
        
      return of({
        home: true,
        ask: true,
        manageAccount: false,
        manageSim: false,
        authed: false,
        signIn: true,
      });
    }
  }

  public isIdentified(decodeIdentifier: any) {
    if(decodeIdentifier) {
      const instertedSimMsisdn = decodeIdentifier.msisdn;
      
      if(decodeIdentifier.imsi.substring(0, 5) === "65573") return false;
      return Boolean(instertedSimMsisdn);
    }

    return;
  }

  public disableAssignSimIf5G() {
    const dCodedCreds = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);

    if(dCodedCreds) {
      const msisdn = dCodedCreds?.udg.msisdn as String;
      return Boolean(msisdn.length > 11);
    }

    return false;
  }

  private checkIfOnSVCPage(): boolean {
    const pathName = window.location.pathname;

    return Boolean(pathName.includes('/services/'));
  }

  public ssoToManage(isOnSVCPage: boolean) {
    if(isOnSVCPage) {
      const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
      const redirectUrl: string =`manage/services`;
      const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);

      const config = {serviceId: selectedSvc.id, mode: mode}
      return this.sso.createUrl(redirectUrl, false, config).then((url) => window.open(url, '_blank'));
    } else {
      const redirectUrl: string =`manage/account`;
      const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
      const config = {mode};
      return this.sso.createUrl(redirectUrl, false, config).then((url) => window.open(url, '_blank'));
    }
  }

  public goToAskRain() {
    this.store.dispatch(new BottomSheetActions.ShowBottomSheet('ask-rain-widget'));
  }


  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
