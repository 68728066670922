import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';

@Component({
  selector: 'app-password-reset-successful',
  templateUrl: './password-reset-successful.component.html',
  styleUrls: ['./password-reset-successful.component.scss']
})
export class PasswordResetSuccessfulComponent {
  constructor(private store: Store) {}

  backToLogin() {
    return this.store.dispatch(new BottomSheetActions.ChangeOpenSheet('sign_in'))
  }
}
