<app-bottom-sheet-content-template>

    <h1 Header>oh no, something went wrong</h1>

    <div Content>
        <div class="info-lockup">
            <p>We’re having trouble switching your international calling settings.</p>
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>

</app-bottom-sheet-content-template>