import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Selector, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ClientDetails, DigitalIdentityClientObject, PurchaseHistoryItem, RaingoPromoObject, ServiceObject, TopUpOption, WalletObject } from 'src/app/interfaces/interfaces';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { ClearTransaction, ResetCheckOut, ResetWallet, StartHomeLoader } from 'src/app/store/Core/actions/core.actions';
import { CoreState } from 'src/app/store/Core/core.state';
import { FireStoreActions } from 'src/app/store/FireStore/actions';
import { FirestoreState } from 'src/app/store/FireStore/firestore.state';
import { ProductState } from 'src/app/store/Product/state/product.state';
import { ClearTopUpDetails, SetSelectedTopup } from 'src/app/store/topup/actions/topup.actions';
import { TopupState } from 'src/app/store/topup/topup.state';
import { HomeViewModel } from './interfaces/home-view-model.interface';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { ClientMode } from 'src/app/store/Client Mode/actions/client-mode.actions';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { Navigate } from '@ngxs/router-plugin';


class HomeSelectors {
  @Selector([
    CoreState.HomeLoading, 
    TopupState.getSelectedTopup, 
    CoreState.getWallet, 
    CoreState.getClientDetails, 
    CoreState.checkoutSelected, 
    ProductState.GetProducts, 
    CoreState.shopHeaderServices,
    CoreState.getPaginatedWorkServices,
    CoreState.getPaginatedConsumerServices,
    CoreState.getUserPurchaseHistory, 
    AuthState.getAxiomUser,
    AuthState.getNickname,
    FirestoreState.getPromoDetails,
    CoreState.isFormattingServices,
    ClientModeState.GetClientMode,
    CoreState.showWorkHomeToggle
  ])
  static getViewModel(
      homeLoader: boolean, 
      selectedTopup: TopUpOption, 
      wallet: WalletObject, 
      clientDetails: ClientDetails, 
      checkedout: boolean, 
      products: {[key:string] : TopUpOption[]}, 
      servicePairs: Array<ServiceObject[]>, 
      workServicePairs: Array<ServiceObject[]>, 
      consumerServicePairs: Array<ServiceObject[]>, 
      purchaseHistory: PurchaseHistoryItem[], 
      user: DigitalIdentityClientObject, 
      nickname: string, 
      promo: RaingoPromoObject | null,
      isFormattingServices: boolean,
      clientMode: ClientMode,
      showHomeWorkToggle: boolean
    ): HomeViewModel {
    const productTypes = Object.keys(products);

    const displayServices = !showHomeWorkToggle ?  
      servicePairs :
      clientMode === "consumer" ? 
        consumerServicePairs : 
        workServicePairs;
    
    return {
      homeLoader,
      selectedTopup,
      wallet,
      clientDetails,
      checkedout,
      products,
      productTypes,
      servicePairs: displayServices,
      purchaseHistory,
      user,
      promo,
      nickname,
      isFormattingServices,
      showHomeWorkToggle
    }
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private ngDestroy$: Subject<any> = new Subject();
  constructor(private store: Store, private aRoute: ActivatedRoute) {};

  @Select(HomeSelectors.getViewModel) viewModel$?: Observable<HomeViewModel>;

  textSize: string = '16px';

  ngOnInit(): void {
    // this.store.dispatch([
    //   new FireStoreActions.FetchPromoDetails,
    //   new StartHomeLoader,
    //   new ResetCheckOut,
    //   new ResetWallet,
    //   new ClearTopUpDetails,
    //   new ClearTransaction,
    // ]);

    // setInterval(() => { this.resizeText() }, 100);
    this.store.dispatch(new Navigate(['../services']));
  }


  resizeText() {
    const container: HTMLElement = document.querySelector<HTMLElement>('.user-details')!;
    const ratio = container?.scrollWidth / document.body.clientWidth;

    if (ratio > 1) {
      const newSize = `${16/(ratio * 1.4)}px`;
      this.textSize =  newSize;
    } else {
      return 
    }
  }
 
  public setSelectedOption(option: TopUpOption) {
    this.store.dispatch(new SetSelectedTopup(option));
  }

  openSheet() {
    if (!/.*(iPad|iPhone|iPod).*/.test(window?.navigator?.userAgent)) {
      window.navigator.vibrate(50);
    }

    this.store.dispatch([new BottomSheetActions.ShowBottomSheet('select_topup_number')]);
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}