import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { SSOService } from 'src/app/services/sso.service';
import { TopupActions } from 'src/app/store/topup/actions';

@Component({
  selector: 'raingo-arrears-level-up-sheet',
  templateUrl: './arrears-level-up-sheet.component.html',
  styleUrl: './arrears-level-up-sheet.component.scss'
})
export class ArrearsLevelUpSheetComponent {

  constructor(private readonly sso: SSOService, private store: Store) {}

  public navigateToMyRain() {
    this.sso.createUrl('manage/billing').then((url) => window.open(url, '_self'));
  }

  ngOnDestroy(): void {
    this.store.dispatch(TopupActions.ClearTopUpDetails);
  }    
}
