import { Dictionary, isNil } from 'lodash';

/**
 * PREFIX @param
 */
const PREFIX = '8927380000';

class ICCIDUtility {
  private static hashMap: Dictionary<string> = {
    '0': '1',
    '1': '2',
    '2': '3',
    '3': '4',
    '4': '5',
    '5': '6',
    '6': '7',
    '7': '8',
    '8': '9',
    '9': 'B',
    A: 'C',
    B: 'D',
    C: 'F',
    D: 'G',
    E: 'H',
    F: 'J',
    G: 'K',
    H: 'L',
    I: 'M',
    J: 'N',
    K: 'P',
    L: 'R',
    M: 'S',
    N: 'T',
    O: 'V',
    P: 'W',
    Q: 'X',
    R: 'Y',
    S: 'Z'
  };

  private static swapped: any = Object.keys(ICCIDUtility.hashMap).reduce((map: any, key: string) => {
    const value: string = ICCIDUtility.hashMap[key];

    map[value] = key;

    return map;
  }, {});

  public static encode(iccid: string): string | null {
    if (isNil(iccid) || iccid.length < 19) {
      return null;
    }

    const substring: string = iccid.substring(10);

    if (substring[0] != '4') {
      return null;
    }

    const subStringNumber = parseInt(substring, 10);

    const iccidBase29: string = subStringNumber.toString(29).toUpperCase();

    const iccidCharArray: string[] = iccidBase29.split('');

    for (let i = 0; i < iccidCharArray.length; i++) {
      iccidCharArray[i] = ICCIDUtility.hashMap[iccidCharArray[i]];
    }

    return iccidCharArray.join('');
  }

  public static decode(rainAlpha: string): string | null {
    if (isNil(rainAlpha)) {
      return null;
    }

    rainAlpha = rainAlpha.toUpperCase();

    const iccidCharArray: string[] = rainAlpha.split('');

    for (let i = 0; i < iccidCharArray.length; i++) {
      const key = iccidCharArray[i];

      iccidCharArray[i] = ICCIDUtility.swapped[key];
    }

    const substring: string = iccidCharArray.join('');

    return `${PREFIX}${parseInt(substring, 29).toString(10)}`;
  }
}

export function GenerateICCIDShortCode(iccid: string): string {
    return ICCIDUtility.encode(iccid)!;
}