import { Component, OnInit, ViewChild } from '@angular/core';
import { TopupState } from '../../store/topup/topup.state';
import { Select, Store } from '@ngxs/store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { PeachWidgetComponent } from './components/peach-widget/peach-widget/peach-widget.component';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { HideLoader, ShowLoader } from 'src/app/store/Core/actions/core.actions';
import { TopupService } from 'src/app/services/topup.service';
import { CoreState } from 'src/app/store/Core/core.state';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { PaymentState } from 'src/app/store/Payment/payment.state';
import { PaymentActions } from 'src/app/store/Payment/actions';
import { PAYMENT_METHOD } from 'src/app/constants/constants';
import { PaymentCacheService } from 'src/app/services/payment-cache.service';
import { Utils } from '@Utils';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {
  @Select(CoreState.isLoaderOpen) isOpen$!: Observable<boolean>
  @Select(TopupState.getTopUpResult) checkout$?: Observable<any>
  public showPaymentMethods: boolean = true;
  public showEftAccordion: boolean = false;
  ngUnsubscribe = new Subject<null>()
  paymentMethods: any
  isMobile = !!navigator.userAgent.match(/(iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  isIpad = !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  checkoutId: string | null  = null
  message: string | null = null
  selectedPaymentType: any
  //TODO: use selector
  paymentHeader = { 
    partOne:'choose', 
    partTwo:' payment option'
  };
  peachWidget!: PeachWidgetComponent | null;
  @ViewChild(PeachWidgetComponent)
  set controlElRef(elementRef: PeachWidgetComponent) {
    if (elementRef) {
      this.peachWidget = elementRef;
      this.peachWidget = null;
    } 
  }

  // payAtUrl: any
  payUrl!: SafeResourceUrl
  showInstantIframe!: boolean;
  instantPayURL!: string;
  timer!: any;
  counter!: number;
  countDown!: Subscription;
  tick = 1000;
  eftLoaded!: boolean;
  redirectUrl!:string;

  constructor(
      public sanitizer: DomSanitizer,
      private store: Store,
      private dataLayerService: DataLayerService,
      private topupService: TopupService,
      private paymentCacheService: PaymentCacheService
    ) {
  }

  ngOnInit(): void {
      
      this.store.dispatch(new ShowLoader());
      this.returnPaymentMethods();
      this.redirectUrl = window.location.origin + '/payment-options';
      const routerState: any = this.store.selectSnapshot(RouterState.state) as any;
      
      const paymentIdInHeader = routerState?.queryParams['id'] || !!(this.store.selectSnapshot(RouterState.state)?.root?.queryParams['id']  || this.store.selectSnapshot(RouterState.state)?.root?.queryParams['payatReferenceNum'] || this.store.selectSnapshot(RouterState.state)?.root?.queryParams['referenceKey']);
      
      const paymentMethodName = this.store.selectSnapshot(PaymentState.GetMethod) ?? this.paymentCacheService.getPaymentCacheItem(PAYMENT_METHOD);
      
      const method = paymentMethodName === "CARD" ? "peach" : "payAt";
      
      if (paymentIdInHeader) {
        this.store.dispatch(new BottomSheetActions.ShowBottomSheet('purchase_result', method));
      };

      setTimeout(() => {
        Utils.Helpers.ScrollTo('.page-body-container');
      }, 500);
  }

  public filterPaymentOptions(options:any) {
    const device = 'mobile'
    if (device === 'mobile') return options.filter((o: any) => o.paymentMethodName.toLowerCase() !== 'qr code payment');
    
    return options;
  }

  public returnPaymentMethods() {
    const result = this.store.selectSnapshot(TopupState.getTopUpResult)
    
    if(result) {
      this.paymentMethods = result;
      let paymentMethods = [];
      
      paymentMethods.push({
          paymentMethodName: 'CARD',
          paymentUrl: '',
          paymentImage: '/assets/images/payment-page/card.png',
          isMobile: this.isIpad
      });

      if (!this.isIpad) {
        const setCardDetails = {
        paymentMethodName: 'CARD',
        paymentUrl: '',
        paymentImage: '/assets/images/payment-page/card.png',
        isMobile: this.isIpad
        };
      }

      if (this.paymentMethods?.checkout?.webPaymentLinks?.length > 0) {
        for (let i = 0; i < this.paymentMethods?.checkout?.webPaymentLinks.length; i++) {
          if (this.paymentMethods?.checkout?.webPaymentLinks?.[i]?.paymentMethodName === 'EFT') {
              paymentMethods.push({
              paymentMethodName: 'INSTANT EFT',
              paymentImage: '/assets/images/payment-page/Instant_EFT.svg',
              paymentUrl: this.paymentMethods?.checkout?.webPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: this.isMobile ? 'instant EFT_mobile' : 'Instant EFT_desktop'
              });
          }
          if (this.paymentMethods?.checkout?.webPaymentLinks?.[i]?.paymentMethodName === 'CAPITEC_EFT') {
              paymentMethods.push({
              paymentMethodName: 'CAPITEC EFT',
              paymentImage: '/assets/images/payment-page/CapitecPay-colour.png',
              paymentUrl: this.paymentMethods?.checkout?.webPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: this.isMobile ? 'Capitec EFT_mobile' : 'Capitec EFT_desktop'
              });
          }
        }
      }
      
      if (this.paymentMethods?.checkout?.appPaymentLinks?.length) {
        for (let i = 0; i < this.paymentMethods?.checkout?.appPaymentLinks.length; i++) {
          if (this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentMethodName.includes('SNAPSCAN')) {
              paymentMethods.push({
              paymentMethodName: 'SNAPSCAN',
              paymentImage: '/assets/images/payment-page/snapscan.png',
              paymentUrl: this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: 'SnapScan'
              });
          } else if (this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentMethodName.includes('ZAPPER')) {
              paymentMethods.push({
              paymentMethodName: 'ZAPPER',
              paymentImage: '/assets/images/payment-page/zapper_mobile_app.png',
              paymentUrl: this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: 'Zapper'
              });
          } else if (this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentMethodName.includes('MASTERPASS')) {
              paymentMethods.push({
              paymentMethodName: 'MASTERPASS',
              paymentImage: '/assets/images/payment-page/masterpass.png',
              paymentUrl: this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: 'Masterpass'
              });
          } else if (this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentMethodName.includes('NEDBANK_SCAN_TO_PAY')) {
              paymentMethods.push({
              paymentMethodName: 'NEDBANK SCAN TO PAY',
              paymentImage: '/assets/images/payment-page/nedbank-scan-to-pay.svg',
              paymentUrl: this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: 'Netbank scan to pay'
              });
          } else if (this.paymentMethods?.checkout?.appPaymentLinks?.[i]?.paymentMethodName.includes('FNB_SCAN_TO_PAY')) {
              paymentMethods.push({
              paymentMethodName: 'FNB SCAN TO PAY',
              paymentImage: '/assets/images/payment-page/FNB-Pay-rgb.png',
              paymentUrl: this.paymentMethods?.checkout?.appPaymentLinks[i]?.paymentUrl,
              isMobile: this.isMobile,
              gtm_label: 'FNB pay'
              });
          }
        }
      }

      if (this.paymentMethods?.checkout?.qrCodePayment) {
          paymentMethods.push({
          paymentMethodName: 'QR CODE PAYMENT',
          paymentImage: '/assets/images/payment-page/scan-to-pay.svg',
          paymentUrl: this.paymentMethods?.checkout?.qrCodePayment?.qrCodeUrl,
          isMobile: this.isMobile,
          gtm_label: 'Instant EFT_desktop'
          });
      }
      
      this.paymentMethods = Object.assign({}, {...this.paymentMethods, appPaymentLinks: paymentMethods })
      
      if (this.paymentMethods?.checkout) {
          
          this.checkoutId = this.paymentMethods?.checkout?.checkout;
          
      } else if (this.paymentMethods?.id) {
          this.checkoutId = this.paymentMethods?.id;
      } else {
          this.message = this.paymentMethods?.message;
          this.checkoutId = null;
      }
    }

    this.store.dispatch(new HideLoader)
  }

  clearSelectedPaymentMethod() {
    this.paymentHeader = {
      partOne:'choose',
      partTwo:' payment option'
    };
    setTimeout((() => {this.selectedPaymentType = {}}), 200)
  }

  showWidget(paymentMethod: any) {
    this.paymentHeader = {
      partOne:'enter',
      partTwo: ' card details'
    };

    if (paymentMethod.paymentMethodName === this.selectedPaymentType?.paymentMethodName) return true;
    this.selectedPaymentType = paymentMethod;
    const paymentMethodName = this.selectedPaymentType?.paymentMethodName?.toLowerCase();

    this.selectedPaymentType = paymentMethod;
    this.payUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedPaymentType?.paymentUrl);
    if ((paymentMethodName === 'instant eft' || paymentMethodName === "capitec eft")) { //redirect
      this.store.dispatch(new ShowLoader());
      this.startTimer();
      this.instantPayURL = this.selectedPaymentType?.paymentUrl;
      this.store.dispatch(new HideLoader());
    } else {
      this.store.dispatch(new ShowLoader());

      setTimeout(() => {
        this.store.dispatch(new HideLoader());
      }, 5000);
    }

    this.showInstantIframe = paymentMethodName === 'instant eft' || paymentMethodName === 'capitec eft';

    if (
      this.selectedPaymentType?.paymentMethodName !== 'INSTANT EFT' &&
      this.selectedPaymentType?.paymentMethodName !== 'CARD' &&
      this.selectedPaymentType?.paymentMethodName !== 'PAY IN-STORE' &&
      this.selectedPaymentType?.paymentMethodName !== 'QR CODE PAYMENT' &&
      this.selectedPaymentType?.paymentMethodName !== 'CAPITEC EFT'
    ) {
      window.open(this.selectedPaymentType?.paymentUrl);
    }

    if (
      this.selectedPaymentType?.paymentMethodName !== 'PAY IN-STORE' &&
      this.selectedPaymentType?.paymentMethodName !== 'CARD'  &&
      this.selectedPaymentType?.paymentMethodName !== 'CAPITEC EFT'  &&
      this.selectedPaymentType?.paymentMethodName !== 'INSTANT EFT'
    ) {
      this.store.dispatch(new BottomSheetActions.ShowBottomSheet('purchase_result', "payAt"))
    }

    this.clearTimer();
    this.initIframeWatcher();
    this.store.dispatch(new PaymentActions.SavePaymentMethod(this.selectedPaymentType))

    this.dataLayerService.addPaymentInfo(this.store.selectSnapshot(TopupState.getSelectedTopup)!, paymentMethod?.paymentMethodName)
    return
  }

  public selectPaymentType(type:any) {
    this.selectedPaymentType = type
  }

  private startTimer(time: number = 60000) {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.store.dispatch(new HideLoader());
    }, time);
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  getPaymentStatus(referenceKey: any) {
    setTimeout(() => {
      return this.topupService
        .getPaymentStatus(referenceKey)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            const result = res?.result
            if (result?.status === 'pending') {
              this.counter = 2;
              this.countDown = timer(0, this.tick).subscribe(() => {
                this.counter--;
                if (this.counter === 0) {
                  this.counter = 0;
                  this.countDown.unsubscribe();
                  this.getPaymentStatus(referenceKey);
                }
              });
            } else {
              this.store.dispatch(new Navigate(['/payment-options'], {referenceKey: referenceKey}))
            }
          },
          e => {
          throw e?.error
          }
        );
    }, 2000)
  }

  private initIframeWatcher() {
    const paymentType = this.selectedPaymentType.paymentMethodName.toLowerCase();
    if (paymentType === 'pay in-store' || paymentType === 'qr code payment') {
      setTimeout(() => {
        this.store.dispatch(new HideLoader());
      }, 2000);
    }
    return window.addEventListener('message', e => {
      switch (paymentType) {
        case 'card':
          if (e.origin === 'https://oppwa.com') {
            const data: string = e.data;

            if (data.includes('id')) {
              this.store.dispatch(new HideLoader());
              this.clearTimer();
            } else {

              return this.startTimer()
            }
          }
          break;

        case 'instant eft':
          if (e.origin === 'https://payat.io') {
            const data: string = e.data;
            if (data.includes('onLoad')) {
              this.eftLoaded = true;
              this.store.dispatch(new HideLoader());
              this.clearTimer();
            } else {

              return this.startTimer()
            }
          }
          break;
      }
    });
  }
}
