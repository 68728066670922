import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { HideTokenExpiredModal, SignOut } from 'src/app/store/Auth/actions/auth.actions';

@Component({
  selector: 'app-raingo-modal',
  templateUrl: './assign-sim-modal.component.html',
  styleUrls: ['./assign-sim-modal.component.scss']
})
export class RaingoModalComponent {


  constructor(private readonly store: Store) {}

  public stopAssignSimProcess(ev?: Event) {
    if(ev && ev.target) {
      const target = ev.target as HTMLElement
      if(target.classList.contains('modal-wrapper')) {
        return this.store.dispatch([new HideTokenExpiredModal(), new SignOut()]);
      }
      return;
    }
    
    return this.store.dispatch([ new HideTokenExpiredModal(), new SignOut(), new Navigate(['/'])]);
  }
}
