import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-level-change-failed',
  templateUrl: './level-change-failed.component.html',
  styleUrl: './level-change-failed.component.scss'
})
export class LevelChangeFailedComponent {

  constructor(private readonly store: Store) {}

  public accept() {
    this.store.dispatch([new CloseBottomSheet()]);
  }
}
