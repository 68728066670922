import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClientModeActions } from 'src/app/store/Client Mode/actions';
import { ClientMode } from 'src/app/store/Client Mode/actions/client-mode.actions';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-home-work-toggle',
  templateUrl: './home-work-toggle.component.html',
  styleUrls: ['./home-work-toggle.component.scss']
})
export class HomeWorkToggleComponent implements OnInit {
  @Select(ClientModeState.GetClientMode) mode$ : Observable<string>
  @Select(CoreState.hasWorkAndConsumerServices) svcConfig$ : Observable<{hasConsumerSVCs: boolean, hasWorkSVCs: boolean, hasMobileSVCs: boolean}>;

  constructor(private store: Store) {}

  ngOnInit() {
    const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    if(!mode) {
      this.setClientMode('consumer');
    } else {
      const check = this.store.selectSnapshot(CoreState.hasWorkAndConsumerServices);
      if(mode === 'consumer' && !check.hasConsumerSVCs) {
        if(!check.hasWorkSVCs) {
          this.setClientMode('mobile');
        } else {
          this.setClientMode('sme');
        }
      }
    }
  }

  setClientMode (mode: ClientMode) {
    this.store.dispatch(new ClientModeActions.SetClientMode(mode));
    setTimeout(() => {
      const element = document.querySelector('.content-wrapper') as HTMLElement;
      element?.scroll({ top: 0 , behavior: 'smooth' });
    }, 100);
  }

  public checkTrueValues(hasWorkAndConsumerServices: any) {
    return Object.values(hasWorkAndConsumerServices).filter(a=> a === true).length;
  }
}
