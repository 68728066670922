<ng-container >
    <div class="modal-wrapper" (click)="stopAssignSimProcess($event)">
        <div class="modal-container">
            <div class="modal-header">
                <i (click)="stopAssignSimProcess()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.5086 7.49142C16.8244 7.80723 16.8244 8.31925 16.5086 8.63506L13.1436 12L16.5086 15.365C16.8244 15.6808 16.8244 16.1928 16.5086 16.5086C16.1928 16.8244 15.6807 16.8244 15.3649 16.5086L12 13.1437L8.6351 16.5086C8.3193 16.8244 7.80727 16.8244 7.49147 16.5086C7.17566 16.1928 7.17566 15.6807 7.49147 15.3649L10.8564 12L7.4914 8.63507C7.17559 8.31926 7.17559 7.80724 7.4914 7.49143C7.80721 7.17563 8.31923 7.17563 8.63504 7.49143L12 10.8564L15.365 7.49142C15.6808 7.17561 16.1928 7.17561 16.5086 7.49142Z" fill="#D9D9D9"/>
                    </svg>                                               
                </i>
            </div>
            <div class="modal-body">
                <h3>Auth Token Expired</h3>

                <div class="copy-lockup">
                    <p>Your current session has expired and you need to login in again.</p>
                </div>
                    
            </div>
            <div class="modal-footer">
                <button (click)="stopAssignSimProcess()">got it</button>
            </div>
        </div>
    </div>
</ng-container>