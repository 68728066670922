export const FOURG_STANDALONE = "6cf4e840-00e3-4f8c-a0a0-d3baacc95fac";
export const CONFIG_TARIF = 'tarif';
export const DEFAULT_TARRIF = 50;
export const RICA_TYPE = 'rica_type';
export const RICA_TYPE_COURIER = 'courier';
export const RICA_TYPE_E_RICA = 'e-rica';

export const RAIN_MOBILE_BASE_SUBSCRIPTIONS = {
    '0': {
        DATA: 2,
        VOICE: 60,
        SMS: 100
    },
    '1': {
        DATA: 3,
        VOICE: 90,
        SMS: 100
    },
    '2': {
        DATA: 4,
        VOICE: 120,
        SMS: 100
    },
    '3': {
        DATA: 5,
        VOICE: 150,
        SMS: 100
    },
    '4': {
        DATA: 6,
        VOICE: 180,
        SMS: 100
    },
    '5': {
        DATA: 7,
        VOICE: 210,
        SMS: 100
    }
}