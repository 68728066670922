import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { FetchServices } from 'src/app/store/Core/actions/core.actions';
import { FetchOTPStats } from 'src/app/store/Core/actions/otp.actions';
import { CoreState, } from 'src/app/store/Core/core.state';

@Injectable()
export class FetchAllService {
  constructor(private store: Store, public router: Router) {}

  canActivate(): boolean {
    // const isServicePoliciesLoaded = this.store.selectSnapshot(CoreState.isServicePoliciesLoaded);
    this.store.dispatch(new FetchServices('FetchServicesGuard'));
    
    // if(!isServicePoliciesLoaded) {
    //   setTimeout(() => {
    //     return true;
    //   }, 500);
    // }
    return true;
  }
}

export const FetchServicesGuard: CanActivateFn = (route, state) => {
  return inject(FetchAllService).canActivate();
};
