import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { StopAssignSimProcess } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { CoreActions } from 'src/app/store/Core/actions';

@Component({
  selector: 'app-sim-already-assigned-sheet',
  templateUrl: './sim-already-assigned-sheet.component.html',
  styleUrls: ['./sim-already-assigned-sheet.component.scss']
})
export class SimAlreadyAssignedSheetComponent {

  constructor(private readonly store: Store){}

  public close() {
    return this.store.dispatch([new BottomSheetActions.CloseBottomSheet(), new StopAssignSimProcess()]);
  }
}
