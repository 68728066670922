import { Utils } from "src/app/utils";

export class SavePaymentMethod {
    static readonly type = Utils.Helpers.Type('[Payment: Set] Set topup payment method')
    constructor(public method: any) {}
}
export class GetPaymentResult {
    static readonly type = Utils.Helpers.Type('[Payment: Get] Get topup payment result')
    constructor(public method: string) {}
}

export class ClearPaymentResult {
    static readonly type = Utils.Helpers.Type('[Payment: Clear] Clear topup payment result')
}