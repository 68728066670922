import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { BottomSheetActions } from './actions';
import { BottomSheetName } from './intefaces/bottom-sheet-interfaces';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetContentContainerComponent } from 'src/app/shared/bottom-sheet-content-container/bottom-sheet-content-container.component';
import { StopAssignSimProcess } from '../AssignSim/actions/assign-sim.actions';
import { AssignSimState } from '../AssignSim/assign-sim.state';

function loadDefault():BottomSheetStateModel {
    return {
        openSheetName: null,
        sheetData: null,
        isOpen: false
    }
}

interface BottomSheetStateModel {
    openSheetName: BottomSheetName | null;
    sheetData: any,
    isOpen: boolean
}

@State<BottomSheetStateModel>({
    name: 'bottom_sheet_state',
    defaults: loadDefault()
})

@Injectable()
export class BottomSheetState {

    @Selector()
    static OpenSheetName(state: BottomSheetStateModel) { return state.openSheetName }
    @Selector()
    static GetSheetData(state: BottomSheetStateModel) { return state.sheetData }

    constructor(private bottomSheet: MatBottomSheet, private readonly store: Store) { }    

    @Action(BottomSheetActions.ShowBottomSheet)
    showBottomSheet(ctx: StateContext<BottomSheetStateModel>, action: BottomSheetActions.ShowBottomSheet) {
        const { openSheetName, data, clearAssignSimState } = action;

        if(clearAssignSimState) {
            ctx.dispatch(new StopAssignSimProcess())
        }
        
        ctx.patchState({
            openSheetName: openSheetName,
            sheetData: data ?? null,
            isOpen: true
        })
        
        return this.bottomSheet.open(BottomSheetContentContainerComponent)
    }

    @Action(BottomSheetActions.ChangeOpenSheet)
    changeOpenSheet(ctx: StateContext<BottomSheetStateModel>, action: BottomSheetActions.ChangeOpenSheet) {
        const { openSheetName, data, clearAssignSimState } = action
        const state = ctx.getState();
        const isOpen = state.isOpen;
        if(clearAssignSimState) {
            ctx.dispatch(new StopAssignSimProcess())
        }
        ctx.patchState({
            openSheetName: openSheetName,
            sheetData: data ?? null
        });

        const assignSimState = this.store.selectSnapshot(AssignSimState.GetStage);
        if(!isOpen && assignSimState !== null) {
            ctx.patchState({
                isOpen: true
            })
            this.bottomSheet.open(BottomSheetContentContainerComponent)
        }
    }

    @Action(BottomSheetActions.CloseBottomSheet)
    closeBottomSheet(ctx: StateContext<BottomSheetStateModel>, action: BottomSheetActions.CloseBottomSheet) {
        this.bottomSheet._openedBottomSheetRef?.dismiss()
        
        return ctx.patchState({
            openSheetName: null,
            sheetData: null,
            isOpen: false
        });
    }

}