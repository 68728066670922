import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordService {
    constructor(private http: HttpClient) {}

    sendEmail(email: string) {
        const url = `https://prod-bss-cf.rain.co.za/v1/fullcircle/password_reset/${email}`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'apiKey': environment?.apiKey
            })
        };
        
        return this.http.get(url, httpOptions)
    }
}