import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { BottomSheetContentContainerComponent } from './bottom-sheet-content-container/bottom-sheet-content-container.component';
import { PaymentResultNewComponent } from './bottom-sheet-content-container/bottom-sheet-components/payment-result/payment-result-new.component';
import { BottomSheetContentTemplateComponent } from './bottom-sheet-content-container/bottom-sheet-components/bottom-sheet-content-template/bottom-sheet-content-template.component';
import { RegisterComponent } from './bottom-sheet-content-container/bottom-sheet-components/register/register.component';
import { SignInComponent } from './bottom-sheet-content-container/bottom-sheet-components/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RaingoInputComponent } from './raingo-input/raingo-input.component';
import { RaingoTextareaComponent } from './raingo-textarea/raingo-textarea.component';
import { SelectTopUpNumberComponent } from './bottom-sheet-content-container/bottom-sheet-components/select-top-up-number/select-top-up-number.component';
import { LandingNavbarComponent } from './landing-navbar/landing-navbar.component';
import { AssignSimComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim/assign-sim.component';
import { AssignSimEnterNameComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-enter-name/assign-sim-enter-name.component';
import { AssignSimUnidentifiedComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-unidentified/assign-sim-unidentified.component';
import { AssignSimVerificationSuccessComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-verification-success/assign-sim-verification-success.component';
import { AssignSimPrimaryUserMessageComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-primary-user-message/assign-sim-primary-user-message.component';
import { PrimaryUserMessageSheetComponent } from './sheets/primary-user-message-sheet/primary-user-message-sheet.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AssignSimRequestSheetComponent } from './sheets/assign-sim-request-sheet/assign-sim-request-sheet.component';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { InArrearsComponent } from './bottom-sheet-content-container/bottom-sheet-components/in-arrears/in-arrears.component';
import { ForgotPasswordComponent } from './bottom-sheet-content-container/bottom-sheet-components/forgot-password/forgot-password.component';
import { PasswordResetSuccessfulComponent } from './bottom-sheet-content-container/bottom-sheet-components/password-reset-successful/password-reset-successful.component';
import { HomeWorkToggleComponent } from './home-work-toggle/home-work-toggle.component';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { RaingoModalComponent } from './components/assign-sim-modal/assign-sim-modal.component';
import { AskAssignSimSheetComponent } from './bottom-sheet-content-container/bottom-sheet-components/register-success-sheet/ask-assign-sim-sheet.component';
import { AssignSimWizardComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-wizard/assign-sim-wizard.component';
import { AssignSimRegisterOrSignInComponent } from './bottom-sheet-content-container/bottom-sheet-components/assign-sim-register-or-sign-in/assign-sim-register-or-sign-in.component';
import { SimAlreadyAssignedSheetComponent } from './bottom-sheet-content-container/bottom-sheet-components/sim-already-assigned-sheet/sim-already-assigned-sheet.component';
import { TopupFreeResultComponent } from './bottom-sheet-content-container/bottom-sheet-components/topup-free-result/topup-free-result.component';
import { PwaInstallerComponent } from './modals/pwa-installer/pwa-installer.component';
import { MaintenanceModeComponent } from './components/maintenance-mode/maintenance-mode.component';
import { PromoTileComponent } from './components/promo-tile/promo-tile.component';
import { PurchaseTabsComponent } from './components/purchase-tabs/purchase-tabs.component';
import { SimDetailsSheetComponent } from './sheets/sim-details-sheet/sim-details-sheet.component';
import { SpeedUpApprovalSheetComponent } from './sheets/speed-up-approval-sheet/speed-up-approval-sheet.component';
import { BuyIntMinutesSheetComponent } from './sheets/buy-int-minutes-sheet/buy-int-minutes-sheet.component';
import { LevelUpTableComponent } from '../pages/Dashboard/components/level-up-table/level-up-table.component';
import { LevelUpSuccessSheetComponent } from './sheets/level-up-success-sheet/level-up-success-sheet.component';
import { SessionExpiredSheetComponent } from './sheets/session-expired-sheet/session-expired-sheet.component';
import { LevelChangeFailedComponent } from './sheets/level-change-failed/level-change-failed.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { SpeedDownSuccessSheetComponent } from './sheets/speed-down-success-sheet/speed-down-success-sheet.component';
import { ConfirmSpeedDownSheetComponent } from './sheets/confirm-speed-down-sheet/confirm-speed-down-sheet.component';
import { ReverseSpeedDownConfirmSheetComponent } from './sheets/reverse-speed-down-confirm-sheet/reverse-speed-down-confirm-sheet.component';
import { ReverseSpeedDownSuccessSheetComponent } from './sheets/reverse-speed-down-success-sheet/reverse-speed-down-success-sheet.component';
import { ReverseLevelMigrationConfirmSheetComponent } from './sheets/reverse-level-migration-confirm-sheet/reverse-level-migration-confirm-sheet.component';
import { TopUpFailedSheetComponent } from './sheets/top-up-failed-sheet/top-up-failed-sheet.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ToggleIntDialingFailedSheetComponent } from './sheets/toggle-int-dialing-failed-sheet/toggle-int-dialing-failed-sheet.component';
import { ArrearsLevelUpSheetComponent } from './sheets/arrears-level-up-sheet/arrears-level-up-sheet.component';
import { ArrearsSpeedUpSheetComponent } from './sheets/arrears-speed-up-sheet/arrears-speed-up-sheet.component';
import { FullPageLoaderComponent } from './full-page-loader/full-page-loader.component';
import { AskrainWidgetSheetComponent } from './sheets/askrain-widget-sheet/askrain-widget-sheet.component';
import { NonEligibleTopUpErrorSheetComponent } from './sheets/non-eligible-top-up-error-sheet/non-eligible-top-up-error-sheet.component';


const components = [
  HeaderComponent,
  LoaderComponent,
  BottomSheetContentContainerComponent,
  PaymentResultNewComponent,
  BottomSheetContentTemplateComponent,
  RegisterComponent,
  SignInComponent,
  RaingoInputComponent,
  RaingoTextareaComponent,
  SelectTopUpNumberComponent,
  LandingNavbarComponent,
  AssignSimComponent,
  AssignSimEnterNameComponent,
  AssignSimUnidentifiedComponent,
  AssignSimVerificationSuccessComponent,
  AssignSimPrimaryUserMessageComponent,
  PrimaryUserMessageSheetComponent,
  AssignSimRequestSheetComponent,
  MessageBarComponent,
  InArrearsComponent,
  ForgotPasswordComponent,
  PasswordResetSuccessfulComponent,
  HomeWorkToggleComponent,
  LoadingCircleComponent,
  RaingoModalComponent,
  AssignSimWizardComponent,
  AssignSimRegisterOrSignInComponent,
  AskAssignSimSheetComponent,
  PwaInstallerComponent,
  PromoTileComponent,
  PurchaseTabsComponent,
  SimDetailsSheetComponent,
  SpeedUpApprovalSheetComponent,
  BuyIntMinutesSheetComponent,
  LevelUpSuccessSheetComponent,
  SessionExpiredSheetComponent,
  LevelChangeFailedComponent,
  SpeedDownSuccessSheetComponent,
  ConfirmSpeedDownSheetComponent,
  ReverseSpeedDownConfirmSheetComponent,
  ReverseSpeedDownSuccessSheetComponent,
  ReverseLevelMigrationConfirmSheetComponent,
  TopUpFailedSheetComponent,
  ToggleIntDialingFailedSheetComponent,
  ArrearsLevelUpSheetComponent,
  ArrearsSpeedUpSheetComponent,
  FullPageLoaderComponent,
  AskrainWidgetSheetComponent,
  NonEligibleTopUpErrorSheetComponent
]

@NgModule({
  declarations: [
    ...components,
    SimAlreadyAssignedSheetComponent,
    TopupFreeResultComponent,
    MaintenanceModeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    LevelUpTableComponent,
    NgxGpAutocompleteModule,
    ToggleButtonComponent
  ],
  exports: [
    ...components,
    MaintenanceModeComponent,
    FullPageLoaderComponent
  ]
})
export class SharedModule { }
