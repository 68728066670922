import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { StopAssignSimProcess } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { isDesktop } from 'src/app/store/Core/functions/core-state-functions';
import { PaymentActions } from 'src/app/store/Payment/actions';

@Component({
  selector: 'app-bottom-sheet-content-template',
  templateUrl: './bottom-sheet-content-template.component.html',
  styleUrls: ['./bottom-sheet-content-template.component.scss'],
})
export class BottomSheetContentTemplateComponent implements OnDestroy {
  @Input() loading: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() hasNoTitle: boolean = false;

  constructor(private readonly store: Store) {}

  public onClick() {
    // if(isDesktop()) {
      this.store.dispatch([new CloseBottomSheet(), new StopAssignSimProcess()]);
    // }
    this.store.dispatch([new StopAssignSimProcess()])
  }

  ngOnDestroy(): void {
    
    this.store.dispatch([new PaymentActions.ClearPaymentResult])
  }
  
}
