import { Utils } from "src/app/utils";

export type ClientMode = "consumer" | "sme" | "mobile";

export class SetClientMode {
    static readonly type = Utils.Helpers.Type('[Client Mode] Set Client Mode');
    constructor(public mode: ClientMode) {}
}
export class Reset {
    static readonly type = Utils.Helpers.Type('[Client Mode] Reset');
}