import { Bucket, ServiceObject } from "src/app/interfaces/interfaces";

/**
 *
 * @param objects
 * @param key
 */
export function ToHashMap<T>(obj: any[], state: any, key: string, key2?: string): { [key: string]: T } {
    return obj.reduce(
        (entities: { [id: string]: any }, item: any) => {
            let param = key2 ?  item[key][key2] : item[key];
            
            if (Array.isArray(item[key])) {
                param = item[key][0];
            }
            return {
                ...entities,
                [param]: item,
            };
        },
        { ...state.entities }
    );

}

/**
 *
 *
 * @export
 * @param {*} entities
 * @returns {T[]}
 */
export function FromHashMap<T>(entities: any): T[] | undefined {
    if(entities) {
        return Object.keys(entities).map(key => {
            if (key) return entities[key];
        });
    }

    return;
}

/**
 *
 *
 * @export
 * @param {any[]} obj
 * @param {*} state
 * @param {string} key
 * @returns
 */
export function ToSortedHashMap<T>(obj: any[], state: any, key: string, key2?: string): { [key: string]: T } {
    return obj.reduce((r: any, a: any) => {
        if (a !== null) {
            let param = key2 ?  a[key][key2] : a[key];
            
            r[param] = r[param] || [];
            r[param].push(a);
        }
        return r;
    }, Object.create(null));
}

/**
 *
 * @export
 * @template T
 * @param {{[lang: string]: T[]}} dataSet
 * @returns {T[]}
 */
export function FlattenHashMapArrays<T>(dataSet: { [id: string]: T[] }): T[] {
    const entities = FromHashMap<T[]>(dataSet)?.filter((i) => i !== undefined);
    
    const flatArr: any = [];

    // joining arrays into 1
    entities?.forEach(a => flatArr.push(...a));
    return flatArr;
}

export function FromMap<T>(map: any): T[] {
    let obj: any = {}
    for(let[k,v] of map) {
        v instanceof Map 
          ? obj[k] = FromMap(v)
          : obj[k] = v
    }
    return obj
}


export function sortServicesByParent(services: ServiceObject[], prepaid5GBucket?: any) {
    const lockup: any = {};
    if(!services) return;

    services.forEach((svc: ServiceObject) => {

      if (svc.status === 6) return;
      if (svc.parent_service_id) {
        if (lockup[svc.parent_service_id]) {
          lockup[svc.parent_service_id].subServices[svc.id] = svc;
        } else {
          const parentSvc = services?.find((service) => service?.id === svc?.parent_service_id);
          const product = svc.product!;
          const category = product?.category ? product?.category : product?.config?.subtype;
          
          if(prepaid5GBucket) {
            if(prepaid5GBucket.serviceID && prepaid5GBucket.serviceID === svc.parent_service_id) {
              lockup[svc.parent_service_id] = {
                isParentSvc: true,
                category: category,
                serviceObject: parentSvc,
                subServices: {
                  [svc.id]: svc
                },
                isLegacy: product?.config?.legacy,
                isPrepaid: product?.name.toLowerCase().includes('7 days') && product?.name.toLowerCase().includes('prepaid' || 'pay'),
                prepaid5GBucket,
                is5GPrepaid: true
              };
            }

            if(prepaid5GBucket.logicalResource && prepaid5GBucket.logicalResource.id === svc.parent_service_id) {
              lockup[svc.parent_service_id] = {
                isParentSvc: true,
                category: category,
                serviceObject: parentSvc,
                subServices: {
                  [svc.id]: svc
                },
                isLegacy: product?.config?.legacy,
                isPrepaid: product?.name.toLowerCase().includes('7 days') && product?.name.toLowerCase().includes('prepaid' || 'pay'),
                prepaid5GBucket,
                is5GPrepaid: true
              };
            }
          } else {
            lockup[svc.parent_service_id] = {
              isParentSvc: true,
              category: category,
              serviceObject: parentSvc,
              subServices: {
                [svc.id]: svc
              },
              isLegacy: product?.config?.legacy,
              isPrepaid: product?.name.toLowerCase().includes('7 days') && product?.name.toLowerCase().includes('prepaid' || 'pay')
            };
          }
        }
      } else {
        if (lockup[svc.id]) {
          return;
        } else {
          const product = svc.product!;
          const category = product?.category ? product.category : product?.config.subtype;
          lockup[svc.id] = {
            isParentSvc: Boolean(services.find(s => s.parent_service_id === svc.id)),
            serviceObject: svc,
            subServices: {},
            category: category,
            isLegacy: product?.config?.legacy,
            isPrepaid: (Boolean(product?.name.toLowerCase().includes('pay as you')))
          };
        }
      }
    });
    return lockup;
  }