import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ILevel } from '../../interfaces/level.interface';
import { IncrementLines, DecrementLines, SetSelectedLevel } from '../../store/actions/level-up.actions';
import { DashboardState } from '../../store/state/dashboard.state';
import { CommonModule } from '@angular/common';
import { DetailedService } from 'src/app/interfaces/services.interface';
import { FirestoreState } from 'src/app/store/FireStore/firestore.state';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';

@Component({
  selector: 'raingo-level-up-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './level-up-table.component.html',
  styleUrl: './level-up-table.component.scss'
})
export class LevelUpTableComponent {
  @Input() mode: number;
  @Select(DashboardState.GetLineSelectorConfig) minMax$: Observable<{min: number, current: number, max: number}>;
  @Select(DashboardState.GetSelectedLevel) SelectedLevel$: Observable<ILevel>;
  @Select(DashboardState.GetLevelUpConfig) config$: {step: 1 | 2 | 3};
  @Select(DashboardState.GetSelectedService) selectedSvc$: Observable<DetailedService>;
  public isRainMobile: boolean;
  public levels: Array<ILevel>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.selectedSvc$.subscribe({
      next: (res) => {

        if(res) {
          const LevelsConfig = this.store.selectSnapshot(FirestoreState.GetLevelsConfig)!;
          this.isRainMobile = Boolean(res.product?.name === 'standalone 4G generic consumer SIM AU');
          if(this.isRainMobile) {
            this.levels = LevelsConfig['mobile']
          } else {
            const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
            this.levels = mode === 'sme' ? LevelsConfig['sme'] : LevelsConfig['rainOne'];
          }
        }
      }
    });
  }

  public increment() {
    this.store.dispatch(new IncrementLines);
  }

  public decrement() {
    this.store.dispatch(new DecrementLines);
  }

  public selectLevel(level: ILevel) {
    return this.store.dispatch(new SetSelectedLevel(level));
  }
}
