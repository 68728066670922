import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-assign-sim',
  templateUrl: './assign-sim.component.html',
  styleUrls: ['./assign-sim.component.scss']
})
export class AssignSimComponent {

  constructor(private store: Store) {}

  public assignToSecondaryUser() {
    this.store.dispatch([new BottomSheetActions.CloseBottomSheet]);
    const isIdentifiedUser = this.store.selectSnapshot(CoreState.isIdentifiedUser);
    const sheetType = isIdentifiedUser ? 'assign_sim_enter_name' : 'assign_sim_unidentified';
    setTimeout(() => {
      this.store.dispatch(new BottomSheetActions.ShowBottomSheet(sheetType));
    }, 500);
    
  }

  public showPrimaryMessage() {
    const isPrimayUser = this.store.selectSnapshot(CoreState.isPrimaryUser);
    if(!isPrimayUser) {
      this.store.dispatch(new BottomSheetActions.ChangeOpenSheet("assign_sim_primary_message"))
    }
  }

}
