<app-bottom-sheet-content-template [fullWidth]="true" [hasNoTitle]="true">
    <div Content>
        @if(isAuthed$ | async;) {
            @if(user$ | async; as user) {
                <ai-chat-bot user_id="{{user.id}}"></ai-chat-bot>
            }
        } @else {
            <ai-chat-bot></ai-chat-bot>
        }
    </div>
</app-bottom-sheet-content-template>
