
<app-bottom-sheet-content-template>

    <h1 Header>assign a SIM</h1>
    
    <div Content>
        <div class="info-lockup">
            <p>Now rain<strong>one</strong> account holders can assign their rain mobile SIMs to other people. Allowing them to create their own rain accounts & take control of their top ups.</p>
            <p>Are you the rain<strong>one</strong> account holder?</p>
        </div>
        <div class="button-container">
            <button class="solid" (click)="showPrimaryMessage()">yes I am</button>
            <button class="transparent" (click)="assignToSecondaryUser()">no I'm not</button>
        </div>
    </div>

</app-bottom-sheet-content-template>