import { Component, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SELECTED_MSISDN, TOPUP_ERROR, TOPUP_FREE_SUCCESS } from 'src/app/constants/constants';
import { PaymentCacheService } from 'src/app/services/payment-cache.service';
import { TopupActions } from 'src/app/store/topup/actions';
import { TopupState } from 'src/app/store/topup/topup.state';

@Component({
  selector: 'app-topup-free-result',
  templateUrl: './topup-free-result.component.html',
  styleUrls: ['./topup-free-result.component.scss']
})
export class TopupFreeResultComponent implements OnDestroy {
  @Select(TopupState.getTopUpFreeResult) topupResult$!: Observable<any>;
  @Select(TopupState.getError) error$!: Observable<any>;

  constructor(private store: Store, private paymentCacheService: PaymentCacheService) {}

  topupFreeSuccess = this.paymentCacheService.getPaymentCacheItem(TOPUP_FREE_SUCCESS);
  msisdn = this.paymentCacheService.getPaymentCacheItem(SELECTED_MSISDN);
  error = this.paymentCacheService.getPaymentCacheItem(TOPUP_ERROR);

  ngOnDestroy(): void {
    this.store.dispatch(new TopupActions.ClearTopUpDetails());
    this.paymentCacheService.clearPaymentCache();
  }

}
