<div class="loader-wrapper">
    <div class="loading-container">
        <div class="loading-box">
            <div class="loading-sphere" 
                [style.backgroundColor]="loaderColor ?? '#0077C8'"
                [style.height]="size ? size+'px' : '40px'"
                [style.width]="size ? size+'px' : '40px'"
            ></div>
        </div>
    </div>
</div>