import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from "../../shared/shared.module";
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { ShopHeaderComponent } from './components/shop-header/shop-header.component';
import {MatTooltipModule} from '@angular/material/tooltip';


const declarations = [
  HomeComponent,
  ShopHeaderComponent,
]

@NgModule({
    declarations: [...declarations],
    exports: [...declarations],
    imports: [
        CommonModule,
        HomeRoutingModule,
        SharedModule,
        NavbarComponent,
        MatTooltipModule
    ]
})
export class HomeModule { }
