import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { StopAssignSimProcess } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';

@Component({
  selector: 'app-primary-user-message-sheet',
  templateUrl: './primary-user-message-sheet.component.html',
  styleUrls: ['./primary-user-message-sheet.component.scss']
})
export class PrimaryUserMessageSheetComponent {
  @Select(AssignSimState.AssimStateData) AssimStateData$: Observable<any>;
  constructor(private readonly store: Store){}

  public close() {
    return this.store.dispatch([new BottomSheetActions.CloseBottomSheet(), new StopAssignSimProcess()]);
  }
}
