import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RetryLoading } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { AuthWithTLS } from 'src/app/store/Core/actions/core.actions';

@Component({
  selector: 'app-assign-sim-unidentified',
  templateUrl: './assign-sim-unidentified.component.html',
  styleUrls: ['./assign-sim-unidentified.component.scss']
})
export class AssignSimUnidentifiedComponent {

  @Select(AssignSimState.isLoading) loading$: Observable<boolean>

  constructor(private readonly store: Store) {}

  public retryWhoami() {
    this.store.dispatch([new RetryLoading(true),  new AuthWithTLS(true)]);
  }
}
