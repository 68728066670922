import { RainOneProduct } from "src/app/interfaces/product.interface";
import { Utils } from "src/app/utils";

export class FetchCatelogueProducts {
    static readonly type = Utils.Helpers.Type('[Catelogue: Fetch] Fetch catelogue products');
}

export class FetchCatelogueProductsSuccess {
    static readonly type = Utils.Helpers.Type('[Catelogue: Fetch] Fetch catelogue products success');
    constructor(public readonly payload: Array<RainOneProduct>) {}
}

export class FetchCatelogueProductsFail {
    static readonly type = Utils.Helpers.Type('[Catelogue: Fetch] Fetch catelogue products fail');
    constructor(public readonly payload: any) {}
}