import { Utils } from "@Utils";
import { IFCCheckoutResponse } from "src/app/interfaces/services.interface";

export class SaveServiceName {
    static readonly type = Utils.Helpers.Type('[Service: Save] Save service name');
    constructor(public readonly payload: {
        name: string,
        serviceId: string
    }) {}
}

export class SaveServiceNameSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Save] Save service name success');
    constructor(public readonly payload: any) {}
}
export class SaveServiceNameFail {
    static readonly type = Utils.Helpers.Type('[Service: Save] Save service name fail');
    constructor(public readonly payload: any) {}
}

export class ToggleIntDialing {
    static readonly type = Utils.Helpers.Type('[Service: Int-Dialing] Toggle int dialing');
    constructor(public readonly payload: {enable: boolean, serviceID: string, isPurchasing?: boolean}) {}
}

export class ToggleIntDialingSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Int-Dialing] Toggle int dialing success');
    constructor(public readonly payload: {response: any, enabled: boolean}) {}
}
export class ToggleIntDialingFail {
    static readonly type = Utils.Helpers.Type('[Service: Int-Dialing] Toggle int dialing fail');
    constructor(public readonly payload: any) {}
}

export class setAddonType {
    static readonly type = Utils.Helpers.Type('[Service: Addon] Set Type');
    constructor(public payload : 'imediate'| 'scheduled'| 'none'){}
}

export class SpeedDownService {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Speed down service speed policy');
    constructor(public readonly payload: {speed: string}) {}
}

export class SpeedDownServiceSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Speed down service speed policy success');
    constructor(public readonly payload: any) {}

}
export class SpeedDownServiceFail {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Speed down service speed policy fail');
    constructor(public readonly payload: any) {}
}

export class ChangeServiceSpeed {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Change service speed policy');
    constructor(public readonly payload: {speed: string}) {}
}

export class ChangeServiceSpeedSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Change service speed policy success');
    constructor(public readonly payload: any) {}

}
export class ChangeServiceSpeedFail {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Change service speed policy fail');
    constructor(public readonly payload: any) {}
}

export class RevokeSpeedDown {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Revoke service speed change');
    constructor(public readonly payload: {serviceId: string}) {}
}
export class RevokeSpeedDownSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Revoke service speed change success');
    constructor(public readonly payload: any) {}
}
export class RevokeSpeedDownFail {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Revoke service speed change fail');
    constructor(public readonly payload: any) {}
}
export class GetCheckoutId {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Get checkout id');
    constructor(public readonly payload: {order_id: string, amount: string}) {}
}

export class GetCheckoutIdSuccess {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Get checkout id success');
    constructor(public readonly payload: {res: IFCCheckoutResponse, order_id: string}) {}

}
export class GetCheckoutIdFail {
    static readonly type = Utils.Helpers.Type('[Service: Speed-policy] Get checkout id fail');
    constructor(public readonly payload: any) {}
}


export class RevokeMigration {
	static readonly type = Utils.Helpers.Type('[Services: migration] Revoke level migration');
    constructor(public readonly payload: {product_id: string, service_id: string}) {}
}
export class RevokeMigrationSuccess {
	static readonly type = Utils.Helpers.Type('[Services: migration] Revoke level migration success');
    constructor(public readonly payload: any) {}
}
export class RevokeMigrationFail {
	static readonly type = Utils.Helpers.Type('[Services: migration] Revoke level migration fail');

    constructor(public readonly payload: any) {}
}
