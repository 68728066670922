import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  AxiomAuthResponse,
  TLSDecryptedResponse,
  TLSEncryptedResponse,
} from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient, private store: Store) {}

  sessionIdAuth(sessionId: string): Observable<AxiomAuthResponse> {
    const url = `${environment.DIGITAL_IDENTITY}/authenticate/login/${sessionId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<AxiomAuthResponse>(url, httpOptions);
  }

  auth(email: string, password: string): Observable<AxiomAuthResponse> {
    const url = `${environment.DIGITAL_IDENTITY}/authenticate`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const body = {
      auth: {
        email,
        password,
      },
    };

    return this.http.post<AxiomAuthResponse>(
      url,
      JSON.stringify(body),
      httpOptions
    );
  }

  getEncryptedTLSInfo(): Observable<TLSEncryptedResponse> {
    const url = environment.TLSEncryption;

    return this.http.get<TLSEncryptedResponse>(url);
  }

  authUserByIdentifier(identifiers: string): Observable<TLSDecryptedResponse> {
    const url = `${environment.AXIOM_RAINGO}/auth/decoded`;

    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;
    let httpOptions;

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: apiKey,
      }),
    };
    const body = {
      identifiers,
    };

    return this.http.post<TLSDecryptedResponse>(url, body, httpOptions);
  }
}
