<header #header class="header" [class.hidden]="!(isSignedIn$ | async) && !(whoamiNickname$ | async)">
  @if ((isSignedIn$ | async)) {
    <div class="message-container">
      <app-message-bar></app-message-bar>
    </div>
    @if (homeViewModel?.servicePairs) {
      <div class="heading">
        @if (homeViewModel?.nickname?.length || homeViewModel?.user?.individualIdentified?.name?.split(' ')?.[0]) {
          <h1 class="hi">hi <strong>{{ getName(homeViewModel?.nickname?.length ? homeViewModel?.nickname :
          (homeViewModel?.user?.individualIdentified?.name?.split(' ')?.[0]) ?? 'mobile') }}</strong></h1>
        } @else {
          <h1 class="hi">my <strong>mobile</strong></h1>
        }
      </div>
    }
    @if (!homeViewModel.homeLoader && !homeViewModel?.isFormattingServices && !swipingLoading) {
      @if (homeViewModel?.servicePairs?.length) {
        <div #dataContainer class="data-container"
          [style.transform]="dataContainerTranslateX" [draggable]="homeViewModel?.servicePairs?.length > 1">
          @for (servicePair of homeViewModel?.servicePairs; track servicePair) {
            <div class="service-pair"
              [style.width]="servicePairWidth">
              @for (service of servicePair; track service) {
                <div class="services">
                  <div class="top-row">
                    <p class="msisdn"><strong>{{service?.msisdn}}</strong> {{service.sim_name}}</p>
                    @if (whoamiMsisdn === service?.msisdn) {
                      <span><svg width="8" height="14"
                        viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V12C0.5 12.8284 1.17157 13.5 2 13.5H6C6.82843 13.5 7.5 12.8284 7.5 12V2C7.5 1.17157 6.82843 0.5 6 0.5Z"
                          fill="#0077C8" stroke="#0077C8" />
                        <circle cx="0.75" cy="0.75" r="0.75" transform="matrix(1 0 0 -1 3.25 13)"
                          fill="white" />
                      </svg>
                    </span>
                  }
                  @if (checkIfSimAssigned(service?.msisdn)) {
                    <span>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1679_12438)">
                          <path
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                            fill="#0077C8" stroke="#0077C8" stroke-linecap="round"
                            stroke-linejoin="round" />
                          <circle cx="7" cy="12.9004" r="4.5" fill="white" stroke="white" />
                          <path
                            d="M6.99922 8.90078C8.82176 8.90078 10.2992 7.42332 10.2992 5.60078C10.2992 3.77824 8.82176 2.30078 6.99922 2.30078C5.17668 2.30078 3.69922 3.77824 3.69922 5.60078C3.69922 7.42332 5.17668 8.90078 6.99922 8.90078Z"
                            fill="white" stroke="#0077C8" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1679_12438">
                            <rect width="14" height="14" rx="7" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  }
                </div>
                <div class="balance-lockup">
                  <div class="walletValue">
                    <p><strong>{{service?.balances?.data}}</strong> GB</p>
                  </div>
                  <div class="walletValue">
                    <p><strong>{{service?.balances?.voice?.minutes}}</strong>m<strong>{{service?.balances?.voice?.seconds}}</strong>s
                  </p>
                </div>
                <div class="walletValue">
                  <p><strong>{{service?.balances?.sms}}</strong> sms</p>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
} @else {
  <div class="loader-container">
    <app-loading-circle></app-loading-circle>
  </div>
}
@if (!homeViewModel.homeLoader && !homeViewModel?.isFormattingServices && !swipingLoading) {
  @if (homeViewModel?.servicePairs?.length > 1) {
    <div class="handle">
      @for (servicePairs of homeViewModel?.servicePairs; track servicePairs; let i = $index) {
        <span class="index index-{{i}}"
                    (click)="setActiveIndex(i)" [ngClass]="{
                        'active': i == activeIndex
                    }"></span>
      }
    </div>
  }
}
<ng-template #loader>
  <div class="loader-container">
    <app-loading-circle></app-loading-circle>
  </div>
</ng-template>
} @else {
  @if ((whoamiNickname$ | async) && (mobileSvcs$| async); as mServices) {
    <div class="message-container">
      <app-message-bar></app-message-bar>
    </div>
    <div class="heading">
      <h1 class="hi">hi <strong>there</strong></h1>
    </div>
    @if (!homeViewModel.homeLoader && !homeViewModel?.isFormattingServices) {
      @if (filterSvcsbyWhoaimiMsisdn(mServices)?.length) {
        <div #dataContainer class="data-container"
          [style.transform]="dataContainerTranslateX"
          [draggable]="filterSvcsbyWhoaimiMsisdn(mServices).length > 1">
          <div class="service-pair" [style.width]="servicePairWidth">
            @for (service of filterSvcsbyWhoaimiMsisdn(mServices); track service) {
              <div class="services">
                <div class="top-row">
                  <p class="msisdn"><strong>{{service?.msisdn}}</strong> {{service.sim_name}}</p>
                  @if (primaryMsisdn === service?.msisdn) {
                    <span><svg width="8" height="14"
                      viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V12C0.5 12.8284 1.17157 13.5 2 13.5H6C6.82843 13.5 7.5 12.8284 7.5 12V2C7.5 1.17157 6.82843 0.5 6 0.5Z"
                        fill="#0077C8" stroke="#0077C8" />
                      <circle cx="0.75" cy="0.75" r="0.75" transform="matrix(1 0 0 -1 3.25 13)"
                        fill="white" />
                    </svg>
                  </span>
                }
              </div>
              <div class="balance-lockup">
                <div class="walletValue">
                  <p><strong>{{service?.balances?.data}}</strong> GB</p>
                </div>
                <div class="walletValue">
                  <p><strong>{{service?.balances?.voice?.minutes}}</strong>m<strong>{{service?.balances?.voice?.seconds}}</strong>s
                </p>
              </div>
              <div class="walletValue">
                <p><strong>{{service?.balances?.sms}}</strong> sms</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
} @else {
  <div class="loader-container">
    <app-loading-circle></app-loading-circle>
  </div>
}
}
}

</header>