import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { HomeViewModel } from '../../interfaces/home-view-model.interface';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { CoreState } from 'src/app/store/Core/core.state';
import { Observable, Subject, fromEvent, of, takeUntil } from 'rxjs';
import { ServiceObject, TLSDecryptedResponse } from 'src/app/interfaces/interfaces';
import { Utils } from '@Utils';
import { AuthWithTLS } from 'src/app/store/Core/actions/core.actions';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { SignOut } from 'src/app/store/Auth/actions/auth.actions';

@Component({
  selector: 'app-shop-header',
  templateUrl: './shop-header.component.html',
  styleUrls: ['./shop-header.component.scss']
})
export class ShopHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren("header") header: QueryList<ElementRef>;
  @Input() homeViewModel: HomeViewModel;
  @Select(AuthState.isAuthed) isSignedIn$: Observable<boolean>;
  @Select(CoreState.isFormattingServices) isFormattingServices$: Observable<boolean>;
  @Select(CoreState.getDecodedTLSCredentials) decodedCredentials$: Observable<TLSDecryptedResponse>;
  @Select(CoreState.getMobileServices) mobileSvcs$: Observable<ServiceObject[]>;
  @Select(AssignSimState.GetStage) stage$: Observable<string>;
  touchStart: number;
  touchEnd: number;
  servicePairWidth: string;
  dataContainerTouchMove: () => void;
  dataContainerTouchEnd: () => void;
  dataContainerTranslateX: string = '0px'
  activeIndex: number = 0
  containerWidth: any;
  servicePairCount: number;
  showIndex: boolean = false;
  ngDestroy = new Subject<null>
  dataContainerDragEnd:any;
  dataContainerDragMove:any;
  displayServices: boolean;

  firstTimeLoaded: boolean;
  swipingActive: boolean = false;
  swipingLoading: boolean = false;
  public whoamiMsisdn: string;
  public primaryMsisdn: string;
  public whoamiNickname$: Observable<string>;
  public whoamiImsi: string;
  isSignedIn: boolean;

  constructor(
    private store: Store,
    private renderer2: Renderer2,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.swipingActive = false;
    this.swipingLoading = false;
    this.firstTimeLoaded = false;
    this.showIndex = false;
    this.isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
    
    this.store.select(CoreState.getDecodedTLSCredentials).subscribe({
      next: (res) => {
        if(res) this.getIdentifiedService(res);
      }
    });
  
    this.actions.pipe(
      ofActionCompleted(SignOut, AuthWithTLS)
    ).subscribe({
      next: () => {
        this.unlisten();
        this.getIdentifiedService(this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse);
      }
    });
  }
  ngAfterViewInit(): void {
    this.getIdentifiedService(this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse);
    
    this.header.changes?.pipe(
      takeUntil(this.ngDestroy)
    ).subscribe((result) => {
      this.isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);

      if (this.firstTimeLoaded || !result) {
        this.unlisten();
      }
      
      if (result && this.homeViewModel?.servicePairs?.length && this.isSignedIn) {
        this.initializeServiceContainerSwiping()
      }
    })

    this.store.select(CoreState.isFormattingServices)
    .pipe(
      takeUntil(this.ngDestroy)
    ).subscribe((isFormattingServices) => {
      this.isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
      this.unlisten();
      this.setWidths();
      if (!isFormattingServices && this.homeViewModel?.servicePairs?.length &&  this.isSignedIn) {
        this.initializeServiceContainerSwiping();
      }
    })

    this.store.select(ClientModeState.GetClientMode)
    .pipe(
      takeUntil(this.ngDestroy)
    ).subscribe(() => {
      this.isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
      this.unlisten();
      this.setWidths();
      if (this.homeViewModel?.servicePairs?.length &&  this.isSignedIn) {
        this.initializeServiceContainerSwiping();
      }
    })

    fromEvent(window, 'resize')?.pipe(
      takeUntil(this.ngDestroy)
    ).subscribe(() => {
      this.setWidths();
    })

    this.firstTimeLoaded = true;
  }

  initializeServiceContainerSwiping() {
    this.swipingLoading = true;
    setTimeout(()=> {
      this.unlisten();
      this.setWidths();
      this.servicePairCount = this.homeViewModel?.servicePairs?.length;
      this.dataContainerDragMove = this.renderer2?.listen(this.header?.first?.nativeElement, 'dragstart', (event) => this.handleDragStart(event));
      this.dataContainerDragEnd = this.renderer2.listen(this.header?.first?.nativeElement, 'dragend', (event) => this.handleDragEnd(event))
      this.dataContainerTouchMove = this.renderer2.listen(this.header?.first?.nativeElement, 'touchstart', (event) => this.handleTouchStart(event));
      this.dataContainerTouchEnd = this.renderer2.listen(this.header?.first?.nativeElement, 'touchend', (event) => this.handleTouchEnd(event));

      this.swipingActive = true;
      this.swipingLoading = false;
    }, 600)

  }
  handleDragStart(event: DragEvent) {
    this.disablePointerEvents();
    const img = new Image();
    img.src = "";
    event?.dataTransfer?.setDragImage(img, 0, 0);
    this.touchStart = event?.offsetX;
  }

  disablePointerEvents() {
    this.renderer2.setStyle(document.body, 'pointer-events', "none"); 
  }
  
  handleDragEnd(event: DragEvent) {
    this.touchEnd = event?.offsetX;
    this.updateActiveIndex(this.touchStart, this.touchEnd);
    this.touchStart = 0;
    this.touchEnd = 0;
    this.renderer2.removeStyle(document.body, 'cursor');
    this.renderer2.removeStyle(document.body, 'pointer-events');
  }

  handleTouchStart(event: TouchEvent) {
    this.touchStart = event?.changedTouches?.[0]?.screenX;
  }

  handleTouchEnd(event: TouchEvent) {
    this.touchEnd = event?.changedTouches?.[0]?.screenX;
    this.updateActiveIndex(this.touchStart, this.touchEnd);
    this.touchStart = 0;
    this.touchEnd = 0;
  }

  setWidths() {
    this.containerWidth = this.header?.first?.nativeElement?.clientWidth;
    this.servicePairWidth = `${this.containerWidth - 24}px`;
    this.dataContainerTranslateX = `translateX(-${this.activeIndex * this.containerWidth}px)`
    this.displayServices = true;
  }

  updateActiveIndex(touchStart: number, touchEnd: number) {
    if (touchStart > touchEnd) {
      const onLastIndex = this.activeIndex++ < this.servicePairCount - 1;
      const move = onLastIndex ? 
        this.activeIndex++ : 
        0;
      this.activeIndex = move;
    }
    
    if (touchStart < touchEnd) {
      const onFirstIndex = this.activeIndex === 0;
      const move = onFirstIndex ? 
        this.servicePairCount - 1 : 
        this.activeIndex - 1;
      
      this.activeIndex = move;
    }

    this.dataContainerTranslateX = `translateX(-${this.activeIndex * this.containerWidth}px)`;
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
    this.dataContainerTranslateX = `translateX(-${this.activeIndex * this.containerWidth}px)`;
  }

  unlisten() {
    if(this.header && this.swipingActive){
      this.setActiveIndex(0);
      this.dataContainerDragMove();
      this.dataContainerDragEnd();
      this.dataContainerTouchMove();
      this.dataContainerTouchEnd();
    }
  }

  public getIdentifiedService(dCreds: TLSDecryptedResponse) {
    if(!dCreds) return;
    if(dCreds.udg.imsi.substring(0, 5) === "65573") return;
    
    const creds = Utils.Helpers.decodeJWT(dCreds.tokenCredentialDto?.tokenCredential);
    
    this.whoamiImsi = dCreds.udg.imsi;
    this.whoamiNickname$ = of(dCreds.tokenCredentialDto.nickname);
    this.whoamiMsisdn = dCreds.udg.msisdn;
    this.primaryMsisdn = creds.sub;
  }

  public logger(data: any, key: string = 'test') {
    return data;
  }

  public filterSvcsbyWhoaimiMsisdn(svcs: ServiceObject[]): ServiceObject[] {
    return svcs.filter((svc) => svc.msisdn === this.whoamiMsisdn);
  }

  public getName(name:string) {
    if(localStorage.getItem('reg_first_name')) return localStorage.getItem('reg_first_name');

    return name;
  }

  public checkIfSimAssigned(msisdn: string) {
    const assignedSimList = this.store.selectSnapshot(CoreState.GetAssignedSimsList);
    if(assignedSimList && assignedSimList.length > 0 ) {
      const isSimAssigned = assignedSimList.find((as) => as.msisdn === msisdn);
      if(isSimAssigned) return isSimAssigned;

    }
    return null;
  }

  ngOnDestroy(): void {
      this.unlisten();
      this.ngDestroy.next(null)
      this.ngDestroy.complete()
  }

}