import { Utils } from '@Utils';
import { IApiResponse } from 'src/app/interfaces/api-response.inteface';
import { IDeviceInfo } from 'src/app/interfaces/device-info.interface';
import { ServiceObject } from 'src/app/interfaces/interfaces';

export class SetSelectedService {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Set selected service');
	constructor(public readonly payload: ServiceObject) {}
}

export class SetSelectedServiceById {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Set selected service by id');
	constructor(public readonly payload: string) {}
}

export class GetDeviceInfo {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Get services device info');
	constructor(public readonly payload: ServiceObject) {}
}

export class GetDeviceInfoSuccess {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Get services device info success');
	constructor(public readonly payload: {
		id: string,
		result: IApiResponse<IDeviceInfo>
	}) {}
}

export class GetDeviceInfoFail {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Get services device info fail');
	constructor(public readonly payload: IApiResponse<any>) {}
}

export class ShowSimDetails {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] Show sim details');
}

export class SetPageTitle {
	static readonly type = Utils.Helpers.Type('[Dashboard: Page Title] Set page title');
	constructor(public readonly payload: string) {}
}

export class SetSelectedSpeedChange {
	static readonly type = Utils.Helpers.Type('[Dashboard: Speed-Change] Set selected speed change');
	constructor(public readonly payload: string) {}
}

export class UnselectSelectedSvc {
	static readonly type = Utils.Helpers.Type('[Dashboard: Services] UnSet selected service');
}