import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'raingo-toggle-button',
  standalone: true,
  imports: [],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss'
})
export class ToggleButtonComponent {
  @Input() checked: boolean = false;
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();
}
