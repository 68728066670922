<div class="sheet-content-container" [ngClass]="{'full-width': fullWidth}">
  <div class="items">
    <div class="handle" draggable="true" (click)="onClick()">
      <span></span>
    </div>

    @if (loading) {
      <div class="bottomSheetLoader">
        <span class="loadingAnimation"></span>
      </div>
    }

    @if(!hasNoTitle) {
      <div class="bottomSheetHeader">
        @if (!loading) {
          <ng-content select="[Header]"></ng-content>
        }
      </div>
    }

    <div class="bottomSheetContent">
      @if (!loading) {
        <ng-content select="[Content]"></ng-content>
      }
    </div>
  </div>
</div>