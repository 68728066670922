import { NgModule } from '@angular/core';
import { PeachWidgetComponent } from './peach-widget.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [PeachWidgetComponent],
    imports: [CommonModule],
    exports: [PeachWidgetComponent],
    providers: [],
})
export class PeachWidgetModule { }
