import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Select, Selector, Store } from '@ngxs/store';
import { TopupState } from 'src/app/store/topup/topup.state';
import { SetBuyMoreMsisdn, TopUp } from 'src/app/store/topup/actions/topup.actions';
import { CoreState } from 'src/app/store/Core/core.state';
import { Observable } from 'rxjs';
import { ClientDetails, TLSDecryptedResponse, TopUpOption } from 'src/app/interfaces/interfaces';
import { TopupActions } from 'src/app/store/topup/actions';
import { Utils } from '@Utils';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { RemoveFreeGigSVCID } from 'src/app/store/Core/actions/core.actions';
import { Navigate } from '@ngxs/router-plugin';
import { SetPageTitle } from 'src/app/pages/Dashboard/store/actions/dashboard.actions';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

class SelectTopupNumberSelector {
  @Selector([AuthState.isAuthed])
  static isSignedIn(isAuthed: boolean) {
    const canSignIn: boolean = isAuthed;
    return {
      canSignIn
    }
  }
}

interface customer {
  sim_name: string;
  msisdn: string;
  id: string;
}

@Component({
  selector: 'app-select-top-up-number',
  templateUrl: './select-top-up-number.component.html',
  styleUrls: ['./select-top-up-number.component.scss'],
})
export class SelectTopUpNumberComponent implements OnInit, OnDestroy {
  @Select(SelectTopupNumberSelector.isSignedIn) isSignedIn$! : Observable<ClientDetails>
  @Select(CoreState.getClientDetails) ClientDetails$! : Observable<ClientDetails>
  @Select(TopupState.getError) error$? : Observable<boolean>
  @Select(TopupState.getErrorMessage) errorMessage$? : Observable<boolean>;
  @Select(TopupState.getSelectedTopup) selectedTopUp$: Observable<TopUpOption>;

  @Select(TopupState.loading) loading$? : Observable<boolean>

  @ViewChild('numberInput') numberInput?: ElementRef;
  @ViewChild('topupOptions') topupOptionsContainer?: ElementRef;

  isFocused: boolean = false

  attemptedToSubmit: boolean = false;

  addSelected: boolean = false;

  selectedCustomer: customer = { sim_name:'', msisdn:'', id: '' };
  hasError?: boolean;

  customers: any[] = this.store.selectSnapshot(CoreState.getTopupOptions)
  anotherNumberForm: FormGroup
  nameInputOpen: boolean;
  preselectedTopup: boolean
  whoamiMsisdn: any;
  public isDisabled: boolean = true;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<SelectTopUpNumberComponent>,
    private formBuilder: FormBuilder,
    private store: Store,
    private readonly ref: ChangeDetectorRef,
  ) {}

  
  ngOnInit(): void {
    this.getIdentifiedService(this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse);

    this.setForm() 
    this.selectedCustomer = { sim_name:'', msisdn:'', id: '' };

    setTimeout(() => {
      this.numberInput?.nativeElement.focus();
    }, 100);

    this.anotherNumberForm.get('number')?.valueChanges.subscribe({
      next: (res: string) => {
        
        if(res.length === 10) {
          this.isDisabled = res[0] != '0' || res[1] === res[0];
        } else if(res.length === 11) {
          this.isDisabled = !res.startsWith('27');
        } else {
          this.isDisabled = true;
        }

        this.ref.detectChanges();
      }
    });

    document.addEventListener("keyup", ({key}) => {
      if (key === 'Enter') { 
        if(!this.isDisabled) this.topUp()
      }
    });
  }

  selectCustomer(customer:customer) {
    this.selectedCustomer = customer;
    this.addSelected = false;
    this.anotherNumberForm.get('number')?.setValue("")
    this.anotherNumberForm.get('name')?.setValue("")
    this.preselectedTopup = true;
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
  }

  openNameInput() {
    this.nameInputOpen = true;
  }

  setForm() {
    this.anotherNumberForm = this.formBuilder.group({
      number: ['', [
        Validators.compose([Validators.required, Validators.pattern("/^[0-9\s]*$/"), Validators.minLength(10), Validators.maxLength(11)]),
      ]],
      name: ['']
    })
  }

  public selectAddNumberOption() {
    this.selectedCustomer = { sim_name:'', msisdn:'' , id: ''};
    this.preselectedTopup = false;
    this.addSelected = true
    setTimeout(() => {
      this.numberInput!.nativeElement.focus()
    }, 150);
  }

  public topUp() {
    const { number, name } = this.anotherNumberForm.getRawValue() ?? {};
    
    this.attemptedToSubmit = true;
    const services = this.store.selectSnapshot(CoreState.getMobileServices) ?? [];
    const formatNumber = number?.replace(/\s+/g, '')
    const topupNumber = formatNumber;
    const service = services?.find(service => service.msisdn === topupNumber);
    const selectedTopUp = this.store.selectSnapshot(TopupState.getSelectedTopup);
    
    if ( !topupNumber || topupNumber?.length < 10 ) return;

    // if (selectedTopUp?.amount === 0 && service) { 
    //   const isService5G = Boolean(service?.msisdn.length > 11);
    //   const buckets = this.store.selectSnapshot(CoreState.GetBuckets);
    //   const serviceBuckets = buckets[service?.id as string];
    //   const bucketName = isService5G ? '5G-Prepaid': serviceBuckets.find((b) => b.name.includes('data0'))?.name;
      
    //   this.store.dispatch(new TopupActions.TopUpFree(selectedTopUp, topupNumber, bucketName as string, name, service));
    //   return
    // } 
    const fmtdNumber = [topupNumber.slice(0, 3), " ", topupNumber.slice(3,6), " ", topupNumber.slice(6)].join('');
    this.store.dispatch([new SetBuyMoreMsisdn({msisdn: topupNumber}), new SetPageTitle(fmtdNumber), new Navigate([`services/${topupNumber}/buy-more`]), new CloseBottomSheet()]);
    
    // this.redirectToPaymentPage(selectedTopUp, topupNumber, name);
    return

  }

  public redirectToPaymentPage(selectedTopUp: TopUpOption, topupNumber: string, name?: string) {
    this.store.dispatch(new TopUp({option: selectedTopUp, msisdn: topupNumber, name}));
  }

  public filterMobileNumbers(customers: customer[]) {
    const isSignedIn = this.store.selectSnapshot(SelectTopupNumberSelector.isSignedIn)?.canSignIn;
    const selectedTopUp = this.store.selectSnapshot(TopupState.getSelectedTopup);

    if(selectedTopUp && selectedTopUp?.amount === 0) {
      const elServicIDs = this.store.selectSnapshot(CoreState.GetFreeGIGEligbleSvcs);
      const filteredCustomers = customers?.filter((c) => elServicIDs?.includes(c.id))
      
      return filteredCustomers;
    }
    if(!isSignedIn) return customers?.filter((c) => (c.msisdn.length <= 11 && c.msisdn === this.whoamiMsisdn)) ?? [];
    
    const filteredCustomers = customers?.filter((c) => c.msisdn.length <= 11) ?? [];
    return filteredCustomers;
  }

  public getIdentifiedService(dCreds: TLSDecryptedResponse) {
    if(!dCreds) return;
    
    const creds = Utils.Helpers.decodeJWT(dCreds.tokenCredentialDto?.tokenCredential);
    this.whoamiMsisdn = creds.sub;
  }


  ngOnDestroy(): void {
    if (!this.store.selectSnapshot(CoreState.checkoutSelected)) {
      this.store.dispatch(TopupActions.ClearTopUpDetails)
    }
  }

}
