import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DetailedService } from 'src/app/interfaces/services.interface';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-speed-down-success-sheet',
  templateUrl: './speed-down-success-sheet.component.html',
  styleUrl: './speed-down-success-sheet.component.scss'
})
export class SpeedDownSuccessSheetComponent {
  @Select(DashboardState.GetSpeedUpConfig) speedUpConfig$: Observable<{
    canChangeSpeed: boolean,
    currentSpeed: string,
    selectedSpeed: string
}>;
  @Select(DashboardState.GetSelectedService) selectedSvc$: Observable<DetailedService>
  
  constructor(private readonly store: Store) {}

  public formatSpeedUpName(name: string) {
    if(name?.includes('30')) return '30Mbps';
    if(name?.includes('60')) return '60Mbps';
    if(name?.toLocaleLowerCase().includes('level up ')) return name.replace('Level up ', '');

    return name;
  }

  public dismiss() {
    return this.store.dispatch(new CloseBottomSheet());
  }
}
