
<app-bottom-sheet-content-template>

    <h1 Header><strong>assign a SIM</strong> request</h1>
    
    <div Content>
        <div class="sender-details">
            <p>from: <strong>Jack</strong></p>
            <p>for number: <strong>081 610 1000</strong></p>
        </div>
        <div class="info-lockup">
            <p>If you accept, they’ll be able to create their own rain account, add their own payment details & control their own top ups</p>
        </div>
        <div class="button-container">
            <button class="solid" (click)="accept()">accept</button>
            <button class="transparent" (click)="deny()">deny</button>
        </div>
    </div>

</app-bottom-sheet-content-template>