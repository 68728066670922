import { Component, OnDestroy } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClearMigrations } from 'src/app/pages/Dashboard/store/actions/level-up.actions';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-level-up-success-sheet',
  templateUrl: './level-up-success-sheet.component.html',
  styleUrl: './level-up-success-sheet.component.scss'
})
export class LevelUpSuccessSheetComponent implements OnDestroy {
  @Select(DashboardState.GetMigrationsConfig) migrationsConfig$: Observable<any>;
  
  constructor(private readonly store: Store) {}

  public accept() {
    this.store.dispatch([new CloseBottomSheet(), new ClearMigrations(), new Navigate(['/services'])]);
  }

  ngOnDestroy(): void {

  }
}
