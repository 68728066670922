import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentOptionsComponent } from './payment-options.component';
import { PaymentOptionsRoutingModule } from './payment-options-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PeachWidgetModule } from './components/peach-widget/peach-widget/peach-widget.module';
import { SafePipe } from 'src/app/pipes/safe.pipe';



@NgModule({
  declarations: [
    PaymentOptionsComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    PaymentOptionsRoutingModule,
    SharedModule,
    PeachWidgetModule
  ]
})
export class PaymentOptionsModule { }
