
@if(migrationsConfig$ | async; as config) {
<app-bottom-sheet-content-template>

    <h1 Header>
        @if(config.config.type === 'up') {
            level up <strong>successful</strong>
        } @else {
            level change <strong>successful</strong>
        }
    </h1>
    
    <div Content>
        <div class="info-lockup">
        @if(config.config.type === 'up') {
            <p>Your extra gigs & minutes have already been added. Your next monthly bill will increase by <strong>R{{config.config.amount}}</strong>.</p>
        } @else {
            <p>This change will take effect from your next payment date. Your monthly bill will decrease by <strong>R{{config.config.amount}}</strong>.</p>
        }
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>
    
</app-bottom-sheet-content-template>
}