import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Utils } from '@Utils';
import { Observable } from 'rxjs';
import { RainOneProduct } from 'src/app/interfaces/product.interface';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { ChangeServiceSpeed } from 'src/app/store/Core/actions/service.actions';
import { TopupActions } from 'src/app/store/topup/actions';
import { TopupState } from 'src/app/store/topup/topup.state';

@Component({
  selector: 'raingo-speed-up-approval-sheet',
  templateUrl: './speed-up-approval-sheet.component.html',
  styleUrls: ['./speed-up-approval-sheet.component.scss']
})
export class SpeedUpApprovalSheetComponent implements OnInit, OnDestroy {
  @Select(TopupState.getSelectedTopup) sTopUp$: Observable<RainOneProduct>;
  @Select(DashboardState.GetSpeedUpConfig) speedUpConfig$: Observable<{
    canChangeSpeed: boolean,
    currentSpeed: string,
    selectedSpeed: string
}>;
  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    
  }
  public close() {
    this.store.dispatch([new CloseBottomSheet, new TopupActions.ClearTopUpDetails]);
  }

  public getProRataPrice(amount: string, config: any) {
    const sTopUp = this.store.selectSnapshot(TopupState.getSelectedTopup);
    let currentAmount = 0;

    if(config.currentSpeed.includes('60')) currentAmount = 200;
    else if(config.currentSpeed.includes('1000')) currentAmount = 400;

    const bCycleDate = this.store.selectSnapshot(AuthState.GetBillCycleDate).toDateString();

    return Utils.Helpers.CalcProRata(bCycleDate, (parseInt(amount) - currentAmount).toString())
  }

  public formatSpeedUpName(name: string) {
    if(name?.includes('30')) return '30Mbps';
    if(name?.includes('60')) return '60Mbps';

    if(name?.toLocaleLowerCase().includes('level up ')) return name.replace('Level up ', '');

    return name;
  }

  public continuePurchase(name: string) {
    this.store.dispatch(new ChangeServiceSpeed({speed: this.formatSpeedUpName(name)}))
  }

  public calcDiff(currentSpeed: string, selectedSpeed: any) {
    let price: number = 400;
    if(currentSpeed.includes('30')) {
      price = 0;      

    } else if(currentSpeed.includes('60')) {
      price = 200;
    }
    
     return selectedSpeed.recurringPrice - price;
  }

  ngOnDestroy(): void {
  }
}
