<app-bottom-sheet-content-template>
  <h1 Header><strong>create</strong> rain account</h1>

  <div Content>
    <form class="form-container" [formGroup]="registerForm">
      <div class="input-container">
        <input id="firstName" type="text" [formControlName]="'firstName'" placeholder="first name">
        @if (registerForm.get('firstName')?.errors && registerForm.get('firstName')?.dirty && attemptedRegister) {
          <p
            >
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid first name
            </span>
          </p>
        }
      </div>
      <div class="input-container">
        <input id="lastName" type="text" [formControlName]="'lastName'" placeholder="last name">
        @if (registerForm.get('lastName')?.errors && registerForm.get('lastName')?.dirty && attemptedRegister) {
          <p
            >
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid last name
            </span>
          </p>
        }
      </div>
      <div class="input-container">
        <input id="email" type="text" [formControlName]="'email'" placeholder="email">
        @if (registerForm.get('email')?.errors && registerForm.get('email')?.dirty && registerForm.get('email')?.value !== '') {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>please enter</b> a valid email
            </span>
          </p>
        }
      </div>
      <div class="input-container">
        <input id="email" type="text" [formControlName]="'id_number'" placeholder="ID number/passport number">
        @if (registerForm.get('id_number')?.errors && registerForm.get('id_number')?.dirty) {
          <p>
            <img src="assets/images/error-icon.png" alt="error">
            @if (registerForm.get('id_number')?.errors['exists']) {
              <span>
                <b>id number</b> already exists
              </span>
            } @else {
              <span>
                <b>please enter</b> a valid id number
              </span>
            }
          </p>
        }
      </div>
      <div class="input-container">
        <input id="password" type="password" [formControlName]="'password'" placeholder="password">
        @if (registerForm.get('password')?.errors && registerForm.get('password')?.dirty && attemptedRegister) {
          <p
            >
            <img src="assets/images/error-icon.png" alt="error">
            <span>
              <b>password</b> must be at least 8 characters, and include a minimum of one uppercase character
              and one number
            </span>
          </p>
        }
      </div>
      <div class="input-container">
        <input id="rainNumber" type="string" [formControlName]="'rainNumber'">
        @if (registerForm.get('rainNumber')?.errors && registerForm.get('rainNumber')?.dirty) {
          <p> <img
            src="assets/images/error-icon.png" alt="error"> <b>please enter</b> a valid number</p>
          }
        </div>
        <div class="input-container">
          <input ngx-gp-autocomplete  [options]="options"  (onAddressChange)="onAutocompleteSelected($event)" id="address" type="string" [formControlName]="'address'" [placeholder]="'enter home address'" #placesRef="ngx-places">
          @if (registerForm.get('address')?.errors && registerForm.get('address')?.dirty) {
            <p> <img
              src="assets/images/error-icon.png" alt="error"> <b>please enter</b> a home address</p>
            }
          </div>

          <div class="input-container checbox-field" [class.checked]="registerForm.get('terms')?.value">
            <mat-checkbox class="example-margin"  (change)="onChange($event)" [formControlName]="'terms'" [disableRipple]="true"></mat-checkbox>
            <span class="customLabel">Accept <a href="https://rain.co.za" target="_blank">Terms and Conditions</a></span>
          </div>

          <div class="input-container checbox-field" [class.checked]="registerForm.get('whatsapp')?.value">
            <mat-checkbox class="example-margin"  (change)="onChange($event)" [formControlName]="'whatsapp'" [disableRipple]="true"></mat-checkbox>
            <span class="customLabel">opt in to WhatsApp</span>
          </div>
          @if (genericMessage && genericMessage.show) {
            <div class="generic-message-container">
              <img src="assets/images/error-icon.png" alt="error">
              <p>
                {{genericMessage.message}}
              </p>
            </div>
          }
          <button class="register" (click)="register()" [disabled]="registerForm.invalid"
            [ngClass]="{'disabled': registerForm.invalid}">
            @if (!(loading$ | async)) {
              <p>
                create an account
              </p>
            }
            @if ((loading$ | async)) {
              <p class="pendingAnimation"></p>
            }
          </button>
        </form>
      </div>

    </app-bottom-sheet-content-template>