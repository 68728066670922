import { Component, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Select, Selector, Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { SignInComponent } from '../bottom-sheet-content-container/bottom-sheet-components/sign-in/sign-in.component';
import { Navigate } from '@ngxs/router-plugin';
import { CoreState } from 'src/app/store/Core/core.state';
import { Utils } from 'src/app/utils';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { Observable } from 'rxjs';
import { CoreActions } from 'src/app/store/Core/actions';
import { v4 as uuidv4 } from 'uuid';
import { SSOService } from 'src/app/services/sso.service';
import { AuthActions } from 'src/app/store/Auth/actions';
import { AuthState } from 'src/app/store/Auth/auth.state';

interface HeaderViewModel {
  canSignIn: boolean | null
}

class HeaderSelector {
  @Selector([AuthState.isAuthed])
  static viewModel(isAuthed: boolean) {
    const canSignIn: boolean = isAuthed;
    return {
      canSignIn
    }
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Select(HeaderSelector.viewModel) viewModel$?: Observable<HeaderViewModel>
  @Select(AuthState.isAuthed) isAuthed$: Observable<boolean>;
  homeUrl: string = environment.tokenUrl;
  public showTestPageMenuItem = !environment.production;

  constructor(
    private store: Store,
    private sso: SSOService
  ) {}

  goHome() {
    this.store.dispatch(new Navigate(['/']));
  }

  handleAuth() {
    if (this.store.selectSnapshot(AuthState.isAuthed)) {
      return this.signOut()
    } else {
      return this.openSheet()
    }
  }
  
  openSheet() {
    return this.store.dispatch(new BottomSheetActions.ShowBottomSheet('sign_in'))
  }

  signOut() {
    if(window.location.pathname.includes('services')) {
      this.store.dispatch(new Navigate(['/']));
    }
    return this.store.dispatch([new AuthActions.SignOut]);
  }

  goToTestPage() {
    return this.store.dispatch(new Navigate(['/dev-testing']));
  }

  public redirectToRain() {
    this.sso.createUrl().then((url) => {
      this.store.dispatch([new AuthActions.SignOut]);
      return window.open(url, '_self');
    })
  }

}
