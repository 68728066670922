import { PaymentStatuses } from "../services/topup.service";
import { RainOneProduct } from "./product.interface";
import { IServicePolicy } from "./services.interface";

export enum Statuses {
    None = 0,
    Success,
    NotFound,
    Error,
    Conflict,
    Invalid,
    Timeout
  }

export class Result<T> {

    static timeout<U>(message: string): Result<U> {
      const result = new Result<U>();
      result.status = Statuses.Timeout;
      result.message = message;
      return result;
    }
  
    static invalid<U>(message: string): Result<U> {
      const result = new Result<U>();
      result.status = Statuses.Invalid;
      result.message = message;
      return result;
    }
  
    static conflict<U>(message: string = "Conflict"): Result<U> {
      const result = new Result<U>();
      result.status = Statuses.Conflict;
      result.message = message;
      return result;
    }
  
    value!: T;
  
    status!: Statuses;
  
    message!: string;
  
    public static success<U>(value: U): Result<U> {
      const result = new Result<U>();
      result.status = Statuses.Success;
      result.message = "Success";
      result.value = value;
      return result;
    }
  
    public static error(message: string): Result<any> {
      const result = new Result();
      result.status = Statuses.Error;
      result.value = null;
      result.message = message;
      return result;
    }
  
    public static notFound(): Result<any> {
      const result = new Result();
      result.status = Statuses.NotFound;
      result.value = null;
      result.message = "Not Found";
      return result;
    }
  }

  export interface ClientDetails {
      "host": string,
      "x-request-id": string,
      "x-real-ip": string,
      "x-forwarded-host": string,
      "x-forwarded-port": string,
      "x-forwarded-proto": string,
      "x-scheme": string,
      "x-original-forwarded-for": string,
      "msisdn": string,
      "content-type": string,
      "user-agent": string,
      "accept": string,
      "postman-token": string,
      "accept-encoding": string
  }

  export interface DigitalIdentityClientObject {
    "id": string,
    "href": string,
    "creationDate": string,
    "lastUpdate": "2024-03-05T07:47:23.763Z",
    "nickname": string,
    "status": "Active" | "Inactive",
    "referredType": string,
    "validFor": {
      "startDateTime": "2024-03-05T07:47:23.763Z",
      "endDateTime": "2024-03-05T07:47:23.763Z"
    },
    "relatedParty"?: [
      {
        "id": string,
        "name": string,
        "href": string,
        "role": string,
        "@baseType": string,
        "@referredType": string,
        "@type": string,
        "@schemaLocation": "string"
      }
    ],
    "individualIdentified"?: {
      "id": string,
      "name": string,
      "href": string,
      "@baseType": string,
      "@referredType": string,
      "@schemaLocation": string,
      "@type": "string"
    },
    "resourceIdentified"?: [
      {
        "id": string,
        "name": string,
        "href": string,
        "@baseType": string,
        "@referredType": string,
        "@schemaLocation": string,
        "@type": string
      }
    ],
    "credential"?: [
      {
        "id": string,
        "href": string,
        "trustLevel": string,
        "state": string,
        "validFor": {
          "startDateTime": Date,
          "endDateTime": Date
        },
        "contactMedium": [
          {
            "id": string,
            "href": string,
            "contactType": string,
            "preferred": true,
            "validFor": {
              "startDateTime": Date,
              "endDateTime": Date
            },
            "suburb": string,
            "city": string,
            "province": string,
            "country": string,
            "email": string,
            "@baseType": string,
            "@referredType": string,
            "@schemaLocation": string,
            "@type": string,
            "medium_type": string,
            "street_name": string,
            "building_name": string,
            "floor_level": string,
            "unit_number": string,
            "postal_code": string,
            "phone_number": string
          }
        ],
        "@baseType": string,
        "@referredType": string,
        "@schemaLocation": string,
        "@type": string
      }
    ],
    "contactMedium"?: [
      {
        "id": string,
        "href": string,
        "contactType": string,
        "preferred": boolean,
        "validFor": {
          "startDateTime": Date,
          "endDateTime": Date
        },
        "suburb": string,
        "city": string,
        "province": string,
        "country": string,
        "email": string,
        "@baseType": string,
        "@referredType": string,
        "@schemaLocation": string,
        "@type": string,
        "medium_type": string,
        "street_name": string,
        "building_name": string,
        "floor_level": string,
        "unit_number": string,
        "postal_code": string,
        "phone_number": string
      }
    ],
    "attachment"?: [
      {
        "id": string,
        "name": string,
        "href": string,
        "content": string,
        "description": string,
        "mimeType": string,
        "size": string,
        "attachmentType": string,
        "validFor": {
          "startDateTime": Date,
          "endDateTime": Date
        },
        "url": string,
        "@referredType": string,
        "@schemaLocation": string,
        "@type": string,
        "@baseType": string
      }
    ],
    "partyRoleIdentified"?: [
      {
        "id": string,
        "name": string,
        "href": string,
        "role": string,
        "@baseType": string,
        "@referredType": string,
        "@type": string,
        "@schemaLocation": string
      }
    ],
    "user"?: {
      "email": string,
      "password": string,
      "phone": string,
      "first_name": string,
      "last_name": string,
      "id_number": string,
      "rica_address": {
        "id": string,
        "href": string,
        "contactType": string,
        "preferred": boolean,
        "validFor": {
          "startDateTime": Date
          "endDateTime": Date
        },
        "suburb": string,
        "city": string,
        "province": string,
        "country": string,
        "email": string,
        "@baseType": string,
        "@referredType": string,
        "@schemaLocation": string,
        "@type": string,
        "medium_type": string,
        "street_name": string,
        "building_name": string,
        "floor_level": string,
        "unit_number": string,
        "postal_code": string,
        "phone_number": string
      }
    },
    "loginId"?: string,
    "loginHash"?: string,
    "@baseType"?: string,
    "@schemaLocation"?: string,
    "@type"?: string
  }

  export function emptyClientDetails(): ClientDetails {
    return {
      "host": '',
      "x-request-id": '',
      "x-real-ip": '',
      "x-forwarded-host": '',
      "x-forwarded-port": '',
      "x-forwarded-proto": '',
      "x-scheme": '',
      "x-original-forwarded-for": '',
      "msisdn": '',
      "content-type": '',
      "user-agent": '',
      "accept": '',
      "postman-token": '',
      "accept-encoding": ''
    }
  }

  export interface RaingoPromoObject {
    active: boolean,
    promoTopup: TopUpOption,
    canBuy: boolean,
    imgUrl?: string,
    hasImage: boolean,
    landingPage?: {
      active: boolean,
      img: string
    }
  }

  export interface FetchRaingoPromoResponse {
    promo: RaingoPromoObject,
    'wifi-promo': RaingoPromoObject
  }

  export interface FetchRaingoConfigResponse {
    maintenanceModeOn: boolean
  }

  export interface WalletObject {
    data: string,
    voice: {
      minutes: string, 
      seconds: string
    },
    sms: string,
    int_dialing: string | null
  }

  export interface WalletBalance {
      "available": number,
      "expiry": string,
      "name": string,
      "quantity": number,
      "unit_type": string
  }  

  export interface BalancesResponse {
    result: {
      wallets: WalletBalance[]
    }
  } 


  export interface Bucket {
    id: string,
    name: string,
    description: string,
    shared: false,
    remainingValue: 0,
    remainingValueName: null,
    reservedValue: null,
    allocatedValue: null,
    status: null,
    usageType: string,
    validFor: {
        startDateTime: any
    },
    productId: null,
    logicalResource : {
      id: string,
    }
    partyAccountId: string
}
  export interface BalancesManagerResponse {
    buckets: Bucket[],
    freeGigServiceIds: string[],
    promoServiceIds: string[],
    products: {
      DATA: any[],
      PREPAID: any[],
      SMS: any[],
      VOICE: any[],
    }
  } 


  export function defaultWallet(): WalletObject {
    return {
      data: '',
      voice: {
        minutes: '', 
        seconds: ''
      },
      sms: '',
      int_dialing: null
    }
  }

export interface TopUpOption {
  id: string,
  amount: number, 
  extras?:{}, 
  type: 'DATA' | 'VOICE' | 'SMS' | 'VAS', 
  quantity: number, 
  unit?: 'MB' | 'GB' | 'MIN' | 'SMS' | 'Mbps' | 'level up', 
  color?: '#3392D3' | '#1170B0' | '#005FA0'
}

export interface TopUpConfig {
  data: TopUpOption[],
  minutes: TopUpOption[],
  sms: TopUpOption[],
}

export interface IPaymentStatusDetail {
  result: {
    completed: boolean;
    checkout: string;
    message: string;
    status: PaymentStatuses;
  }
}

export interface PaymentMethod {
    paymentMethodName: string,
    paymentImage?: string,
    paymentUrl: string,
    isMobile?: boolean,
    gtm_label?: string
}

export interface PaymentMethodRes {
    "checkout": string,
    "completed": boolean,
    "message": string,
    "status": null,
    "amount": any,
    "userId": string,
    "initiatePaymentRequest": {
        "messageId": string,
        "transmissionDateTime": string,
        "accountNumber": string,
        "accountType": string,
        "amount": 3000,
        "clientReference": string,
        "successUrl": string,
        "failedUrl": string,
        "cancelledUrl": string
    },
    "transmissionDateTime": string,
    "referenceKey": string,
    "networkTransactionID": any,
    "accountNumber": string,
    "appPaymentLinks": PaymentMethod[],
    "webPaymentLinks": {
            "paymentMethodName": string,
            "paymentUrl": string
        }[],
    "qrCodePayment": {
        "qrCodeUrl": string,
        "supportedApplications": string[]
    },
    "responseCode": string,
    "responseDescription": string
}

export interface AuthToken {
  token: string
}

export interface AssignUserPayload {
  auth : {
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    id_number: string,
    phone: string
  }
}
export interface GpsLocation {
  latitude: string;
  longitude: string;
}


export interface IAddress {
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  country?: string;
  buildingName: string;
  floorLevel: string;
  unitNumber: string;
  gps_coordinates?: GpsLocation
}
export interface RegistrationPayload {
  user : {
    first_name: string,
    last_name: string,
    name?: string,
    email: string,
    password: string,
    id_number: string,
    phone: string
    ricaAddress?: IAddress;
  }
}

export interface ServiceObject {
  user_id: string,
  id: string,
  iccid: string,
  sim_name: string,
  msisdn: string,
  account_type: string,
  balances: WalletObject,
  status: number,
  product_id: string,
  token?: string;
  product?: RainOneProduct,
  parent_service_id: string | null,
  type: "Fixed" | "Legacy" | "Mobile",
  policy?: IServicePolicy,
  international_access: "disabled" | "enabled",
  scheduled_actions: Array<{actionName: string, date: string}>;
  is5GPrepaid?: boolean
} 

export interface IGroupedService {
  category: "5G" | "4G"
  isLegacy: boolean;
  isParentSvc:  boolean;
  isPrepaid: boolean;
  serviceObject: ServiceObject;
  subServices: {[id: string]: ServiceObject}
}

export interface FiveGPrepaidService extends ServiceObject {
  bucket: Bucket
}

export interface FetchServicesResponse {
  response: ServiceObject[]
}

export type PurchaseStatus ="AWAITING_PAYMENT" | "PAYMENT_FAILED" | "PAYMENT_SUCCESS"

export interface PurchaseHistoryItem {
  "id": string,
  "href": string,
  "status":  PurchaseStatus,
  "type": string,
  "baseType": string,
  "schemaLocation": string,
  "userId": string,
  "msisdn": string,
  "productId": string,
  option?: TopUpOption,
  name?: string
}

export interface PaymentStatusResponse {
  
    "errors": any,
    "data": {
        "status": string,
        "message": string,
        "merchantTransactionId": string,
        "recordedDate": number
  }
}

export interface PaymentPayAtStatusResponse {
    "checkout":string,
    "completed": boolean,
    "message":string,
    "status":string ,
    "amount": any,
    "userId": any,
    "initiatePaymentRequest": any,
    "transmissionDateTime": any,
    "referenceKey": any,
    "networkTransactionID": any,
    "accountNumber": any,
    "appPaymentLinks": any,
    "webPaymentLinks": any,
    "qrCodePayment": any,
    "responseCode": any,
    "responseDescription": any
}

export interface TopupCheckoutResponse {
  checkout_id: string,
  checkout: {
    checkout: string,
    completed: boolean,
    message: string,
    status: string,
    referenceKey?: string
  }
}


export interface AxiomRegistrationResponse {
  id: string,
  href: string,
  creationDate: string,
  lastUpdate: string,
  nickname: string,
  status: string | "Active",
  referredType: string,
  validFor: {
    startDateTime: string,
    endDateTime: string
  },
  relatedParty: [
    {
      id: string,
      name: string,
      href: string,
      role: string,
      "@baseType": string,
      "@referredType": string,
      "@type": string,
      "@schemaLocation": string
    }
  ],
  individualIdentified: {
    id: string,
    name: string,
    href: string,
    "@baseType": string,
    "@referredType": string,
    "@schemaLocation": string,
    "@type": string
  },
  resourceIdentified: {
    id: string,
    name: string,
    href: string,
    "@baseType": string,
    "@referredType": string,
    "@schemaLocation": string,
    "@type": string
  },
  credential: [
    {
      id: string,
      href: string,
      trustLevel: string,
      state: string | "Active",
      validFor: {
        startDateTime: string,
        endDateTime: string
      },
      contactMedium: [
        {
          id: string,
          href: string,
          contactType: string,
          preferred: boolean,
          validFor: {
            startDateTime: string,
            endDateTime: string
          },
          suburb: string,
          city: string,
          province: string,
          country: string,
          email: string,
          "@baseType": string,
          "@referredType": string,
          "@schemaLocation": string,
          "@type": string,
          medium_type: string,
          street_name: string,
          building_name: string,
          floor_level: string,
          unit_number: string,
          postal_code: string,
          phone_number: string
        }
      ],
      "@baseType": string,
      "@referredType": string,
      "@schemaLocation": string,
      "@type": string
    }
  ],
  contactMedium: [
    {
      id: string,
      href: string,
      contactType: string,
      preferred: boolean,
      validFor: {
        startDateTime: string,
        endDateTime: string
      },
      suburb: string,
      city: string,
      province: string,
      country: string,
      email: string,
      "@baseType": string,
      "@referredType": string,
      "@schemaLocation": string,
      "@type": string,
      medium_type: string,
      street_name: string,
      building_name: string,
      floor_level: string,
      unit_number: string,
      postal_code: string,
      phone_number: string
    }
  ],
  attachment: [
    {
      id: string,
      name: string,
      href: string,
      content: string,
      description: string,
      mimeType: string,
      size: string,
      attachmentType: string,
      validFor: {
        startDateTime: string,
        endDateTime: string
      },
      url: string,
      "@referredType": string,
      "@schemaLocation": string,
      "@type": string,
      "@baseType": string
    }
  ],
  partyRoleIdentified: [
    {
      id: string,
      name: string,
      href: string,
      role: string,
      "@baseType": string,
      "@referredType": string,
      "@type": string,
      "@schemaLocation": string
    }
  ],
  user: {
    email: string,
    password: string,
    phone: string,
    first_name: string,
    last_name: string,
    id_number: string,
    rica_address: {
      id: string,
      href: string,
      contactType: string,
      preferred: boolean,
      validFor: {
        startDateTime: string,
        endDateTime: string
      },
      suburb: string,
      city: string,
      province: string,
      country: string,
      email: string,
      "@baseType": string,
      "@referredType": string,
      "@schemaLocation": string,
      "@type": string,
      medium_type: string,
      street_name: string,
      building_name: string,
      floor_level: string,
      unit_number: string,
      postal_code: string,
      phone_number: string
    }
  },
  loginId: string,
  loginHash: string,
  "@baseType": string,
  "@schemaLocation": string,
  "@type": string
}

export interface AxiomAuthResponse {
  login: string,
  tokenCredential: string,
  nickname: string,
  token: string,
  tokenSme?: string,
  "@type": string,
  "@baseType": string,
  "@schemaLocation": string,
  hasPrepaid: boolean
}

export interface TLSEncryptedResponse {
  identifiers: string;
}

export interface TLSDecryptedResponse {
  loginId: string,
  resourceIdentified: [{
    name: string
  }]
  otp: string,
  status: string,
  msisdn: string,
  tokenCredentialDto: {
    login: string,
    tokenCredential: string,
    mobileNumber: string,
    partyId: string,
    token: string,
    nickname: string,
  },
  udg: {
    imsi: string, // IMSI
    imei: string, // IMEI
    msisdn: string, // Full MSISDN (with 27, without +)
    timestamp_ms: string, // Unix epoch, millisecond precision
  }
}