<app-bottom-sheet-content-template>

    <h1 Header>oh no, your session is <strong>expired</strong></h1>

    <div Content>
        <div class="info-lockup">
            <p>Your session is expired. Please login again to continue.</p>
        </div>
        <div class="button-container">
            <button (click)="accept()">got it</button>
        </div>
    </div>

</app-bottom-sheet-content-template>