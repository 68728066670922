import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { OpenPWAInstaller } from '../store/pwa/actions/pwa.actions';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;

  constructor(
    private platform: Platform,
    private store: Store
  ) { }

  public initPwaPrompt() {
    if(this.checkIfInstallRejected()) return;

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        return this.openPromptComponent('android');
      });
    }
    else if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        return this.openPromptComponent('ios');
      }
    } else if(this.platform.isBrowser) {
        window.addEventListener('beforeinstallprompt', event => {
          event.preventDefault();
          this.promptEvent = event;
          this.openPromptComponent('browser');
        });
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android' | 'browser') {
    setTimeout(() => {
      this.store.dispatch(new OpenPWAInstaller({mobileType: mobileType, promptEvent: this.promptEvent}))
    }, 3000);
  }

  private checkIfInstallRejected() {
    const cookies = document.cookie;

    if(cookies) return cookies.includes('discardAppInstall');

    return false;

  }
}