// export const environment = {
//     production: false,
//     config: 'sit',
//     version: '0.0.866',
//     tokenUrl: 'http://sit.raingo.co.za',
//     apiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
//     'header-enrichment': 'https://www.sit.raingo.co.za/api',
//     'payment-status': 'https://www.sit.raingo.co.za/api/status',
//     'balances': 'https://prod-bss-api.rain.co.za/bss-api/v1/service-proxy/service/{service-id}/wallet-balance',
//     balanceManager: 'https://sit-bss-api.rain.network/axiom/rain-go/balance-manager/findall',
//     SERVICE_PROXY_API: 'https://sit-bss-api.rain.network/bss-api/v1/service-proxy',
//     PEACH_URL: 'https://eu-prod.oppwa.com/v1',
//     BASE_API_URL: 'https://sit-bss-api.rain.network',
//     TLSEncryption: 'https://whoami.sit.raingo.co.za/api/v1/whoami',
//     API_TIMEOUT: 600000,
//     MANAGE_PAGE: 'https://www.rain.co.za/login/home',
//     DIGITAL_IDENTITY: 'https://sit-bss-api.rain.network/axiom/digital-identity',
//     AXIOM_RAINGO: 'https://sit-bss-api.rain.network/axiom/rain-go',
//     AXIOM_RAINGO_SIT: 'https://sit-bss-api.rain.co.za/axiom/rain-go',
//     AXIOM: 'https://sit-bss-api.rain.network/axiom',
//     ACCOUNT_STATUS: 'https://sit-bss-api.rain.network/v1/bss/account/getstatus',
//     PAYMENT_GATEWAY: 'https://sit-bss-api.rain.network/v1/bss-pg/payments',
//     PAYMENT_GATEWAY_KEY: "qidSQZHGqFTShfuCksIZJ74aDwLpWEw5",
//     encyrption: 'ypxj6koh&4W^pD9H6dkVF#e0BHS1T!Vl7dVNxRl#3ra^r3s$rZ6a&6MW17%07!5p',
//     RAIN_SSO_URL: 'https://www.precipitation.co.za',
//     ASK_RAIN_SSO_URL: 'https://help-sit0.rain.co.za',
//     firebaseConfig: {
//         apiKey: 'AIzaSyC7lUkmE329eyqBa_0_b9NlYFtL6b6IG1g',
//         authDomain: 'rain-mobile-a12e2.firebaseapp.com',
//         databaseURL: 'https://rain-mobile-a12e2.firebaseio.com',
//         projectId: 'rain-mobile-a12e2',
//         storageBucket: 'rain-mobile-a12e2.appspot.com',
//         messagingSenderId: '32563084554',
//         appId: '1:32563084554:web:7422db729959f2df264ef3',
//         measurementId: 'G-Q790WB54ES'
//     },
// Resource_Manager: "https://prod-bss-api.rain.co.za/V1/rain-web/resource-manager"
// }

export const environment = {
  production: true,
  config: 'sit',
  tokenUrl: 'http://raingo.co.za',
  version: '0.0.866',
  'header-enrichment': 'https://www.raingo.co.za/api/header-enrichment',
  'payment-status': 'https://www.raingo.co.za/api/header-enrichment/status',
  balances: 'https://prod-bss-api.rain.co.za/bss-api/v1/service-proxy/service/{service-id}/wallet-balance',
  balanceManager: 'https://prod-bss-api.rain.co.za/axiom/rain-go/balance-manager/findall',
  SERVICE_PROXY_API: 'https://prod-bss-api.rain.co.za/bss-api/v1/service-proxy',
  FullCIRCLE_API: 'https://prod-bss-cf.rain.co.za/v1/fullcircle',
  PEACH_URL: 'https://eu-prod.oppwa.com/v1',
  TLSEncryption: 'https://whoami.raingo.co.za/api/v1/whoami',
  BASE_API_URL: 'https://prod-bss-api.rain.co.za',
  API_TIMEOUT: 600000,
  MANAGE_PAGE: 'https://www.rain.co.za/login/home',
  DIGITAL_IDENTITY: 'https://prod-bss-api.rain.co.za/axiom/digital-identity',
  AXIOM_RAINGO: 'https://prod-bss-api.rain.co.za/axiom/rain-go',
  AXIOM_RAINGO_SIT: 'https://sit-bss-api.rain.network/axiom/rain-go',
  AXIOM: 'https://prod-bss-api.rain.co.za/axiom',
  ACCOUNT_STATUS: 'https://prod-bss-api.rain.co.za/v1/bss/account/getstatus',
  PAYMENT_GATEWAY: 'https://prod-bss-api.rain.co.za/v1/bss-pg/payments',
  PAYMENT_GATEWAY_KEY: "qidSQZHGqFTShfuCksIZJ74aDwLpWEw5",
  encyrption: 'ypxj6koh&4W^pD9H6dkVF#e0BHS1T!Vl7dVNxRl#3ra^r3s$rZ6a&6MW17%07!5p',
  RAIN_SSO_URL: 'https://www.precipitation.co.za',
  ASK_RAIN_SSO_URL: 'https://askrain.sit.rain.co.za',
  apiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
  AXIOM_IDM_URL: "https://prod-bss-api.rain.co.za/bss-api/v1/idm",
  RAIN_NETWORK_CHECK_API: "https://check.rain.network/check.php",
  SICAP: "https://prod-bss-api.rain.co.za/sicapsoap",
  firebaseConfig: {
      apiKey: 'AIzaSyC7lUkmE329eyqBa_0_b9NlYFtL6b6IG1g',
      authDomain: 'rain-mobile-a12e2.firebaseapp.com',
      databaseURL: 'https://rain-mobile-a12e2.firebaseio.com',
      projectId: 'rain-mobile-a12e2',
      storageBucket: 'rain-mobile-a12e2.appspot.com',
      messagingSenderId: '32563084554',
      appId: '1:32563084554:web:7422db729959f2df264ef3',
      measurementId: 'G-Q790WB54ES'
  },
  catelogue: "https://prod-bss-api.rain.co.za/firestore/base?apikey=zI7RnqlIeA7GwkHQVXNa5vZSOPfAuEug",
  Resource_Manager: "https://prod-bss-api.rain.co.za/V1/rain-web/resource-manager",
  Notify_Url: "https://prod-bss-cf.rain.co.za/v1/notify"
}
