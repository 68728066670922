import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IApiResponse } from 'src/app/interfaces/api-response.inteface';
import { IDeviceInfo } from 'src/app/interfaces/device-info.interface';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private store: Store, private readonly _http: HttpClient) {}

  public GetDeviceDetails(
    msisdn: string
  ): Observable<IApiResponse<IDeviceInfo>> {
    const path = `${environment.SICAP}/mobileinformationbymsisdn?MSISDN=${msisdn}`;
    const token = this.store.selectSnapshot(AuthState.getToken);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      })
    };

    return this._http.get<IApiResponse<IDeviceInfo>>(path, httpOptions);
  }
}
