import { Component, Input, OnInit } from '@angular/core';
import { HomeViewModel } from '../../../pages/home/interfaces/home-view-model.interface';
import { Select, Store } from '@ngxs/store';
import { SetSelectedTopup } from 'src/app/store/topup/actions/topup.actions';
import { RaingoPromoObject, TopUpOption } from 'src/app/interfaces/interfaces';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { FirestoreState } from 'src/app/store/FireStore/firestore.state';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-promo-tile',
  templateUrl: './promo-tile.component.html',
  styleUrls: ['./promo-tile.component.scss']
})
export class PromoTileComponent implements OnInit {
  @Input() promo: RaingoPromoObject;
  @Select(FirestoreState.getPromoDetails) promoDetails$: Observable<RaingoPromoObject>;
  @Select(CoreState.GetFreeGIGEligbleSvcs) eligbleFreeGigServices$: Observable<string[]>;
  public pageSlug = window.location.pathname.replace('/', '');
  constructor(private readonly store: Store) {}

  ngOnInit(): void {}

  public setSelectedOption(option: TopUpOption, canBuy: Boolean) {
    if (!canBuy) {
      return
    }

    this.store.dispatch(new SetSelectedTopup(option));
    this.openSheet();
  }

  openSheet() {
    if (!/.*(iPad|iPhone|iPod).*/.test(window.navigator.userAgent)) {
      window.navigator.vibrate(50)
    }
    return this.store.dispatch([new BottomSheetActions.ShowBottomSheet('select_topup_number')]);
  }

  public initiateFreeGiG() {
    const option: TopUpOption = {
      id:"Bf27-ArUDqfeIZz9sQg",
      quantity:1,
      unit:"GB",
      amount:0,
      type:"DATA",
    }

    this.store.dispatch([new SetSelectedTopup(option), new BottomSheetActions.ShowBottomSheet('select_topup_number')]);
  }
}
