<app-bottom-sheet-content-template>
  <div Content>
    <div class="sheet-body">
      <div class="disclaimer-container">
        @if ((stage$ | async) === '2') {
          <p >your account has been created, are you sure you want to <strong>assign this SIM</strong> to your account?</p>
        } @else {
          <p>are you sure you want to <strong>assign this SIM</strong> to your account?</p>
        }
        <p>Number: <strong>{{msisdn}}</strong></p>
      </div>

      <div class="actions-footer">
        <button class="transparent" (click)="dismiss()">no thanks</button>
        <button class="solid" (click)="initiateAssignSim()" >yes, assign SIM</button>
      </div>
    </div>
  </div>

</app-bottom-sheet-content-template>