import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NextStep } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-assign-sim-register-or-sign-in',
  templateUrl: './assign-sim-register-or-sign-in.component.html',
  styleUrls: ['./assign-sim-register-or-sign-in.component.scss']
})
export class AssignSimRegisterOrSignInComponent implements OnInit {

  constructor(private store: Store) {}

  isIdentified: any = false;

  ngOnInit(): void {
    this.isIdentified = !!this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);   
  }

  createAccount() {
    if (!this.isIdentified) {
      this.showSimNotIdentified()
      return
    }

    this.store.dispatch(new NextStep('2'))
  }

  signIn() {
    if (!this.isIdentified) {
      this.showSimNotIdentified()
      return
    }

    this.store.dispatch(new NextStep('3'))

  }

  showSimNotIdentified() {
    this.store.dispatch(new NextStep('4'));
  }

}
