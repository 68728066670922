import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { TopupActions } from 'src/app/store/topup/actions';

@Component({
  selector: 'raingo-non-eligible-top-up-error-sheet',
  templateUrl: './non-eligible-top-up-error-sheet.component.html',
  styleUrl: './non-eligible-top-up-error-sheet.component.scss'
})
export class NonEligibleTopUpErrorSheetComponent {
  
  constructor(private readonly store: Store) {}

  public accept() {
    this.store.dispatch([new CloseBottomSheet(), new Navigate(['/services'], new TopupActions.ClearTopUpDetails)]);
  }
}
