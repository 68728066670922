import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ForgotPasswordActions } from './actions';
import { ForgotPasswordService } from 'src/app/services/forgot-password.service';
import { error } from 'jquery';
import { tap } from 'rxjs/operators';
import { BottomSheetActions } from '../BottomSheet/actions';

interface ForgotPasswordStateModel {
    result: any
    error: any
}
@State<ForgotPasswordStateModel>({
    name: 'forgot_password_state',
    defaults: {
        result: null,
        error: null
    }
})

@Injectable()
export class ForgotPasswordState {

    @Selector()
    static getResult(state: ForgotPasswordStateModel) {return state.result}

    @Selector()
    static getError(state: ForgotPasswordStateModel) {return state.error}

    constructor(private forgotPasswordService: ForgotPasswordService) {}

    @Action(ForgotPasswordActions.SendPasswordResetEmail)
    sendPasswordResetEmail(ctx: StateContext<ForgotPasswordStateModel>, action: ForgotPasswordActions.SendPasswordResetEmail) {

        return this.forgotPasswordService.sendEmail(action?.payload).pipe(
            tap({
                next: (res) => {
                    ctx.patchState({
                        result: res,
                        error: null
                    })

                    return ctx.dispatch(new BottomSheetActions.ChangeOpenSheet('password_reset_successful'))
                },
                error: (err: unknown) => {
                    ctx.patchState({
                        result: "failed",
                        error: err
                    })
                }
            })
        )
    }

    @Action(ForgotPasswordActions.Reset)
    Reset(ctx: StateContext<ForgotPasswordStateModel>, action: ForgotPasswordActions.Reset) {
        return ctx.setState({
            result: null,
            error: null
        })
    }



}