import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { ToggleIntDialing } from 'src/app/store/Core/actions/service.actions';
import { TopUp } from 'src/app/store/topup/actions/topup.actions';
import { TopupState } from 'src/app/store/topup/topup.state';

@Component({
  selector: 'raingo-buy-int-minutes-sheet',
  templateUrl: './buy-int-minutes-sheet.component.html',
  styleUrl: './buy-int-minutes-sheet.component.scss'
})
export class BuyIntMinutesSheetComponent {

  constructor(private readonly store: Store) {}

  public continuePurchase() {
    const selectedSVc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    const selectedTopUp = this.store.selectSnapshot(TopupState.getSelectedTopup)!;

    if(selectedSVc.international_access === 'disabled') {
     return this.store.dispatch(new ToggleIntDialing({enable: true, serviceID: selectedSVc.id, isPurchasing: true}));
    }

    this.store.dispatch(new TopUp({option: selectedTopUp, msisdn: selectedSVc.msisdn}));
  }

}
