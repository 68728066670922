import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AssignSim, NextStep } from 'src/app/store/AssignSim/actions/assign-sim.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'app-ask-assign-sim-sheet',
  templateUrl: './ask-assign-sim-sheet.component.html',
  styleUrls: ['./ask-assign-sim-sheet.component.scss']
})
export class AskAssignSimSheetComponent implements OnInit {
  @Select(AssignSimState.GetStage) stage$: Observable<string>;
  public msisdn: string ='08329557655';

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.getSimMsisdn();
  }

  public dismiss() {
    this.store.dispatch(new CloseBottomSheet());
  }

  public initiateAssignSim() {
    const decodedId = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);
    const user = this.store.selectSnapshot(AuthState.getAxiomUser);

    const assignedServices = this.store.selectSnapshot(CoreState.GetAssignedServices);
    const alreadyAssigned = assignedServices?.find((as) => as.msisdn === this.msisdn);
    if(alreadyAssigned) {
      return this.store.dispatch(new NextStep('6'))
    } else {
      if(decodedId && this.msisdn) {
        const payload = {
          loginId: user?.loginId as string,
          msisdn: this.msisdn
        }
        this.store.dispatch(new AssignSim(payload));
      }
    }
    return;
  }

  private getSimMsisdn() {
    const decodedId = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials);
    this.msisdn = decodedId?.udg.msisdn as string;
  }
}
