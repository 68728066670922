import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { IAccountStatusDetail } from '../interfaces/account.interfaces';
import { AuthState } from '../store/Auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private store: Store) {}

  public getAccountStatus(email: string): Observable<IAccountStatusDetail> {
    const idmToken = this.store.selectSnapshot(AuthState.getTauToken);
    const requestPath = `${environment.ACCOUNT_STATUS}`;
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: apiKey,
        Authorization: 'Bearer ' + idmToken,
      }),
    };

    const body = {
      email
    };
    return this.http.post<IAccountStatusDetail>(requestPath, body, httpOptions);
  }
}
