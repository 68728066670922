import { Utils } from "@Utils";

export class SignIn {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Auth user with username and password');
    constructor(public email: string, public password: string, public readonly redirectToUrl?: string) {}
}

export class SignInFail {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Auth user with username and password fail');
}

export class SignInWithToken {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Auth user with token');
    constructor(public token: string) {}
}

export class UpdateSignedInStatus {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Check if user is signed in or not');
    constructor(public readonly token?: string) {}
}

export class SignOut {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Sign Out');
    constructor(public sso?: boolean) {}
}

export class ShowTokenExpiredModal {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Show token expired modal');

}
export class HideTokenExpiredModal {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Hide token expired modal');
}

export class GetUserDetails {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Get user details');
}

export class AuthIfNotAuthed {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Check If Rain One Mobile');
}

export class AuthUserWithSessionId {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Auth user with session id');
    constructor(public sessionId: string) {}
}

export class SetAuthTokens {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Set auth tokens');
    constructor(public readonly payload: {token: string}) {}
}

export class ShowLoader {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Show loader');

}
export class HideLoader {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Hide loader');
}