
import { Utils } from '@Utils';
import { IPinPukResponse } from 'src/app/interfaces/pinkpuk.interface';

export class FetchPinPukDetails {
	static readonly type = Utils.Helpers.Type('[Dashboard: PinPuk] Fetch services pinpuk details');
	constructor(public readonly payload: string) {}
}
export class FetchPinPukDetailsSuccess {
	static readonly type = Utils.Helpers.Type('[Dashboard: PinPuk] Fetch services pinpuk details success');
	constructor(public readonly payload: IPinPukResponse) {}
}
export class FetchPinPukDetailsFail {
	static readonly type = Utils.Helpers.Type('[Dashboard: PinPuk] Fetch services pinpuk details fail');
	constructor(public readonly payload: any) {}
}