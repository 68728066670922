import { Utils } from "@Utils";

export class GetUsersBillCycleDate {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date');
}

export class GetUsersBillCycleDateSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date success');
    constructor(public readonly payload: {user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period:{start: number}}}}) {}
}

export class GetUsersBillCycleDateFail {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date fail');
    constructor(public readonly payload: any) {}
}