import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/store/Auth/auth.state';

@Component({
  selector: 'raingo-full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrl: './full-page-loader.component.scss'
})
export class FullPageLoaderComponent {
  @Select(AuthState.GetLoader) GetLoader$: Observable<boolean>;
}
