import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { ForgotPasswordActions } from 'src/app/store/ForgotPassword/actions';
import { ForgotPasswordState } from 'src/app/store/ForgotPassword/forgot-password.state';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Select(ForgotPasswordState.getError) error$!: Observable<any>

  passwordReset!: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
      this.setForm()
  }

  setForm() {
    this.passwordReset = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
    })
  }

  sendEmail() {
    const { email } = this.passwordReset?.getRawValue() ?? {}
    if (!email) {
      return
    }
    return this.store.dispatch(new ForgotPasswordActions.SendPasswordResetEmail(email))
  }

  goToLogin() {
    return this.store.dispatch(new BottomSheetActions.ChangeOpenSheet('sign_in'))
  }

  ngOnDestroy(): void {
      
  }
}
