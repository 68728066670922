import { Injectable } from '@angular/core';

interface BreakPoints {
    mobile:string,
    ipad: string,
    ipadMed: string,
    ipadPro: string,
    desktop: string,
}

export const CustomBreakpointNames: BreakPoints = {
    mobile: 'mobile',
    ipad: 'ipad',
    ipadMed: 'ipadMed',
    ipadPro: 'ipadPro',
    desktop: 'desktop',
};

@Injectable({
    providedIn: 'root'
})
export class BreakpointsService {
    breakpoints: any = {
        '(min-width: 375px)': CustomBreakpointNames.mobile,
        '(min-width: 428px)': CustomBreakpointNames.mobile,
        '(min-width: 750px)': CustomBreakpointNames.ipadMed,
        // '(min-width: 1024px)': CustomBreakpointNames.ipadPro,
        // '(min-width: 1200px)': CustomBreakpointNames.desktop
    };

    getBreakpoints(): string[] {
        return Object.keys(this.breakpoints);
    }

    getBreakpointName(breakpointValue:string): string {
        const breakPointName: string = this.breakpoints[breakpointValue]

        return breakPointName;
    }

    constructor() {
    }
}
