import { Injectable } from "@angular/core";
import { PAYMENT_METHOD, SELECTED_TOPUP, TOPUP_RESULT } from "../constants/constants";

@Injectable({
    providedIn: 'root'
})
export class PaymentCacheService {
    setPaymentCacheItem(item: string, value: any) {
        sessionStorage.setItem(item, JSON.stringify(value));
    }

    getPaymentCacheItem(item: string) {
        return JSON.parse(sessionStorage.getItem(item)!)
    }

    clearPaymentCache() {
        sessionStorage.removeItem(TOPUP_RESULT);
        sessionStorage.removeItem(PAYMENT_METHOD);
        sessionStorage.removeItem(SELECTED_TOPUP);
        sessionStorage.removeItem(TOPUP_RESULT);
    }
    
}