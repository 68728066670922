import { Utils } from "src/app/utils";
import { BottomSheetName } from "../intefaces/bottom-sheet-interfaces";

export class ShowBottomSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Open] Open bottom sheet');
    constructor(public openSheetName: BottomSheetName, public data?: any, public clearAssignSimState?: boolean) {}
}

export class ChangeOpenSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Change] Change bottom sheet');
    constructor(public openSheetName: BottomSheetName, public data?: any, public clearAssignSimState?: boolean) {}
}

export class CloseBottomSheet {
    static readonly type = Utils.Helpers.Type('[BottomSheet: Close] Close bottom sheet');
    constructor(public readonly clearAssignSimState?: boolean) {}
}