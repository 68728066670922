import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RainOneProduct } from 'src/app/interfaces/product.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCatelogueService {
  constructor(private _http: HttpClient) {}

  public fetchRainOneProducts(): Observable<{ result: Array<RainOneProduct> }> {
  
    let url: string = environment.catelogue;

    
    return this._http.get<{ result: Array<RainOneProduct> }>(url);
  }
}
