import { TopUpOption } from "../../interfaces/interfaces";

export function sortTopUpOptions(options: {[key: string]: TopUpOption[]}) : {[key: string]: TopUpOption[]} {
    const sortedCopy:{[key:string]:TopUpOption[]} =  {DATA:[],SMS:[],VOICE:[]}
    Object.keys(options).forEach(key => {

        const value = options[key];
        if(key.toLocaleLowerCase() === 'data' && value.find((po: any) => po.amount === 0)) {
            const promoPO = value.find((po: any) => po.amount === 0);
            
            const fillteredArr = value.filter((po: any) => po.amount !== 0).sort((a,b)=> a.amount - b.amount);
            if(promoPO) {
                sortedCopy[key] = [
                    ...fillteredArr.slice(0, 1),
                    promoPO,
                    ...fillteredArr.slice(1, fillteredArr.length)
                ];

                return sortedCopy;
            }
        }
        if(key.toLocaleLowerCase() === 'voice') {
            value.push({
                amount:100,
                id:"Bh29-AqQ5ENLk09ehEQ",
                quantity:100,
                type:'VOICE',
                unit:'MIN'
            })
        }
        return sortedCopy[key] = value.sort((a,b)=> a.amount - b.amount)
    });
    return sortedCopy
}