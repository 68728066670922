import { IAccountStatusDetail } from "src/app/interfaces/account.interfaces";
import { AssignUserPayload, RegistrationPayload } from "src/app/interfaces/interfaces";
import { IServicePolicyResponse, IServicesGrouping } from "src/app/interfaces/services.interface";
import { SSOPayload } from "src/app/services/sso.service";
import { Utils } from "src/app/utils";



export class RedirectToAuthURL {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Redirect to auth URL');
}
export class ResetWallet {
    static readonly type = Utils.Helpers.Type('[Core: Delete] Reset wallet');
}

export class ResetRedirectCounter {
    static readonly type = Utils.Helpers.Type('[Core: Redirect] Reset Redirect Counter');
}
  
export class CheckIfRainOneMobileSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Check If Rain One Mobile Success');
    constructor(public payload: string) {}
}

export class FetchServices {
    static readonly type = Utils.Helpers.Type('[Core: Fetch] Fetch services tied to user');
    constructor(public readonly from?: string) {}
}
export class FetchServicesSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Fetch] Fetch services tied to user success');
    constructor(public readonly payload: IServicesGrouping) {}
}

export class FetchTopupHistory {
    static readonly type = Utils.Helpers.Type('[Core: Fetch] Fetch topup history tied to user');
}
  
export class CheckIfRainOneMobileFailure {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Check If Rain One Mobile Failure');
    constructor(public error: unknown) {}
}

export class GetWalletData {
    static readonly type = Utils.Helpers.Type('[Core: Get] Get wallet data');
    constructor(public readonly serviceId: string, public readonly isTLSService?: boolean, public readonly svcs?: IServicesGrouping) {}
}

export class GetSMEWalletData {
    static readonly type = Utils.Helpers.Type('[Core: Get] Get SME wallet data');
    constructor(public readonly smeToken: string, public readonly isTLSService?: boolean, public readonly svcs?: IServicesGrouping) {}
}
export class StartHomeLoader {
    static readonly type = Utils.Helpers.Type('[Core: Loader] Start home loader');
}
export class EndHomeLoader {
    static readonly type = Utils.Helpers.Type('[Core: Loader] End home loader');
}
export class AssignDeviceLayout {
    static readonly type = Utils.Helpers.Type('[Core: Device] Assign device layout');
    constructor(public readonly payload: string) { }
}
export class ShowLoader {
    static readonly type = Utils.Helpers.Type('[Core: Loader] Show loader');
}
export class HideLoader {
    static readonly type = Utils.Helpers.Type('[Core: Loader] Hide Loader');
}
export class TransactionEnd {
    static readonly type = Utils.Helpers.Type('[Core: Set] Set Transaction End');
}
export class ClearTransaction {
    static readonly type = Utils.Helpers.Type('[Core: Set] Clear Transaction End');
}
export class CheckedOut {
    static readonly type = Utils.Helpers.Type('[Core: Set] Set CheckedOut');
}
export class ResetCheckOut {
    static readonly type = Utils.Helpers.Type('[Core: Reset] Reset CheckedOut');
}

export class RegisterUser {
    static readonly type = Utils.Helpers.Type('[Core: Register] Register user');
    constructor(public payload: RegistrationPayload) {}
}

// used when a secondary user does an assign sim and creates a new account
export class AssignUser {
    static readonly type = Utils.Helpers.Type('[Core: Assign] Assign user an account');
    constructor(public payload: AssignUserPayload) {}
}

export class FetchData {
    static readonly type = Utils.Helpers.Type('[Core: Fetch] Fetched authed users details');
}

export class AuthWithTLS {
    static readonly type = Utils.Helpers.Type('[Core: Auth] Auth user with TLS');
    constructor(public assignSimJourney?: boolean) {}
}

export class FetchTlsServiceObject {
    static readonly type = Utils.Helpers.Type('[Core: Fetch] Fetch TLS service object by msisdn');
    constructor(public readonly msisdn: string) {}
}

export class AttemptTLSIndentity {
    static readonly type = Utils.Helpers.Type('[Core: TLS] Attempt TLS identity check');
}

export class DecodeTLSResponse {
    static readonly type = Utils.Helpers.Type('[Core: Decode] Decode TLS response');
    constructor(public indentifier: string) {}
}

export class DismissMessageBar {
    static readonly type = Utils.Helpers.Type('[Core: MessageBar] Dismiss message bar');
}

export class ShowMessageBar {
    static readonly type = Utils.Helpers.Type('[Core: MessageBar] Show message bar');
    constructor(public readonly payload: {message: string}) {}
}

export class TurnOffFirstTimeLanding {
    static readonly type = Utils.Helpers.Type('[Core: FirstTimeLanding] Set first time landing');
}

export class SSOSignIn {
    static readonly type = Utils.Helpers.Type('[Core: Sign-in] Sign-in by sso');
    constructor(public readonly payload: SSOPayload) {}
}

export class TurnOffSignInLoading {
    static readonly type = Utils.Helpers.Type('[Core: Sign-in] turn off loading');
}
export class FetchAccountStatus {
    static readonly type = Utils.Helpers.Type('[Core: Account] Fetch account status');
    constructor(public readonly payload: {email: string, ignoreCache?: boolean}) {}
}

export class FetchAccountStatusSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Account] Fetch account status success');
    constructor(public readonly payload: IAccountStatusDetail) {}
}

export class FetchAccountStatusFail {
    static readonly type = Utils.Helpers.Type('[Core: Account] Fetch account status fail');
    constructor(public readonly payload: any) {}
}

export class RequestToDecodeIdentifier {
    static readonly type = Utils.Helpers.Type('[Core: Request] Request to decode identifier');
    constructor(public readonly payload: {identifiers: string}, public assignSimJourney?: boolean) {}
}

export class RequestToDecodeIdentifierSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Request] Request to decode identifier success');
    constructor(public readonly payload: any, public assignSimJourney?: boolean ) {}
}

export class RequestToDecodeIdentifierFail {
    static readonly type = Utils.Helpers.Type('[Core: Request] Request to decode identifier fail');
    constructor(public readonly payload: any) {}
}

export class InitiateAssignSimOTP {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Initate assign sim OTP');
    constructor(public readonly payload: any) {}
}

export class AcceptAssignSimOTP {
    static readonly type = Utils.Helpers.Type('[Core: Assign Sim] Accept OTP');
    constructor(public readonly payload: any) {}
}

export class GetAssignedSimList {
    static readonly type = Utils.Helpers.Type('[Core: Assigned Sim List] Get a list of assigned sims');
    constructor(public readonly payload: string) {}
}
export class GetAssignedSimListSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Assigned Sim List] Get a list of assigned sims success');
    constructor(public readonly payload: any) {}
}
export class GetAssignedSimListFail {
    static readonly type = Utils.Helpers.Type('[Core: Assigned Sim List] Get a list of assigned sims fail');
    constructor(public readonly payload: any) {}
}

export class CheckIfIsPrimaryUser {
    static readonly type = Utils.Helpers.Type('[Core: Primary User Check] Check if the user is the primary');
}

export class RemoveFreeGigSVCID {
    static readonly type = Utils.Helpers.Type('[Core: FreeGig] Remove free gig svc id from state');

    constructor(public readonly payload: string) {}
}

export class HideHeader {
    static readonly type = Utils.Helpers.Type('[Core: UI] Hide header');
    constructor(public readonly payload: boolean) {}
}

export class FetchServicePolicies {
    static readonly type = Utils.Helpers.Type('[Core: Service-Policies] Fetch service polies');
    constructor(public readonly payload: {mode: string}) {}
}
export class ClearServicePolicies {
    static readonly type = Utils.Helpers.Type('[Core: Service-Policies] Clear service polies');
}
export class FetchServicePoliciesSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Service-Policies] Fetch service polies success');
    constructor(public readonly payload: IServicePolicyResponse) {}
}
export class FetchServicePoliciesFail {
    static readonly type = Utils.Helpers.Type('[Core: Service-Policies] Fetch service polies fail');
    constructor(public readonly payload: any) {}
}