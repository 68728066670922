@if((speedUpConfig$ | async); as config) {
    <app-bottom-sheet-content-template>
    
        <h1 Header><strong>reverse</strong> speed change successful</h1>
        
        <div Content>
            <div class="disclaimer">
                <p>Your speed change has been reversed successfully.</p>
            </div>
            <div class="button-container">
                <button class="solid" (click)="dismiss()">got it</button>
            </div>
        </div>
    
    </app-bottom-sheet-content-template>
}


