
<app-bottom-sheet-content-template>

    <h1 Header></h1>
    
    <div Content>
        <h1>new to rain?</h1>
        <div class="info-lockup">
            <h3>Create your own rain account & assign this SIM.</h3>
            <button class="button" (click)="createAccount()">create account & assign SIM</button>
            <!-- <button class="button" (click)="proceed('register')">create account & assign SIM</button> -->
        </div>

        <h1>already a rain customer?</h1>
        <div class="info-lockup">
            <h3>Sign in using your rain details & assign this SIM.</h3>
            <button class="button inverse" (click)="signIn()">sign in & assign SIM</button>
            <!-- <button class="button inverse" (click)="proceed('sign_in')">sign in & assign SIM</button> -->
        </div>

    </div>

</app-bottom-sheet-content-template>