import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { tap } from 'rxjs';
import { IProductDetail, RainOneProduct } from 'src/app/interfaces/product.interface';
import { ProductCatelogueService } from 'src/app/core/services/product-catelogue.service';
import * as CatelogueActions from './actions/catelogue.actions';
import { Utils } from '@Utils';
import { FOURG_STANDALONE } from 'src/app/core/data/constants';

function loadDefault(): CatelogueStateModel {
  return {
    catelogue: null,
    catelogue_isLoading: false,
    catelogue_isLoaded: false,
    standAlone4GAU: null,
    numRainOneMobileSvcs:null
  };
}

interface CatelogueStateModel {
    catelogue: { [key: string]: RainOneProduct[] } | null;
    catelogue_isLoading: boolean,
    catelogue_isLoaded: boolean,
    standAlone4GAU: RainOneProduct | null,
    numRainOneMobileSvcs: number | null
}

@State<CatelogueStateModel>({
  name: 'catelogue',
  defaults: loadDefault(),
})
@Injectable()
export class CatelogueState {
  @Selector()
  static GetProducts(state: CatelogueStateModel) {
    return state.catelogue;
  }


  @Selector()
  static GetProductById(id: string) {
    return createSelector([CatelogueState], (state: any): IProductDetail | RainOneProduct | undefined  => {
      const bundles: RainOneProduct[] = [...Utils.Helpers.FlattenHashMapArrays<RainOneProduct>(state.catelogue.catelogue as any)];
      
      const product = bundles.find(b => b.id === id); 
      if(product) return product;
    });
  }

  constructor(
    private catelogueSvc: ProductCatelogueService,
  ) {}

  @Action(CatelogueActions.FetchCatelogueProducts)
  FetchProducts(
    ctx: StateContext<CatelogueStateModel>,
    action: CatelogueActions.FetchCatelogueProducts
  ) {

    return this.catelogueSvc.fetchRainOneProducts().pipe(
      tap({
        next: (res: { result: Array<RainOneProduct> }) => {
            if (res && res.result) {
              ctx.dispatch(new CatelogueActions.FetchCatelogueProductsSuccess(res.result));
            }
          },
          error: err => {
            ctx.dispatch(new CatelogueActions.FetchCatelogueProductsFail(err));
          }
      })
    );
  }

  @Action(CatelogueActions.FetchCatelogueProductsSuccess)
  FetchProductsSuccess(
    ctx: StateContext<CatelogueStateModel>,
    action: CatelogueActions.FetchCatelogueProductsSuccess
  ) {
    const payload = action.payload;
    const state = ctx.getState();
    const catelogue = Utils.Helpers.ToSortedHashMap<RainOneProduct[]>(payload, state.catelogue, 'type');
    
    ctx.patchState({
        catelogue: catelogue,
        catelogue_isLoading: false,
        catelogue_isLoaded: true,
        standAlone4GAU: action.payload.find((p: RainOneProduct) => p.id === FOURG_STANDALONE)
    })
  }

}
