export interface AppElement {
  /**
   * type @param
   */
  type: string;

  /**
   * class? @param
   */
  class?: string;
  id?: string;
  event?: {
    name: string;
    handler: any;
  };
  children?: AppElement[] | string;
  attributes?: ElementAttribute[];
}

/**
 * ElementAttribute @param {string}
 */
interface ElementAttribute {
  name: string;
  value: string;
}

/**
 * applyAttributes @param
 */
function applyAttributes(element: Element, attributes: ElementAttribute[]) {
  for (let attr of attributes) {
    element.setAttribute(attr.name, attr.value);
  }
}

/**
 * createNestedElements @param
 */
function createNestedElements(container: Element, appElement: AppElement) {
  let element: any = document.createElement(appElement.type);

  if (appElement.attributes) {
    applyAttributes(element, appElement.attributes);
  }

  if (appElement.event) {
    element[appElement.event.name] = appElement.event.handler;
  }

  if (appElement.class) {
    element.className = appElement.class;
  }

  if (appElement.id) {
    element.id = appElement.id;
  }

  if (appElement.children) {
    if (typeof appElement.children === 'string') {
      element.innerHTML = appElement.children;
    } else {
      for (let child of appElement.children) {
        createNestedElements(element, child);
      }
    }
  }

  container.appendChild(element);
}

/**
 *  renderElement @param
 */
export function renderElement(query: string, appElement: AppElement) {
  let container = document.querySelector(query);
  if (container) createNestedElements(container, appElement);
}

/** 
  requestFullscreen() can not be called automatically  
  because of security reasons (at least in Chrome). 
  It can only be called by a user action such as:
  
  1. click (button, link...)
  2. key (keydown, keypress...)
*/
export function openFullscreen(html: HTMLElement) {
  if (html.requestFullscreen) {
    html.requestFullscreen()
    .catch((err:DOMException) => {
      console.error(
        `An error occurred while trying to switch into fullscreen mode: `,{err}
      )
    })
  }
}
