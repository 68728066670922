import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, delay, of } from 'rxjs';
import { CoreState } from '../store/Core/core.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionEndCheckResolver implements Resolve<boolean> {
  constructor(private store: Store, private route: ActivatedRoute) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkRainOne();
  }

  private checkRainOne(): Observable<boolean> | any {
    const end = this.store.selectSnapshot(CoreState.checkTransactionEnd);
    
    if (!end) return of(true);

    window.location.replace(environment.tokenUrl);

    return of().pipe(delay(500));
  }
}
