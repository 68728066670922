import { Component, OnInit, Input } from '@angular/core';
import { renderElement } from '../../../../../utils/elements';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { CoreState } from '../../../../../store/Core/core.state'

@Component({
  selector: 'app-peach-widget',
  templateUrl: './peach-widget.component.html',
  styleUrls: ['./peach-widget.component.scss']
})
export class PeachWidgetComponent implements OnInit {

  onSubmit = new Subject<string>();
  
  @Input() checkoutId!: string;
  @Input() buyNowLabel: string = 'buy now';
  @Input() registerLabel!: string;
  @Input() otherPayments!: string;
  @Input() redirectUrl!: string;
  isMobile = navigator.userAgent.match(/(iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  
  // TODO: use a selector to check for successful vs failed payments
  hasError = false;

  constructor(private store: Store) { }

  ngOnInit() {
    this.buildWidget();
  }

  buildWidget() {

    const widgetUrl = `${environment.PEACH_URL}/paymentWidgets.js?checkoutId=${this.checkoutId}`;
    const self = this;
    window.wpwlOptions = {
      brandDetection: true,
      labels: this.getLabels(),
      onAfterSubmit: function () {
        self.onSubmit.next('');
      },
      onReady: () => {
        // const createRegistrationHtml = '<div class="customLabel">Store payment details?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
        // const signedIn = this.store.selectSnapshot(CoreState.isAuthed);
        // signedIn ? $('form.wpwl-form-card').find('.wpwl-button').before(createRegistrationHtml) : null;
      }
    };
    
    renderElement('.page-frame-editpayment', {
      id: 'payment',
      type: 'div',
      children: [
        {
          type: 'script',
          attributes: [{ name: 'src', value: widgetUrl }]
        },
        {
          type: 'div',
          class: 'payment-card',
          children: [
            {
              type: 'form',
              class: 'paymentWidgets',
              id: 'paymentForm',
              attributes: [
                {
                  name: 'action',
                  value: this.redirectUrl
                },
                { name: 'data-brands', value: 'VISA MASTER' },
                { name: 'createRegistration', value: 'true' }
              ]
            }
          ]
        }
      ]
    });
  }

  getLabels() {
    const labels: any = { expiryDate: 'Valid until' };

    if (this.buyNowLabel) {
      labels['submit'] = this.buyNowLabel;
    }

    if (this.registerLabel) {
      labels['register'] = this.registerLabel;
    }

    if (this.otherPayments) {
      labels['showOtherPaymentMethods'] = this.otherPayments;
    }

    return labels;
  }
}
