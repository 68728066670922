@if ((checkout$ | async); as checkout) {
  <section class="paymentMethods-container">
    <h1 class="payment-header">
      {{this?.paymentHeader?.partOne}}<b>{{this?.paymentHeader?.partTwo}}</b>
    </h1>
    @if (isOpen$ | async) {
      <rain-loader></rain-loader>
    }
    @if ((checkoutId && showPaymentMethods)) {
      <div
        [ngClass]="{ 'card-details-height': selectedPaymentType?.paymentMethodName === 'CARD' && checkoutId }"
        class="mb-10"
        >
        @if (selectedPaymentType && selectedPaymentType?.paymentMethodName === 'CARD') {
          <div class="mb-10 peach-widget-box" [ngClass]="{ 'card border-0 custom-rounded shadow': !(isOpen$ | async) }">
            <app-peach-widget [checkoutId]="checkout?.checkout?.checkout" [redirectUrl]="redirectUrl"> </app-peach-widget>
            <br>
              <hr>
              </div>
            }
            <div class="capitec-iframe-container"
            [ngClass]="{
                'open': ((selectedPaymentType?.paymentMethodName === 'INSTANT EFT' ||
                selectedPaymentType?.paymentMethodName === 'CAPITEC EFT'))
            }"
              >
              @if (
                (selectedPaymentType?.paymentMethodName === 'INSTANT EFT' ||
                selectedPaymentType?.paymentMethodName === 'CAPITEC EFT'
                )
                ) {
                <div
                  >
                  <div class="d-flex justify-content-center iframe-container">
                    <iframe
                      id="instantIframe"
                      target="_parent"
                      class="iframe-height not-mobile-eft instant-iframe"
                      name="nameFrame"
                      [src]="instantPayURL | safe: 'resourceUrl'"
                      height="500px"
                      width="100%"
                      frameborder="0"
                      scrolling="no"
                      sandbox="allow-same-origin allow-top-navigation allow-scripts allow-popups allow-forms"
                      allowtransparency="true"
                    ></iframe>
                  </div>
                  <div class="d-flex justify-content-end mt-5 mb-5">
                    <img src="/assets/images/payment-page/powered-by-pay.png" width="180" alt="" />
                  </div>
                </div>
              }
            </div>
            <div class="d-flex justify-content-start">
              <div class="mr-md-10 accordion testy payment-method-width" [id]="'accordion'">
                @if (!(selectedPaymentType && selectedPaymentType?.paymentMethodName === 'CARD')) {
                  @for (method of filterPaymentOptions(paymentMethods?.appPaymentLinks); track method; let i = $index) {
                    <div class="paymentMethod card"
                      (click)="showWidget(method)"
                      >
                      <div
                        class="payment-method-item-container"
                            [ngClass]="{
                                'ipad-width': isIpad,
                                'noIpad-width': !isIpad || isMobile
                            }"
                        >
                        <div class="d-flex justify-content-center image-container">
                          <img
                            [src]="method?.paymentImage"
                                [ngClass]="{
                                    'img-width':
                                    method?.paymentMethodName === 'QR CODE PAYMENT' ||
                                    method?.paymentMethodName === 'PAY IN-STORE',
                                    'zapper-mobi': method?.paymentMethodName === 'ZAPPER',
                                    'eft-img-width': method?.paymentMethodName === 'INSTANT EFT',
                                    'mobile-capitec-img-width': method?.paymentMethodName === 'CAPITEC EFT',
                                    'snapscan-img-width': method?.paymentMethodName === 'SNAPSCAN',
                                    'masterpass-img-width': method?.paymentMethodName === 'MASTERPASS',
                                    'fnb-img-width': method?.paymentMethodName === 'FNB SCAN TO PAY',
                                    'netbank-img-width': method?.paymentMethodName === 'NEDBANK SCAN TO PAY'
                                }"
                            />
                          @if (method?.paymentMethodName === selectedPaymentType?.paymentMethodName) {
                            <span
                              class="settings"
                              >
                              <i class="text-primary fa fa-angle-down angle-down"></i></span>
                            }
                          </div>
                          @if ((selectedPaymentType === method)) {
                            <div class="closebutton" (click)="clearSelectedPaymentMethod()">close</div>
                          }
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            </div>
          }
        </section>
      }