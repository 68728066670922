<app-bottom-sheet-content-template>
    <h1 Header>no need to <strong>assign this SIM</strong></h1>
    <div Content>
        <p class="primary-message">This SIM is already linked to your account. To assign it to someone else, ask them to insert the SIM in their phone, tap on ‘<strong>assign SIM</strong>’ at rain<strong>GO</strong> & follow the easy steps.</p>
        <div class="actions-footer">
            <button class="submit" (click)="close()">
                got it
            </button>
        </div>
    </div>
</app-bottom-sheet-content-template>