import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ClosePWAInstaller, OpenPWAInstaller } from '../actions/pwa.actions';

interface TopupStateModel {
    openInstaller: boolean,
    data: {mobileType: string | null, promptEvent: any | null}
}
@State<TopupStateModel>({
    name: 'PWA_State',
    defaults: {
        openInstaller: false,
        data: {mobileType: null, promptEvent: null}
    }
})
@Injectable()
export class PWAState {
    constructor(private store: Store) {}

    @Selector()
    static OpenInstaller(state: TopupStateModel) { return state.openInstaller }

    @Selector()
    static GetInstallerData(state: TopupStateModel) { return state.data }

    @Action(OpenPWAInstaller)
    OpenPWAInstaller(ctx: StateContext<TopupStateModel>, action: OpenPWAInstaller) {
        const state = ctx.getState();
        const data = action.data;

        ctx.patchState({
            openInstaller: true,
            data: data
        })
    }

    @Action(ClosePWAInstaller)
    ClosePWAInstaller(ctx: StateContext<TopupStateModel>, action: ClosePWAInstaller) {
        const state = ctx.getState();

        ctx.patchState({
            openInstaller: false,
        })
    }
}