<div class="lockup">
    <div class="selector-wrapper">
        @if((config$ | async).step === 2) {
            <div class="header">
                <h2>select how many lines to level up:</h2>
                <div *ngIf="(minMax$ | async) as minMax" class="selector-lockup">
                    <div class="minus btn" [class.disabled]="minMax.current === minMax.min" (click)="decrement()">
                        <svg width="20" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="level">
                        <span>{{minMax.current}}</span>
                    </div>
                    <div class="plus btn" [class.disabled]="minMax.current === minMax.max" (click)="increment()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 10H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 1V19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                    </div>
                </div>
            </div>
    
            <hr>
        }

        <div class="levels-table-wrapper">
            <h2>select your level:</h2>

            @if(!isRainMobile) {
                <p class="disclaimer">Level up all your rain<strong>one</strong> mobile lines.</p>
            }

            <div class="table">
                <div class="table-header">
                    <div class="header-cell">
                        <span>level</span>
                    </div>
                    @if(isRainMobile) {
                        <div class="header-cell">
                            <span>data</span>
                        </div>
                        <div class="header-cell">
                            <span>minutes</span>
                        </div>
                        <div class="header-cell">
                            <span>price <br>per level</span>
                        </div>
                    } @else {
                        <div class="header-cell">
                            <span>data <br>per line</span>
                        </div>
                        <div class="header-cell">
                            <span>minutes <br>per line</span>
                        </div>
                        <div class="header-cell">
                            <span>price <br>per level</span>
                        </div>
                    }
  
                </div>

                <div class="table-body">
                    @for (level of levels; track level) {
                        <div class="table-row" [class.selected]="(SelectedLevel$ | async)?.id === level?.id" (click)="selectLevel(level)">
                            <div class="cell base-level">
                                @if (level.level === 0) {
                                    @if(isRainMobile) {
                                        <span>rain<strong>mobile</strong></span>
                                    } @else {
                                        <span><strong>rainone</strong></span>
                                    }
                                } @else {
                                    <span>{{level.level}}</span>
                                }
                            </div>
                            <div class="cell">
                                <span><strong>{{level.data}}</strong>GB</span>
                            </div>
                            <div class="cell">
                                <span><strong>{{level.minutes}}</strong>min</span>
                            </div>
                            <div class="cell price-cell">
                                @if (level.price === 0) {
                                    <span>FREE</span>
                                } @else {
                                    <span><strong>R{{level.price}}</strong>pm</span>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    </div>
</div>