import { Injectable } from '@angular/core';
import { TopUpOption } from '../interfaces/interfaces';
import { Store } from '@ngxs/store';
import { TopupState } from '../store/topup/topup.state';
import { PaymentState } from '../store/Payment/payment.state';
import { CoreState } from '../store/Core/core.state';
import { PaymentCacheService } from './payment-cache.service';
import { TOPUP_RESULT } from '../constants/constants';
@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor(private store: Store, private paymentCacheService: PaymentCacheService) {}


  selectedPaymentMethod(label: string) {
    window.dataLayer.push({
      page_location: '/payment-details',
      page_title: 'Payment Details',
      user_type: 'existing customer',
      event_action: 'btn-click',
      event_category: 'content selected',
      event_label: label
    });
  }

  itemView(option: TopUpOption) {
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: 'item_view',
      ecommerce: {
        value: option?.amount,
        currency: "ZAR",
        items: [
          {
            item_name: `${option?.quantity}${option?.unit}`,
            currency: "ZAR",
            id: option?.id,
            price: option?.amount,
            item_brand: "rain",
            item_category: option?.type
          }
        ]
      }
    });
  }

  addToCart(option: TopUpOption) {
    const services = this.store.selectSnapshot(CoreState?.getMobileServices);
    const setMsisdn = this.store.selectSnapshot(TopupState.getSetMsisdn);
    const ownedService = services?.find(service => service?.msisdn === setMsisdn);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        value: option?.amount,
        currency: "ZAR",
        items: [
          {
            item_name: `${option?.quantity}${option?.unit}`,
            currency: "ZAR",
            id: option?.id,
            price: option?.amount,
            item_brand: "rain",
            item_category: option?.type,
            item_category2: ownedService ? "me" : "another number"
          }
        ]
      }
    });
  }

  addPaymentInfo(option: TopUpOption, paymentOption: string) {
    const services = this.store.selectSnapshot(CoreState?.getMobileServices);
    const setMsisdn = this.store.selectSnapshot(TopupState.getSetMsisdn);
    const ownedService = services?.find(service => service?.msisdn === setMsisdn);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        payment_type: paymentOption,
        value: option?.amount,
        currency: "ZAR",
        items: [
          {
            item_name: `${option?.quantity}${option?.unit}`,
            currency: "ZAR",
            id: option?.id,
            price: option?.amount,
            item_brand: "rain",
            item_category: option?.type,
            item_category2: ownedService ? "me" : "another number"
          }
        ]
      }
    });
  }

  purchase(option: TopUpOption) {
    const services = this.store.selectSnapshot(CoreState?.getMobileServices);
    const setMsisdn = this.store.selectSnapshot(TopupState.getSetMsisdn);
    const ownedService = services?.find(service => service?.msisdn === setMsisdn);
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: this.store.selectSnapshot(TopupState.getTopUpResult)?.checkout_id ?? this.paymentCacheService.getPaymentCacheItem(TOPUP_RESULT)?.checkout_id,
        result: this.store.selectSnapshot(PaymentState.paymentResult),
        value: option?.amount,
        currency: "ZAR",
        items: [
          {
            item_name: `${option?.quantity}${option?.unit}`,
            currency: "ZAR",
            id: option?.id,
            price: option?.amount,
            item_brand: "rain",
            item_category: option?.type,
            item_category2: ownedService ? "me" : "another number"
          }
        ]
      }
    });
  }
  purchaseFail(option: TopUpOption) {
    const services = this.store.selectSnapshot(CoreState?.getMobileServices);
    const setMsisdn = this.store.selectSnapshot(TopupState.getSetMsisdn);
    const ownedService = services?.find(service => service?.msisdn === setMsisdn)
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: 'purchase_fail',
      ecommerce: {
        transaction_id: this.store.selectSnapshot(TopupState.getTopUpResult)?.checkout_id ?? this.paymentCacheService.getPaymentCacheItem(TOPUP_RESULT)?.checkout_id,
        result: this.store.selectSnapshot(PaymentState.paymentResult),
        value: option?.amount,
        currency: "ZAR",
        items: [
          {
            item_name: `${option?.quantity}${option?.unit}`,
            currency: "ZAR",
            id: option?.id,
            price: option?.amount,
            item_brand: "rain",
            item_category: option?.type,
            item_category2: ownedService ? "me" : "another number"
          }
        ]
      }
    });
  }

}