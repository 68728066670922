<app-bottom-sheet-content-template>

    <h1 Header><strong>buy international minutes</strong></h1>
    
    <div Content>
        <div class="info-lockup">
            <p>Please note that purchasing international voice will enable your international calling </p>
        </div>
        <div class="button-container">

            <button class="solid" (click)="continuePurchase()">buy now</button>
        </div>
    </div>

</app-bottom-sheet-content-template>