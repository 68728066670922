import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Utils } from '@Utils';
import { Observable } from 'rxjs';
import { ServiceObject } from 'src/app/interfaces/interfaces';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { SaveServiceName, ToggleIntDialing } from 'src/app/store/Core/actions/service.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'raingo-sim-details-sheet',
  templateUrl: './sim-details-sheet.component.html',
  styleUrls: ['./sim-details-sheet.component.scss']
})
export class SimDetailsSheetComponent implements OnInit {
  @Select(DashboardState.GetPinPukDetails) pinPukDetails$: Observable<{pin: string, puk: string, iccid: string}>;
  @Select(AuthState.isAuthed) isAuthed$: Observable<boolean>;
  public form: FormGroup
  public isEditing: boolean = false;
  public selectedSvc: ServiceObject;
  public isDirty: boolean = false;
  public intDialingEnabled: boolean;

  constructor(
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly ref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    this.intDialingEnabled = this.selectedSvc.international_access === 'enabled';
    this.form = this.fb.group({
      simName: this.fb.nonNullable.control(this.selectedSvc.sim_name)
    });
  }

  public close() {
    this.store.dispatch(new CloseBottomSheet());
  }

  public editInfo() {
    this.isEditing = true;
    this.ref.detectChanges();
    setTimeout(() => {
      const input = document.querySelector('input');
      if (input) {
        input.focus();
        this.watchInput(input);
      };
    }, 300);
  }

  private watchInput(input: HTMLInputElement) {
    input.addEventListener('keyup', (ev) => this.isDirty = this.selectedSvc.sim_name !== input.value)
  }


  public GenerateShortCode(iccid: string) {
    return Utils.Helpers.GenerateICCIDShortCode(iccid);
  }

  public saveName() {
    const name = this.form.get('simName')?.value;
    const payload = {
      name: name,
      serviceId: this.selectedSvc.id
    };
    this.close();
    return this.store.dispatch(new SaveServiceName(payload));
  }

  public toggleIntDialing(ev: any) {
    this.intDialingEnabled = ev;
    return this.store.dispatch(new ToggleIntDialing({enable: ev, serviceID: this.selectedSvc.id}));
  }

}
